import { InputAdornment, TextField } from '@material-ui/core';
import React, { FormEventHandler, useRef, useState } from 'react';
import { Search as SearchIcon } from '@material-ui/icons';
import styled from 'styled-components';
import { Autocomplete } from '@material-ui/lab';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';

type Props = {
  data: any;
  placeholder?: string;
};

function Search({ placeholder = '검색', data }: Props) {
  const autoCompleteRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const updateUrlParams = useUpdateUrlParams();

  const onSearch: FormEventHandler<HTMLFormElement | HTMLButtonElement> = (
    e,
  ) => {
    e.preventDefault();
    const current = autoCompleteRef.current as any;
    current.blur();
    updateUrlParams({ search: inputValue?.trim() || '', page: '0' });
  };

  const onChange = (e: any, v: any) => {
    setInputValue(v?.keyword);
  };

  const onInputChange = (e: any, v: any) => {
    setInputValue(v);
  };

  return (
    <SearchWrapper>
      <form onSubmit={onSearch}>
        <Autocomplete
          ref={autoCompleteRef}
          options={data || []}
          onChange={onChange}
          onInputChange={onInputChange}
          getOptionLabel={(option) => option.keyword}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant='outlined'
                style={{ backgroundColor: '#FFFFFF' }}
                placeholder={placeholder}
                InputProps={{
                  className: 'MuiAutocomplete-inputRoot',
                  ref: params.InputProps.ref,
                  startAdornment: (
                    <button onClick={onSearch}>
                      <InputAdornment position='start'>
                        <SearchIcon
                          style={{ marginLeft: 10 }}
                          color='disabled'
                        />
                      </InputAdornment>
                    </button>
                  ),
                  fullWidth: true,
                }}
                size='small'
              />
            );
          }}
          renderOption={(option, { inputValue }) => {
            const matches = match(option.keyword, inputValue);
            const parts = parse(option.keyword, matches);
            return (
              <div>
                {parts.map((part: any, index: number) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? '#27AE60' : 'black',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            );
          }}
        />
      </form>
    </SearchWrapper>
  );
}

const SearchWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

export default Search;
