import { useRef, useState } from 'react';
import styled from 'styled-components';

type KioskRadioInputProps = {
  id: string;
  name: string;
  label: string;
  checked: boolean;
};

function KioskRadioInput({ id, name, label, checked }: KioskRadioInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isChecked, setIsChecked] = useState(checked);

  const onWrapperClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Wrapper onClick={onWrapperClick}>
      <label htmlFor={id}>{label}</label>
      <Input
        type='radio'
        name={name}
        id={id}
        ref={inputRef}
        defaultChecked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
      />
    </Wrapper>
  );
}

export default KioskRadioInput;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  border: 1px solid #cbccce;
  padding: 12px 16px;
  border-radius: 12px;
  cursor: pointer;
`;

const Label = styled.label`
  &:checked {
  }
`;

const Input = styled.input`
  flex-shrink: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 50%;
  outline: none;

  &:checked {
    outline: 0.4em solid #246cf6;
  }

  &:focus-visible {
    outline-offset: max(1px, 0.1em);
    outline: max(1px, 0.1em) solid #246cf6;
  }

  &:hover {
    box-shadow: 0 0 0 max(4px, 0.2em) lightgray;
    cursor: pointer;
  }

  &:disabled {
    background-color: lightgray;
    box-shadow: none;
    color: gray;
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
