import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
  children: ReactNode;
  marginBottom?: string;
}
function Title({ children, marginBottom }: IProps) {
  return <StyledTitle marginBottom={marginBottom}>{children}</StyledTitle>;
}

const StyledTitle = styled.h1<{ marginBottom?: string }>`
  font-size: 30px;
  line-height: 38px;
  margin-bottom: ${(props) => props.marginBottom || '24px'};
  color: #212121;
`;
export default Title;
