import initializeAxios from './axiosSetup';
import { axiosRequestConfiguration } from './config';

export const axiosInstance = initializeAxios(axiosRequestConfiguration);

const postAxios = async <ResponseType, BodyType, ParamType>(
  url: string,
  body: BodyType,
  config?: any,
) =>
  axiosInstance.post<ResponseType>(url, body, {
    ...config,
  });

const getAxios = async <ResponseType, ParamType>(
  url: string,
  queryParams?: ParamType,
  config?: any,
) =>
  axiosInstance.get<ResponseType>(url, {
    ...(queryParams && { params: queryParams }),
    ...config,
  });

const patchAxios = async <ResponseType, BodyType, ParamType>(
  url: string,
  body?: BodyType,
  queryParams?: ParamType,
) =>
  axiosInstance.patch<ResponseType>(url, body, {
    ...(queryParams && { params: queryParams }),
  });

const deleteAxios = async <ResponseType, BodyType, ParamType>(
  url: string,
  body?: BodyType,
  queryParams?: ParamType,
) =>
  axiosInstance.delete<ResponseType>(url, {
    ...(body && { data: body }),
    ...(queryParams && { params: queryParams }),
  });

const putAxios = async <ResponseType, BodyType, ParamType>(
  url: string,
  body: BodyType,
  queryParams?: ParamType,
) =>
  axiosInstance.put<ResponseType>(url, body, {
    ...(queryParams && { params: queryParams }),
  });

export default { postAxios, getAxios, patchAxios, deleteAxios, putAxios };
