import React from 'react';
import styled, { keyframes } from 'styled-components';

import FlexWrap from '@components/common/FlexWrap';

function LoadingView() {
  return (
    <Container justifyContent="center" alignItems="center">
      <Spinner />
    </Container>
  );
}
const Container = styled(FlexWrap)`
  width: 100%;
  height: 100%;
`;

const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const Spinner = styled.div`
  width: 30px;
  height: 30px;
  border: 4px solid #354eff;
  border-top-color: white;
  border-radius: 50%;
  animation: ${Spin} 700ms linear infinite;
`;
export default LoadingView;
