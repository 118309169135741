import styled from 'styled-components';

import MultiSelection from '@/components/common/multiSelection/MultiSelection';
import { KioskActiveTypes } from '@/types/kiosk.type';

type Props = {
  kioskActiveTypes: KioskActiveTypes[];
  setKioskActiveType: (data: KioskActiveTypes[]) => void;
};

function KioskFilter({ kioskActiveTypes, setKioskActiveType }: Props) {
  return (
    <Filter>
      <MultiSelection
        key='kioskActiveTypes'
        name='kioskActiveTypes'
        options={['ACTIVATED', 'DEACTIVATED']}
        selectedItems={kioskActiveTypes}
        setSelectedItems={setKioskActiveType}
      />
    </Filter>
  );
}

export default KioskFilter;

const Filter = styled.div``;
