import api from '..';
import store from '@/store';
import {
  GetKioskDetailChargingResponse,
  GetKioskDetailResponse,
  GetKioskStatusPayload,
  GetKioskStatusResponse,
  GetWithdrawalDetailResponse,
  GetKioskDetailWithdrawalResponse,
  UpdateKioskBillTypeBody,
  UpdateKioskBillTypePayload,
  GetKioskErrorLogResponse,
  GetKioskFranchiseeStatusResponse,
  PatchKioskIsActivePayload,
} from '@/types/api/kiosk';

const getTargetServerURL = () => {
  return store.getState().serverState === 'prod'
    ? 'https://erp.successmode.co.kr'
    : 'http://123.142.246.2:20921/test';
};
// 키오스크 현황
export const getKioskStatus = async (params: GetKioskStatusPayload) => {
  const res = await api.getAxios<GetKioskStatusResponse, GetKioskStatusPayload>(
    `${getTargetServerURL()}/kiosk`,
    params,
  );
  return {
    ...res.data,
    totalPages: res.data.totalPages - 1 < 0 ? 0 : res.data.totalPages - 1,
  };
};
// 키오스크 키워드 미리보기
export const getKeywords = async () => {
  const res = await api.getAxios<any, any>(
    `${getTargetServerURL()}/kiosk/keyword`,
  );
  const { kioskKeywordList } = res.data;
  return [
    ...[...new Set(kioskKeywordList)].map((v) => {
      return { keyword: v };
    }),
  ];
};
//키오스크 상세보기 현금 권종 설정
export const updateKioskBillType = async ({
  kioskIndex,
  ...body
}: UpdateKioskBillTypePayload) => {
  const res = await api.patchAxios<null, UpdateKioskBillTypeBody, null>(
    `${getTargetServerURL()}/kiosk/billType/${kioskIndex}`,
    body,
  );
  return res.data;
};
// 키오스크 상세정보
export const getKioskDetail = async (kioskIndex: number) => {
  const res = await api.getAxios<GetKioskDetailResponse, number>(
    `${getTargetServerURL()}/kiosk/${kioskIndex}`,
  );
  return res.data;
};
// 키오스크 상세정보 환급금 출금내역 상세보기
export const getWithdrawalDetail = async (refundIndex: number) => {
  const res = await api.getAxios<GetWithdrawalDetailResponse, number>(
    `${getTargetServerURL()}/kiosk/withdrawal/refunds/${refundIndex}`,
  );
  return res.data;
};
// 키오스크 상세정보 현금관리내역
export const getKioskDetailDeposit = async (payload: any) => {
  const { kioskIndex, queryString } = payload;
  const res = await api.getAxios<GetKioskDetailChargingResponse, number>(
    `${getTargetServerURL()}/kiosk/deposit/${kioskIndex}`,
    queryString,
  );
  return res.data;
};
// 키오스크 상세정보 환급금 출금내역
export const getKioskDetailWithdrawal = async (payload: any) => {
  const { kioskIndex, queryString } = payload;
  const res = await api.getAxios<GetKioskDetailWithdrawalResponse, number>(
    `${getTargetServerURL()}/kiosk/withdrawal/${kioskIndex}`,
    queryString,
  );
  return res.data;
};
// 키오스크 에러목록
export const getKioskErrorLog = async (payload: any) => {
  const { kioskIndex, queryString } = payload;
  const res = await api.getAxios<GetKioskErrorLogResponse, number>(
    `${getTargetServerURL()}/kiosk/error/${kioskIndex}`,
    queryString,
  );
  return res.data;
};
// 키오스크 가맹점 현황
export const getKioskFranchiseeStatus = async (queryString: any) => {
  const res = await api.getAxios<GetKioskFranchiseeStatusResponse, number>(
    `${getTargetServerURL()}/kiosk/user`,
    queryString,
  );
  return res.data;
};
// 키오스크 가맹점 현황 키워드 미리보기
export const getKioskFranchiseeKeywords = async () => {
  const res = await api.getAxios<any, any>(
    `${getTargetServerURL()}/kiosk/user/keyword`,
  );
  const { keywordList } = res.data;
  return [
    ...[...new Set(keywordList)].map((v) => {
      return { keyword: v };
    }),
  ];
};

/** 키오스크 활성화/비활성화 */
export const patchKioskIsActive = async (
  payload: PatchKioskIsActivePayload,
) => {
  const { kioskIndex, ...body } = payload;
  const res = await api.patchAxios(
    `${getTargetServerURL()}/kiosk/${kioskIndex}`,
    body,
  );
  return res.data;
};
