import { AxiosError } from 'axios';
import React, { FormEventHandler, useCallback, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import styled from 'styled-components';
import { Container, TableBody } from '@material-ui/core';

import { getNoticeLists } from '@/api/medical';
import ActionBtn from '@/components/common/ActionBtn';
import DataTable from '@/components/common/DataTable';
import LoadingView from '@/components/common/LoadingView';
import Title from '@/components/common/Title';
import { NoticeData } from '@/constants/medical';
import PageIndicator from '@/components/common/PageIndicator';
import CheckboxForm from '@/components/common/CheckboxForm';
import NoticeListItem from '@/components/medical/notice/NoticeListItem';
import CreateModal from '@/components/medical/notice/CreateModal';
import DetailModal from '@/components/medical/notice/DetailModal';
import useNoticeReducer from '@/hooks/medical/useNoticeReducer';
import { IGetNoticeListResponse } from '@/types/api/medical';

function HospitalNotice() {
  const queryClient = useQueryClient();
  const [state, dispatch] = useNoticeReducer();
  const {
    isOpenDetail,
    isOpenCreate,
    toggle,
    filter: { visible },
    selected,
  } = state;
  const { columns, noticeVisibleRadio } = NoticeData;
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(0);
  const [pageList, setPageList] = useState<any>();

  const { isLoading, data: noticeLists } = useQuery<
    IGetNoticeListResponse,
    AxiosError
  >(
    ['noticeLists', page, visible],
    () => getNoticeLists({ page, visible, size: 10 }),
    {
      onSuccess: (data) => {
        if (page === 0) {
          setTotalPage(data.totalPages);
          setPageList(
            data.totalPages < 4
              ? Array.from({ length: data.totalPages + 1 }, (v, i) => i)
              : [0, 1, 2, 3, 4],
          );
        }
      },
      retry: false,
    },
  );

  const onCloseModal = useCallback(() => {
    dispatch({ type: 'CLOSE_MODAL' });
    queryClient.clear();
  }, []);

  const onOpenModal = useCallback((type, selected) => {
    dispatch({ type: 'OPEN_MODAL', payload: { type, selected } });
  }, []);

  if (isLoading) {
    return <LoadingView />;
  }
  const onFilter: FormEventHandler<HTMLFieldSetElement> | undefined = (e) => {
    const { name, value } = e.target as any;
    dispatch({ type: 'SET_TOGGLE', payload: null });
    dispatch({
      type: 'SET_FILTER',
      payload: { [name]: value },
    });
    setPage(0);
    setPageList([0, 1, 2, 3, 4]);
  };

  return (
    <StyledContainer maxWidth="xl">
      <Title>공지사항</Title>
      <DataTable
        columns={columns}
        dispatch={dispatch}
        toggle={toggle}
        contentComponent={
          <TableBody>
            {noticeLists?.content.map((item: any, idx: number) => (
              <NoticeListItem
                key={item.noticeIndex}
                idx={idx}
                data={item}
                onOpenModal={() =>
                  onOpenModal('DETAIL', { noticeIndex: item.noticeIndex })
                }
              />
            ))}
          </TableBody>
        }
      />
      {noticeLists?.content.length === 0 && (
        <ContentContainer>
          작성된 공지사항이 존재하지 않습니다.
        </ContentContainer>
      )}
      {isOpenCreate && <CreateModal onCloseModal={onCloseModal} />}
      {isOpenDetail && (
        <DetailModal onCloseModal={onCloseModal} data={selected} />
      )}
      {toggle && (
        <CheckboxForm
          valueArray={noticeVisibleRadio}
          defaultValue={visible}
          type={toggle}
          onFilter={onFilter}
          style={{ right: '50px', top: '230px' }}
        />
      )}
      <ActionBtn
        title="신규작성"
        onClick={() => onOpenModal('CREATE', null)}
        margin="20px 0 0"
        right="70px"
        size="big"
      />
      {pageList && (
        <PageIndicator
          totalPage={totalPage}
          pageList={pageList}
          currentPage={page}
          setCurrentPage={setPage}
          setPageList={setPageList}
        />
      )}
    </StyledContainer>
  );
}
const StyledContainer = styled(Container)`
  padding-top: 16px;
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  color: gray;
  font-size: 16px;
`;
export default HospitalNotice;
