import { getHomeData } from '@/api/ktp';
import { IGetHomeDataPayload } from '@/types/api/ktp';

import { useQuery } from 'react-query';

function useHomeData(payload: IGetHomeDataPayload | null) {
  return useQuery(['homeDataKtp', payload], () => getHomeData(payload), {
    retry: false,
  });
}

export default useHomeData;
