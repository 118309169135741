import React from 'react';
import styled from 'styled-components';
import FlexWrap from './FlexWrap';
import Text from './Text';
import Error from '@assets/common/error.png';

interface IProps {
  message: string;
}
function ErrorMessage({ message }: IProps) {
  return (
    <FlexWrap alignItems="center">
      <Icon src={Error} />
      <Text text={message} color="#FF5239" weight={400} size={13} />
    </FlexWrap>
  );
}
const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 4px;
`;
export default ErrorMessage;
