import styled from 'styled-components';

import {
  BillType,
  KioskDetailRemainingInfo,
  KioskTrendInfo,
} from '@/types/api/kiosk';
import { cashMonitoringDataType } from '@/types/kiosk.type';

import FlexWrap from '@/components/common/FlexWrap';
import { cashMonitoringData } from '@/constants/kiosk';
import CashMonitor from './CashMonitor';

type CashProps = {
  remainingInfo: KioskDetailRemainingInfo;
  billType: BillType;
  trendInfo?: KioskTrendInfo;
};

function Cash({ remainingInfo, billType, trendInfo }: CashProps) {
  return (
    <FlexWrap gap="40px">
      {cashMonitoringData.columns.map((cashMonitoringData) => {
        const typedData = cashMonitoringData as cashMonitoringDataType;
        return (
          <CashMonitor
            key={`cash-monitor-${typedData.type}`}
            remainingInfo={remainingInfo}
            billType={billType}
            trendInfo={trendInfo}
            cashMonitoringData={typedData}
          />
        );
      })}
    </FlexWrap>
  );
}

export default Cash;
