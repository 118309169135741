import React from 'react';
import styled from 'styled-components';
import Text from '../../common/Text';

const FormItemLayout = ({ label, required, children, style }) => {
  return (
    <ItemContainer style={style}>
      <Text text={label} required={required} style={{ width: 240 }} />
      {children}
    </ItemContainer>
  );
};
const ItemContainer = styled.div`
  display: flex;
  margin: 4px 0;
  width: 100%;
`;

export default FormItemLayout;
