import React, { useEffect, useState } from 'react';
import MultiSelectionItem from './MultiSelectionItem';
import styled from 'styled-components';
import { FILTER_DATA } from '@/constants/sales/data';
import { FilterType } from '@/types/sales.types';
import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';

type Props<T extends FilterType> = {
  options: T[];
  name: string;
  selectedItems: T[];
  setSelectedItems: (options: T[]) => void;
  hasAllButton?: boolean;
};

function MultiSelection<T extends FilterType>({
  options,
  name,
  selectedItems,
  setSelectedItems,
  hasAllButton = true,
}: Props<T>) {
  const [selectedAll, setSelectedAll] = useState(false);

  const updateUrlParams = useUpdateUrlParams();
  const updateUrlPageParams = () => {
    updateUrlParams({ page: '0' });
  };

  const onCheckedAll = () => {
    if (!selectedAll) {
      setSelectedItems(options);
      setSelectedAll(true);
      updateUrlPageParams();
    }
  };

  const onChecked = (clickedValue: T) => {
    if (selectedAll) {
      setSelectedAll(false);
      setSelectedItems([clickedValue]);
    } else {
      const copiedItems: T[] = [...selectedItems];
      const idx = copiedItems.findIndex((v) => v === clickedValue);
      if (idx < 0) {
        copiedItems.push(clickedValue);
      } else {
        copiedItems.splice(idx, 1);
      }
      setSelectedItems(copiedItems);
    }
    updateUrlPageParams();
  };

  useEffect(() => {
    if (selectedItems?.length === options?.length) {
      setSelectedAll(true);
    } else {
      setSelectedAll(false);
    }
  }, [selectedItems, options, setSelectedAll]);

  return (
    <MultiSelectionWrapper>
      <Title>{FILTER_DATA[name]}</Title>
      {hasAllButton && (
        <MultiSelectionItem
          id={`${name}-all-selector`}
          name={name}
          value='ALL'
          label={FILTER_DATA['ALL']}
          checked={selectedAll}
          onChange={onCheckedAll}
          isAllButton
        />
      )}
      <ItemWrapper>
        {options?.map((option) => (
          <MultiSelectionItem
            key={`${name}-${option}`}
            id={`${name}-${option}`}
            name={name}
            value={option}
            label={FILTER_DATA[option]}
            checked={selectedAll || selectedItems.includes(option)}
            onChange={onChecked}
            selectedAll={selectedAll}
          />
        ))}
      </ItemWrapper>
    </MultiSelectionWrapper>
  );
}

const MultiSelectionWrapper = styled.section`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  border: none;
`;

const Title = styled.h4`
  padding: 10px;
  margin: 0 8px 0 0;
  width: 80px;
  color: #3a3b3e;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  flex-shrink: 0;
`;

const ItemWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

export default MultiSelection;
