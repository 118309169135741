import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const GenderDoughnut = ({ statistics }) => {
  const { numberOfUsers, numberOfCertificated, numberOfNotCertificated } =
    statistics;

  const options = {
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      title: {
        display: true,
        font: {
          size: 20,
        },
        text: '본인인증 여부',
      },
    },
  };
  return (
    <Doughnut
      width={400}
      height={400}
      options={options}
      data={{
        labels: ['본인인증 O', '본인인증 X'],
        datasets: [
          {
            label: 'Gender of Users',
            data: [numberOfCertificated, numberOfNotCertificated],
            backgroundColor: [
              'rgba(75, 192, 192, 0.2)',
              'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
            borderWidth: 1,
          },
        ],
      }}
    />
  );
};

export default GenderDoughnut;
