import { useState, useCallback } from 'react';

function useInputs(initialState) {
  const [form, setForm] = useState(initialState);

  const onChange = useCallback((event) => {
    const { name, value } = event.target;

    setForm((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }, []);

  const onReset = useCallback(() => {
    setForm(initialState);
  }, [initialState]);

  return { form, onChange, onReset };
}

export default useInputs;
