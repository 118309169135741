import FlexWrap from '@/components/common/FlexWrap';
import React from 'react';
import { SubTitle, TransferContainer } from './DetailModalContent';
import { TransferInput } from './RemittanceStatusRadio';

type Props = {
  refundInfo: any;
  isEdit: boolean;
  setData: React.Dispatch<React.SetStateAction<unknown>>;
};

const PAYMENT_WAY_LIST = [
  {
    name: '차감결제',
    value: false,
  },
  {
    name: '전액결제',
    value: true,
  },
];

function PaymentWayRadio({ refundInfo, isEdit, setData }: Props) {
  const onChangeNeedFullPayment = (paymentStatus: boolean) => {
    setData((prev: any) => {
      return {
        ...prev,
        refundInfo: {
          ...prev.refundInfo,
          needFullPayment: paymentStatus,
        },
      };
    });
  };

  return (
    <FlexWrap alignItems="center" margin="0 0 20px 0">
      <SubTitle>결제 방식</SubTitle>
      <TransferContainer dir="row" gap="20px">
        {PAYMENT_WAY_LIST.map((way) => {
          return (
            <FlexWrap>
              <TransferInput
                type="radio"
                checked={!refundInfo.needFullPayment}
                onChange={() => onChangeNeedFullPayment(way.value)}
                disabled={!isEdit}
                isRadio
              />
              <label>{way.name}</label>
            </FlexWrap>
          );
        })}
      </TransferContainer>
    </FlexWrap>
  );
}

export default PaymentWayRadio;
