import React from 'react';
import styled from 'styled-components';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const UserInfoListItem = ({ userInfo }) => {
  const {
    userIndex,
    userType,
    userName,
    gender,
    birthday,
    profileImages,
    createdDate,
    numberOfReservationApplicant,
    numberOfCompletedReservation,
    numberOfReservationRegistration,
  } = userInfo;

  return (
    <>
      <TableRowWithStyle>
        <TableCell component={'th'}>{userIndex}</TableCell>
        <TableCell>{createdDate.split('T')[0]}</TableCell>
        <TableCell>
          <ProfileImage src={profileImages[0]} />
        </TableCell>
        <TableCell>
          {userName}({userType})
        </TableCell>
        <TableCell>
          {gender === 'male' ? '남' : '여'}({birthday})
        </TableCell>
        <TableCell>{numberOfReservationApplicant}</TableCell>
        <TableCell>{numberOfCompletedReservation}</TableCell>
        <TableCell>{numberOfReservationRegistration}</TableCell>
      </TableRowWithStyle>
    </>
  );
};

const TableRowWithStyle = styled(TableRow)`
  &:hover {
    cursor: pointer;
    background-color: gainsboro;
  }
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 70%;
  overflow: hidden;
  object-fit: cover;
`;

export default UserInfoListItem;
