import React, { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
  backgroundColor?: string;
  color?: string;
  width?: number;
};

function StatusLabel({
  children,
  backgroundColor = '#F5F6F7',
  color = '#3A3B3E',
  width = 94,
}: Props) {
  return (
    <Label backgroundColor={backgroundColor} color={color} width={width}>
      {children}
    </Label>
  );
}

const Label = styled.span<{
  backgroundColor: string;
  color: string;
  width: number;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => `${width}px`};
  height: 25px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.28px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  flex-shrink: 0;
`;

export default StatusLabel;
