import React, { Dispatch, MouseEventHandler, SetStateAction } from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  pageList: Array<number>;
  totalPage?: number;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setPageList: Dispatch<SetStateAction<Array<number>>>;
}
function PageIndicator({
  pageList,
  totalPage,
  currentPage,
  setCurrentPage,
  setPageList,
}: IProps) {
  const onClickIndicator: MouseEventHandler<HTMLButtonElement> | undefined = (
    e,
  ) => {
    const target = e.target as HTMLElement;
    setCurrentPage(Number(target.innerText) - 1);
  };

  const onClickLeftArrow = () => {
    setPageList((prevArr: any) => {
      const newArr = Array.from({ length: 5 }, (v, i) => i + prevArr[0] - 5);
      setCurrentPage(newArr[4]);
      return newArr;
    });
  };
  const onClickRightArrow = () => {
    const newPageList = pageList
      .map((v) => v + 5)
      .filter((v) => v <= totalPage!);
    setPageList(newPageList);
    setCurrentPage(newPageList[0]);
  };
  return (
    <Container>
      <ArrowBtn
        active={pageList[0] !== 0}
        disabled={pageList[0] === 0}
        onClick={onClickLeftArrow}
      >{`<`}</ArrowBtn>
      {pageList.map((item, index) => (
        <Indicator
          key={index}
          active={String(currentPage) === String(item)}
          onClick={onClickIndicator}
        >
          {item + 1}
        </Indicator>
      ))}
      <ArrowBtn
        active={pageList[pageList.length - 1] !== totalPage}
        disabled={pageList[pageList.length - 1] === totalPage}
        onClick={onClickRightArrow}
      >{`>`}</ArrowBtn>
    </Container>
  );
}
const Container = styled.footer`
  display: flex;
  margin: 20px;
  justify-content: center;
  @media screen and (max-width: 1280px) {
    margin-top: 80px;
  }
`;
const ArrowBtn = styled.button<{ active: boolean }>`
  border: none;
  background-color: transparent;
  font-size: 20px;
  color: cream;
  ${(props) =>
    props.active &&
    css`
      color: gray;
      cursor: pointer;
    `}
`;
const Indicator = styled.button<{ active: boolean }>`
  border: none;
  width: 30px;
  height: 30px;
  margin: 0 4px;
  text-align: center;
  line-height: 30px;
  color: gray;
  cursor: pointer;
  background-color: transparent;
  ${(props) =>
    props.active &&
    css`
      color: black;
    `}
`;
export default PageIndicator;
