import React, { useCallback, useMemo } from 'react';
import { useMutation } from 'react-query';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled, { css } from 'styled-components';

import { uploadImage } from '@/api/medical';

const QuillEditor = ({ htmlContent, setHtmlContent, isEditMode, quillRef }) => {
  const mutation = useMutation((payload) => uploadImage(payload), {
    retry: false,
    onError: () => alert('이미지 업로드에 실패하였습니다.'),
    onSuccess: ({ s3Url }) => {
      const quill = quillRef.current.getEditor();
      /* * ReactQuill 노드에 대한 Ref가 있어야 메서드들을 호출할 수 있음
       * getEditor() : 편집기를 지원하는 Quill 인스턴스를 반환
       * 여기서 만든 인스턴스로 getText()와 같은 메서드 사용 가능*/

      const range = quill.getSelection()?.index;
      // getSelection()은 현재 선택된 범위를 리턴. 에디터가 포커싱되지 않았다면 null을 반환

      if (typeof range !== 'number') return;
      /* * range는 0이 될 수도 있으므로 null만 생각하고 !range로 체크하면 잘못 작동할 수 있음
       * 따라서 타입이 숫자이지 않을 경우를 체크해 리턴*/

      quill.setSelection(range, 1);
      /* * 사용자 선택을 지정된 범위로 설정하여 에디터에 포커싱 가능
       * 위치 인덱스와 길이 전달*/

      quill.clipboard.dangerouslyPasteHTML(
        range,
        `<img src=${s3Url} alt="image" />`,
      );
      // 주어진 인덱스에 HTML로 작성된 내용물을 에디터에 삽입
    },
  });
  // 툴바의 사진 아이콘 클릭시 기존에 작동하던 방식 대신에 실행시킬 핸들러
  const imageHandler = useCallback(() => {
    // 이렇게 하는 이유: 그냥 하면 이미지 업로드시 base64로 변환돼 저장할 html 태그가 너무 길어짐
    // 이미지를 url로 바꾸기위해 서버로 전달할 폼데이터 생성
    const formData = new FormData();

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.setAttribute('name', 'image');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      formData.append('image', file);
      mutation.mutate(formData);
    };
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        // 툴바에 넣을 기능들 순서대로 나열
        container: [
          ['bold', 'italic', 'underline', 'strike'],
          [
            { size: ['small', false, 'large', 'huge'] },
            { color: [] },
            { background: [] },
          ],
          [{ font: [] }],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
            { align: [] },
          ],
          ['link', 'image'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [imageHandler],
  );
  return (
    <>
      <StyledReactQuill
        ref={quillRef}
        value={htmlContent}
        onChange={setHtmlContent}
        modules={modules}
        theme="snow"
        readOnly={!isEditMode}
      />
    </>
  );
};
const StyledReactQuill = styled(ReactQuill)`
  img {
    max-height: 20rem;
    max-width: 80%;
  }
  div.ql-editor {
    height: 350px;
  }
  ${(props) =>
    props.readOnly &&
    css`
      border: 1px solid lightgray;
      border-radius: 8px;
      padding: 20px;
      div.ql-container {
        border: none;
      }
      div.ql-toolbar {
        display: none;
      }
    `}
`;
export default QuillEditor;
