import React, { useState } from 'react';
import Portal from '../../common/Portal';

import { useQuery } from 'react-query';
import { getDetailHistory } from '@/api/sales';
import SalesModalContent from './SalesModalContent';

type Props = {
  onClose: () => void;
  index?: number;
};

function SalesModal({ index, onClose }: Props) {
  const [id, setId] = useState<number | undefined>(index);

  const { data, isError } = useQuery(
    ['detailHistory', id],
    () => {
      if (id) {
        return getDetailHistory({ id });
      }
      return Promise.reject();
    },
    {
      onError: () => {
        alert('서버 요청에 실패하였습니다. 관리자에게 문의주세요.');
      },
      enabled: id !== undefined,
    },
  );

  const onSave = (id: number) => {
    setId(id);
  };

  if (isError) {
    return null;
  }

  return (
    <Portal isOpaqueBackdrop>
      <SalesModalContent
        id={id}
        info={data?.info}
        inquiries={data?.inquiries}
        onClose={onClose}
        onSave={onSave}
      />
    </Portal>
  );
}

export default SalesModal;
