import { FILTER_DATA } from './data';

export const SERVICE_TYPE_LABEL_LIST = [
  {
    value: 'MERCHANT',
    label: FILTER_DATA.MERCHANT,
    color: '#246CF6',
    width: 40,
  },
  {
    value: 'MEDICAL',
    label: FILTER_DATA.MEDICAL,
    color: '#FF2D55',
    width: 40,
  },
  {
    value: 'ETC',
    label: FILTER_DATA.ETC,
    width: 40,
  },
];

export const TAX_ENROLLMENT_LABEL_LIST = [
  {
    value: 'ENROLL_YES',
    label: FILTER_DATA.ENROLL_YES,
    width: 40,
  },
  {
    value: 'ENROLL_NO',
    label: FILTER_DATA.ENROLL_NO,
    width: 40,
  },
];

export const SALES_STATUS_LABEL_LIST = [
  {
    value: 'INQUIRY',
    label: FILTER_DATA.INQUIRY,
    backgroundColor: '#FFF9DB',
    color: '#E67700',
  },
  {
    value: 'MANUAL_SEND',
    label: FILTER_DATA.MANUAL_SEND,
    backgroundColor: '#E7F5FF',
    color: '#1864AB',
  },
  {
    value: 'PACKAGE_SEND',
    label: FILTER_DATA.PACKAGE_SEND,
    backgroundColor: '#FFEADA',
    color: '#FF6502',
  },
  {
    value: 'BEFORE_MEETING',
    label: FILTER_DATA.BEFORE_MEETING,
    backgroundColor: '#E3FAFC',
    color: '#0B7285',
  },
  {
    value: 'SCHEDULED_MEETING',
    label: FILTER_DATA.SCHEDULED_MEETING,
    backgroundColor: '#F8F0FC',
    color: '#862E9C',
  },
  {
    value: 'AFTER_MEETING',
    label: FILTER_DATA.AFTER_MEETING,
    backgroundColor: '#F3F0FF',
    color: '#5F3DC4',
  },
  {
    value: 'SALES_FAILED',
    label: FILTER_DATA.SALES_FAILED,
    backgroundColor: '#FFE0E1',
    color: '#ED1427',
  },
  {
    value: 'SALES_SUCCESS',
    label: FILTER_DATA.SALES_SUCCESS,
    backgroundColor: '#C8FAEB',
    color: '#09825E',
  },
];

export const TODO_STATUS_LABEL_LIST = [
  {
    value: 'CONTACT',
    label: FILTER_DATA.CONTACT,
    backgroundColor: '#EFFFE8',
    color: '#0D8E1A',
  },
  {
    value: 'VISIT',
    label: FILTER_DATA.VISIT,
    backgroundColor: '#E7F5FF',
    color: '#1864AB',
  },
  {
    value: 'PACKAGE',
    label: FILTER_DATA.PACKAGE,
    backgroundColor: '#FFEADA',
    color: '#FF6502',
  },
];
