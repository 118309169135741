import { useReducer } from 'react';

const initialState = {
  modalType: null,
  toggle: null,
  filter: { isRead: 'all', franchiseeStatus: 'all' },
  selected: null,
};

function reducer(state: any, action: any): any {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        selected: action.payload.selected,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        selected: null,
      };
    case 'SET_SIZE':
      return {
        ...state,
        size: action.payload,
      };
    case 'SET_FILTER':
      return {
        ...state,
        filter: action.payload,
      };
    default:
      throw Error(`Unhandled Type Error: ${action.type}`);
  }
}
const useQnaReducer = (): any => {
  return useReducer(reducer, initialState);
};
export default useQnaReducer;
