import { Checkbox, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';

import SubFormItem from './SubFormItem';
import MainFormItem from './MainFormItem';
import SelectBox from '../../common/SelectBox';
import FormItemLayout from './FormItemLayout';

function Form({
  state,
  onChangeChecked,
  onChangeRadio,
  onChangeInput,
  onChangeImage,
  onChangeSelect,
  isEditMode = false,
}) {
  return (
    <FormContainer>
      <FormControlLabel
        style={{ width: 230 }}
        control={
          <Checkbox
            checked={state.isFixed}
            onChange={onChangeChecked}
            name="isFixed"
            color="primary"
          />
        }
        label="앱 공지사항 화면 상단에 고정"
      />
      <ContentContainer>
        <MainFormItem
          state={state}
          onChangeInput={onChangeInput}
          onChangeRadio={onChangeRadio}
          onChangeImage={onChangeImage}
          isEditMode={isEditMode}
        />
      </ContentContainer>
      {[1, 2, 3].map((index) => (
        <ContentContainer key={index}>
          <SubFormItem
            order={index}
            state={state}
            onChangeImage={onChangeImage}
            onChangeInput={onChangeInput}
            isEditMode={isEditMode}
          />
        </ContentContainer>
      ))}
      <FormItemLayout label="링크 추가" style={{ marginTop: 12 }}>
        <SelectBox
          name="link"
          defaultValue="NONE"
          defaultTitle="이동할 앱 header title 선택"
          selectedValue={state.link}
          onChange={onChangeSelect}
          menuList={[
            { title: '개인정보 이용/수집', value: 'PERSONAL_TYPE' },
            { title: '서비스 이용 약관', value: 'SERVICE_TYPE' },
            { title: 'T.POINT 이용 약관', value: 'POINT_TYPE' },
            { title: 'CMS 출금 약관', value: 'CMS_TYPE' },
          ]}
        />
      </FormItemLayout>
      <FormItemLayout required label="공지사항 분류" style={{ marginTop: 12 }}>
        <SelectBox
          name="noticeType"
          defaultValue="NONE"
          defaultTitle="공지사항 분류 선택"
          selectedValue={state.noticeType}
          onChange={onChangeSelect}
          menuList={[
            { title: '일반', value: 'GENERAL' },
            { title: '업데이트', value: 'UPDATE' },
          ]}
        />
      </FormItemLayout>
    </FormContainer>
  );
}
const FormContainer = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  padding: 0 10px;
  margin-right: 20px;
  height: 690px;
  overflow-y: scroll;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 0;
  border-bottom: 1px solid gray;
`;

export default Form;
