import React from 'react';
import styled from 'styled-components';

import DateSelectorFilter from '@/components/common/DateSelectorFilter';
import FlexWrap from '@/components/common/FlexWrap';
import Typography from '@/components/common/Typography';
import RefundCard from '@/components/medical/home/RefundCard';
import useHomeReducer from '@/hooks/medical/useHomeReducer';

function RefundOverview({ data }: any) {
  return (
    <>
      <RefundContainer>
        {Object.values(data).map((item: any, idx) => (
          <RefundCard key={idx} idx={idx} value={item} />
        ))}
      </RefundContainer>
    </>
  );
}

const TitleWrap = styled(FlexWrap)``;

const RefundContainer = styled(FlexWrap)`
  gap: 20px;
  margin-bottom: 56px;
`;

export default RefundOverview;
