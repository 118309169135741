import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  children?: ReactNode;
  dir?: 'row' | 'column';
  alignItems?: 'center' | 'space-between' | 'flex-start' | 'flex-end';
  justifyContent?:
    | 'center'
    | 'space-between'
    | 'flex-start'
    | 'flex-end'
    | 'space-evenly';
  margin?: string;
  padding?: string;
  gap?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  flex?: string;
}
function FlexWrap({
  children,
  dir = 'row',
  alignItems,
  justifyContent,
  margin,
  padding,
  gap,
  flex,
  onClick,
  ...divProps
}: IProps) {
  return (
    <Container
      dir={dir}
      alignItems={alignItems}
      justifyContent={justifyContent}
      margin={margin}
      padding={padding}
      gap={gap}
      onClick={onClick}
      flex={flex}
      {...divProps}
    >
      {children}
    </Container>
  );
}
const Container = styled.div<{
  dir?: 'row' | 'column';
  alignItems?: 'center' | 'space-between' | 'flex-start' | 'flex-end';
  justifyContent?:
    | 'center'
    | 'space-between'
    | 'flex-start'
    | 'flex-end'
    | 'space-evenly';
  margin?: string;
  padding?: string;
  gap?: string;
  flex?: string;
}>`
  display: flex;
  flex-direction: ${(props) => props.dir};
  align-items: ${(props) => props.alignItems || 'flex-start'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  gap: ${(props) => props.gap || '0px'};
  padding: ${(props) => props.padding || 0};
  margin: ${(props) => props.margin || 0};
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
`;
export default FlexWrap;
