import { format } from 'date-fns';
import { http, HttpResponse } from 'msw';

export const sales = [
  http.get('/sales/status', async () => {
    return HttpResponse.json({
      serviceTypes: ['MERCHANT', 'MEDICAL', 'ETC'],
      salesStatuses: [
        'INQUIRY',
        'MANUAL_SEND',
        'PACKAGE_SEND',
        'BEFORE_MEETING',
        'SCHEDULED_MEETING',
        'AFTER_MEETING',
        'SALES_SUCCESS',
        'SALES_FAILED',
      ],
      todoStatuses: ['CONTACT', 'VISIT', 'PACKAGE'],
      salesKeywords: {
        storeNames: ['테스트_상점', '테스트_상점2'],
        emails: ['abu@ktaxpay.com', 'abu@ktaxpay.com'],
      },
    });
  }),

  http.get('/sales/franchisee', async ({ request }) => {
    const url = new URL(request.url);
    const size = Number(url.searchParams.get('size')) || 10;
    const page = Number(url.searchParams.get('page')) || 1;

    const franchiseeContent = [
      {
        id: 1,
        serviceType: 'MERCHANT',
        storeName: '테스트_상점',
        taxEnrollment: true,
        firstInquiryDate: '2024-08-23',
        salesStatus: 'INQUIRY',
        todoStatus: 'CONTACT',
        todoDueDate: '2024-08-25',
        processDays: 1,
      },
    ];

    const start = (page - 1) * size;
    const end = start + size;
    const content = franchiseeContent.slice(start, end);

    return HttpResponse.json({
      content: content,
      pageable: {
        sort: {
          unsorted: true,
          sorted: false,
          empty: true,
        },
        pageNumber: 0,
        pageSize: 100,
        offset: 0,
        paged: true,
        unpaged: false,
      },
      totalPages: 1,
      totalElements: 2,
      last: true,
      numberOfElements: 2,
      first: true,
      number: 0,
      size: 100,
      sort: {
        unsorted: true,
        sorted: false,
        empty: true,
      },
      empty: false,
    });
  }),

  http.get('/sales/:id', async () => {
    return HttpResponse.json({
      info: {
        storeName: 'storeName_06fef8fa17ca',
        ownerName: 'ownerName_95b952fee526',
        taxEnrollment: false,
        contactNumber: 'contactNumber_01faaccd49d3',
        email: 'email_85b9d0793fe7',
        address: 'address_43c6f0676763',
        firstInquiryDate: '2024-08-27',
        serviceType: 'MERCHANT',
        salesStatus: 'INQUIRY',
        todoStatus: 'CONTACT',
        todoDueDate: '2024-08-27',
        finalContactDate: '2024-08-27',
      },
      histories: [
        {
          inquiryId: 1,
          createDate: '2024-08-27 08:22:29',
          contactManager: 'contactManager_1650804bd580',
          salesStatus: 'INQUIRY',
          content: 'content_b250d84801fe',
        },
      ],
    });
  }),

  http.post('/sales/:id', async () => {
    return new HttpResponse(null, { status: 200 });
  }),

  http.post('/sales', async () => {
    return HttpResponse.json({ salesId: 30 });
  }),

  http.get('/sales/history/:id', async ({ params }) => {
    const { id } = params;

    if (id === '30') {
      return HttpResponse.json({
        content: [],
      });
    }
    return HttpResponse.json({
      content: [
        {
          index: 1,
          datetime: '2024.05.20 21:20:11',
          writer: '이양석',
          status: '1',
          text: '초기 도입문의전화 왔음.초기 도입문의전화 왔음.초기 도입문의전화 왔음.초기 도입문의전화 왔음.초기 도입문의전화 왔음.초기 도입문의전화 왔음.초기 도입문의전화 왔음.',
        },
        {
          index: 2,
          datetime: '2024.05.22 21:20:11',
          writer: '도건우',
          status: '2',
          text: '메뉴얼 전송!',
        },
        {
          index: 3,
          datetime: '2024.05.24 21:20:11',
          writer: '김성은',
          status: '3',
          text: '패키지 발송~!~!~!',
        },
        {
          index: 4,
          datetime: '2024.05.22 21:20:11',
          writer: '김성은',
          status: '4',
          text: '논의 중~~~~~~~',
        },
        {
          index: 5,
          datetime: '2024.05.30 21:20:11',
          writer: '이양석',
          status: '5',
          text: '방문일자 협의 하였습니다. 방문일자 협의 하였습니다. 방문일자 협의 하였습니다. 방문일자 협의 하였습니다. 방문일자 협의 하였습니다. 방문일자 협의 하였습니다.',
        },
        {
          index: 6,
          datetime: '2024.06.10 21:20:11',
          writer: '김성은',
          status: '6',
          text: '히스토리 남기기 히스토리 남기기 히스토리 남기기 히스토리 남기기 히스토리 남기기 히스토리 남기기 히스토리 남기기 히스토리 남기기 히스토리 남기기 히스토리 남기기',
        },
      ],
    });
  }),

  http.post('/sales/inquiry', async () => {
    return HttpResponse.json({
      createDate: '2024-08-27 08:26:11',
      contactManager: 'contactManager_237777338bab',
      salesStatus: 'INQUIRY',
      content: 'content_5c7365715eea',
    });
  }),

  http.delete('/sales/inquiry/:id', async () => {
    return new HttpResponse(null, { status: 200 });
  }),

  http.get('/sales/keywords', async () => {
    return HttpResponse.json({
      searchAllIndexList: [
        '20',
        '19',
        '18',
        '17',
        '16',
        '15',
        '14',
        '13',
        '12',
        '11',
        '10',
        '9',
        '8',
        '7',
        '6',
        '5',
        '4',
        '3',
        '2',
        '1',
      ],
      searchAllStoreNameList: ['리엘르의원', '주식회사 레어마켓'],
    });
  }),
];
