import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type Props = {
  content: string;
  createdDate: string;
  isQuestion: boolean;
};

function ChatBox({ content, createdDate, isQuestion }: Props) {
  const textRef = useRef() as React.MutableRefObject<HTMLTextAreaElement>;
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (textRef) {
      setHeight(textRef.current.scrollHeight);
    }
  }, [textRef]);

  const formatDate = (date: string) => {
    return date.split('T').join(' ');
  };

  return (
    <Wrapper isQuestion={isQuestion}>
      <Date isQuestion={isQuestion}>{formatDate(createdDate)}</Date>
      <Text
        ref={textRef}
        name=""
        id=""
        value={content}
        disabled
        height={height}
        isQuestion={isQuestion}
      />
    </Wrapper>
  );
}

export default ChatBox;

type WrapperStyleProps = {
  isQuestion: boolean;
};

type TextStyleProps = {
  height: number;
};

const Wrapper = styled.div<WrapperStyleProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #e5e6e8;
  border-radius: 8px;
  max-width: 90%;
  padding: 14px 16px;
  background-color: ${({ isQuestion, theme }) =>
    isQuestion ? '#246CF6' : '#fff'};
`;

const Date = styled.small<WrapperStyleProps>`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  color: ${({ isQuestion }) => (isQuestion ? '#fff' : '#030303')};
`;

const Text = styled.textarea<TextStyleProps & WrapperStyleProps>`
  resize: none;
  border: none;
  height: ${({ height }) => height}px;
  line-height: 150%;
  font-size: 14px;
  font-weight: 500;
  color: ${({ isQuestion }) => (isQuestion ? '#fff' : '#030303')};
  &:disabled {
    opacity: 1;
  }
  background-color: ${({ theme, isQuestion }) =>
    isQuestion ? '#246CF6' : '#fff'};
`;
