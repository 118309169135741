import { TableBody } from '@material-ui/core';
import RefundItem from './RefundItem';

function RefundList({ refundData }) {
  return (
    <TableBody>
      {refundData.map((data, idx) => (
        <RefundItem key={data.id} idx={idx} data={data} />
      ))}
    </TableBody>
  );
}

export default RefundList;
