import { TableBody } from '@material-ui/core';
import FranchiseeStatusItem from './FranchiseeStatusItem';

function FranchiseeStatusList({ franchisees, onOpen }) {
  return (
    <TableBody>
      {franchisees.map((franchisee, idx) => (
        <FranchiseeStatusItem
          key={franchisee.franchiseeIndex}
          idx={idx}
          franchisee={franchisee}
          onOpen={onOpen}
        />
      ))}
    </TableBody>
  );
}

export default FranchiseeStatusList;
