import React, { RefObject } from 'react';
import styled from 'styled-components';
import ConfirmModal from './ConfirmModal';

type Props = {
  onKeep: () => void;
  onClose: () => void;
  buttonRef?: RefObject<HTMLButtonElement>;
};

function CloseModal({ onKeep, onClose, buttonRef }: Props) {
  return (
    <ConfirmModal
      title='창을 닫으시겠습니까?'
      onCancel={onKeep}
      onOK={onClose}
      cancelLabel='계속 입력'
      buttonRef={buttonRef}
    >
      <Message>저장하지 않은 내용은 초기화 됩니다.</Message>
    </ConfirmModal>
  );
}

const Message = styled.p`
  font-size: 18px;
  font-weight: 500px;
  line-height: 27px;
  color: #5f6165;
  text-align: center;
`;

export default CloseModal;
