import styled from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';
import IconOne from '@/assets/medical/refund_count.png';
import IconTwo from '@/assets/medical/total_payment.png';
import IconThree from '@/assets/medical/total_refund.png';
import { comma } from '@/utils/ktp';

interface IProps {
  value: number;
  idx: number;
}
function RefundCard({ value, idx }: IProps) {
  return (
    <Container alignItems="center">
      <Icon src={idx === 0 ? IconOne : idx === 1 ? IconTwo : IconThree} />
      <FlexWrap dir="column" justifyContent="space-between" gap="34px" flex="1">
        <Title>
          {idx === 0 ? '환급 건' : idx === 1 ? '누적 판매금액' : '누적 환급액'}
        </Title>
        <ValueWrap alignItems="center" justifyContent="flex-end" gap="8px">
          <ValueText>{comma(value)}</ValueText>
          <Text>{idx === 0 ? '건' : '원'}</Text>
        </ValueWrap>
      </FlexWrap>
    </Container>
  );
}
const Container = styled(FlexWrap)`
  background-color: #ffffff;
  border-radius: 10px;
  height: 180px;
  flex: 1;
  padding: 30px 40px;
`;
const Icon = styled.img`
  width: 120px;
  height: 120px;
  @media ${(props) => props.theme.narrowPC} {
    width: 100px;
    height: 100px;
  }
`;
const Text = styled.p`
  font-size: 20px;
  line-height: 28px;
  @media ${(props) => props.theme.narrowPC} {
    font-size: 18px;
    line-height: 22px;
  }
`;
const Title = styled(Text)`
  margin-left: 56px;
  @media ${(props) => props.theme.narrowPC} {
    margin-left: 20px;
  }
`;
const ValueText = styled.p`
  font-size: 42px;
  line-height: 42px;
  margin-bottom: 3px;
  @media ${(props) => props.theme.narrowPC} {
    font-size: 30px;
    line-height: 35px;
  }
`;
const ValueWrap = styled(FlexWrap)`
  width: 100%;
`;
export default RefundCard;
