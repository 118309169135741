import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled, { css } from 'styled-components';

import { applicationTableData } from '../../../constants/ktp';
import { applyFranchisee } from '../../../api/ktp';
import ActionBtn from '@/components/common/ActionBtn';
import FlexWrap from '@/components/common/FlexWrap';
import Text from '@/components/common/Text';
import debounce from '@/utils/debounce.util';

function ApplicationContent({ newFranchiseeIndex, onClose }) {
  const [inputData, setInputData] = useState({
    franchiseeIndex: null,
    taxFreeStoreNumber: null,
    accountNumber: null,
    bankName: null,
    withdrawalDate: null,
  });
  const {
    franchiseeIndex,
    taxFreeStoreNumber,
    accountNumber,
    bankName,
    withdrawalDate,
  } = inputData;
  const mutation = useMutation(
    (payload) => applyFranchisee(payload, franchiseeIndex),
    {
      onSuccess: () => {
        alert('가맹점 신청이 완료되었습니다.');
        onClose();
      },
      onError: () => {
        alert('가맹점이 존재하지 않습니다.');
      },
      retry: false,
    },
  );

  useEffect(() => {
    setInputData((prev) => ({ ...prev, franchiseeIndex: newFranchiseeIndex }));
  }, [newFranchiseeIndex]);

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const onSubmit = () => {
    if (!franchiseeIndex) {
      alert(
        '가맹 신청하려는 매장의 No를 입력해주세요. 가맹점 현황 표 좌측에서 확인 가능합니다.',
      );
      return;
    }
    if (!taxFreeStoreNumber) {
      alert('사후면세판매장 번호를 입력해 주세요.');
      return;
    }
    if (!accountNumber) {
      alert('계좌번호를 입력해주세요.');
      return;
    }
    if (bankName === '은행 선택') {
      alert('은행명을 선택해주세요.');
      return;
    }
    if (withdrawalDate === '출금일 선택') {
      alert('출금일을 선택해주세요.');
      return;
    }
    mutation.mutate(inputData);
  };
  const createFranchises = debounce(onSubmit, 200);

  return (
    <TableContainer>
      <Title>가맹점 신청</Title>
      <FlexWrap alignItems='flex-start'>
        <Text text='가맹점 번호(No)' margin='10px 0 0' />
        <Text text='*' color='orange' margin='10px 0 0 4px' />
        <FlexWrap dir='column' alignItems=''>
          <FranchiseeIndexInput
            type='number'
            onChange={onChangeInput}
            min='0'
            placeholder=''
            value={franchiseeIndex}
            name='franchiseeIndex'
          />
          <Text
            size='12'
            lineHeight='14'
            margin='0 0 20px 70px'
            text={`✔︎ 상단에서 계정을 생성을 하면 가맹점 번호는 자동으로 기입됩니다.\n✔︎ 기존 계정에 대해 가맹 신청을 진행하려면 신청하려는 가맹점의 번호(No)를 입력해주세요. 번호는 가맹점 현황 탭에서 확인 가능합니다.`}
          />
        </FlexWrap>
      </FlexWrap>
      <StyledTable width='100%' align='center'>
        {applicationTableData.map((items) => (
          <StyledTr>
            {items.map((item) => (
              <>
                <StyledTh>{item.label}</StyledTh>
                <StyledTd>
                  {item?.selectName ? (
                    <StyledSelect
                      name={item.selectName}
                      onChange={onChangeInput}
                    >
                      {item.optionList?.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </StyledSelect>
                  ) : (
                    <StyledInput
                      {...item.option}
                      value={inputData[item.option.name]}
                      onChange={onChangeInput}
                    />
                  )}
                </StyledTd>
              </>
            ))}
          </StyledTr>
        ))}
      </StyledTable>
      <ActionBtn
        title='신청하기'
        onClick={createFranchises}
        bottom='-8px'
        right='0'
      />
    </TableContainer>
  );
}

const TableContainer = styled.div`
  position: relative;
  width: 100%;
`;
const Title = styled.h3`
  margin: 0;
`;
const borderStyles = css`
  border-collapse: collapse;
  border: 1px solid black;
`;
const StyledTable = styled.table`
  ${borderStyles}
  margin: 0 0 40px;
`;
const StyledTd = styled.td`
  ${borderStyles}
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  min-width: 120px;
  width: ${(props) => props.width || 'auto'};
`;
const StyledTh = styled.td`
  ${borderStyles}
  padding-left: 16px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  background-color: #d3d3d3;
  width: 170px;
`;

const StyledTr = styled.tr`
  ${borderStyles}
  padding: 8px 15px;
  font-size: 14px;
`;
const StyledInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  padding: 8px 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const FranchiseeIndexInput = styled.input`
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 8px 16px;
  margin: 12px 0;
  font-size: 14px;
  margin-left: 60px;
  &::-webkit-inner-spin-button {
    appearance: none;
  }
`;
const StyledSelect = styled.select`
  padding: 8px;
  border: none;
  outline: none;
`;
export default ApplicationContent;
