import { createStore } from 'redux';

const initialState = {
  serverState: 'prod',
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_SERVER_TEST':
      return {
        serverState: 'test',
      };
    case 'SET_SERVER_PROD':
      return { serverState: 'prod' };
    default:
      return state;
  }
}
const store = createStore(reducer);
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
