import { TableBody } from '@material-ui/core';
import styled from 'styled-components';

import store from '@/store';
import FlexWrap from '@/components/common/FlexWrap';
import Typography from '@/components/common/Typography';
import { BillType, KioskDetailWithdrawalInfo } from '@/types/api/kiosk';
import { getKioskDetailWithdrawalInfo } from '@/utils/kiosk';
import KioskDataTable from './KioskDataTable';
import WithdrawalInfoItem from './WithdrawalInfoItem';

type WithdrawalInfoProps = {
  data: KioskDetailWithdrawalInfo[];
  onClick: (value1: string, value2: string) => void;
  billType: BillType;
};

function WithdrawalInfo({ data, onClick, billType }: WithdrawalInfoProps) {
  const { columns } = getKioskDetailWithdrawalInfo(billType);
  const serverState = store.getState().serverState;
  return (
    <Container>
      <FlexWrap alignItems="center" justifyContent="space-between">
        <Typography fontSize="18px" fontWeight="600" lineHeight="26px">
          환급금 출금 내역
        </Typography>
        <MoreBtn
          onClick={() =>
            onClick(
              '환급금 출금 내역',
              `/kiosk/${serverState}/home/withdrawal-detail`,
            )
          }
        >
          + 더보기
        </MoreBtn>
      </FlexWrap>
      <KioskDataTable
        columns={columns}
        contentComponent={
          data.length < 1 ? (
            <Typography>데이터가 없습니다.</Typography>
          ) : (
            <TableBody>
              {data.map((item, idx) => (
                <WithdrawalInfoItem
                  key={idx}
                  idx={idx}
                  data={item}
                  billType={billType}
                />
              ))}
            </TableBody>
          )
        }
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const MoreBtn = styled.button`
  color: #757575;
`;

export default WithdrawalInfo;
