import React, { CSSProperties } from 'react';
import styled from 'styled-components';

interface IProps {
  text: string;
  required?: boolean;
  size?: number;
  color?: string;
  lineHeight?: number;
  weight?: number;
  margin?: number | string;
  style?: CSSProperties;
}
function Text({
  text,
  required,
  color = '#212121',
  size = 15,
  lineHeight = 40,
  weight = 500,
  margin = 0,
  style,
}: IProps) {
  return (
    <StyledText
      color={color}
      size={size}
      lineHeight={lineHeight}
      weight={weight}
      margin={margin}
      style={style}
    >
      {text}
      {required && <StyledStar> *</StyledStar>}
    </StyledText>
  );
}
const StyledText = styled.p<{
  color: string;
  margin: string | number;
  lineHeight: number;
  size: number;
  weight: number;
}>`
  font-size: ${(props) => props.size}px;
  margin: ${(props) => props.margin};
  line-height: ${(props) => props.lineHeight}px;
  color: ${(props) => props.color || '#263238'};
  font-weight: ${(props) => props.weight};
  white-space: pre-wrap;
`;
const StyledStar = styled.span`
  color: orange;
`;
export default Text;
