import styled, { css } from 'styled-components';

interface IProps {
  title?: string;
  margin?: string;
  bgColor?: string;
  color?: string;
  outlineColor?: string;
  fontSize?: string;
  fontWeight?: string | number;
  minWitdh?: string;
  right?: string;
  bottom?: string;
  height?: string;
  active?: boolean;
  style?: any;
}

function Tag({ title, active = true, style, ...tagProps }: IProps) {
  return (
    <StyledTag style={style} active={active}>
      {title}
    </StyledTag>
  );
}

export default Tag;

const StyledTag = styled.div<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${(props) =>
    props.active === false ? '#FF2D55' : props.bgColor || '#D3E2FD'};
  border-radius: 8px;
  color: ${(props) =>
    props.active === false ? '#FFFFFF' : props.color || '#246CF6'};
  padding: 6px 16px;
  min-width: ${(props) => props.minWitdh || '92px'};
  height: ${(props) => props.height || '36px'};
  font-size: ${(props) => props.fontSize || '14px'};
  font-weight: ${(props) => props.fontWeight || 600};
  margin: ${(props) => props.margin || 0};
  border: ${(props) =>
    props.outlineColor ? `1px solid ${props.outlineColor}` : 'none'};
`;
