import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { patchKioskIsActive } from '@/api/kiosk';
import { PatchKioskIsActivePayload } from '@/types/api/kiosk';

function useKioskIsActive() {
  return useMutation<unknown, AxiosError, PatchKioskIsActivePayload>(
    (payload) => patchKioskIsActive(payload),
    {
      retry: false,
      onSuccess: () => {
        alert('키오스크 설정이 완료되었습니다.');
      },
      onError: (e) => {
        alert('에러 발생');
      },
    },
  );
}

export default useKioskIsActive;
