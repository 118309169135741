import React, { ChangeEventHandler, useRef } from 'react';
import styled from 'styled-components';

interface IProps {
  name: string;
  placeholder: string;
  onChangeInput: (e: any) => void;
  text: string;
}
function AutoResizeTextarea({
  name,
  placeholder,
  onChangeInput,
  text,
}: IProps) {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const handleResizeHeight:
    | ChangeEventHandler<HTMLTextAreaElement>
    | undefined = (e) => {
    onChangeInput(e);
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      let height = textarea.scrollHeight;
      textarea.style.height = height - 16 + 'px';
    }
  };
  return (
    <Textarea
      spellCheck={false}
      name={name}
      ref={textareaRef}
      placeholder={placeholder}
      className="autoTextarea"
      onChange={handleResizeHeight}
    >
      {text}
    </Textarea>
  );
}

const Textarea = styled.textarea`
  ::placeholder {
    font-size: 14px;
  }
  min-height: 100px;
  outline: none;
  resize: none;
  padding: 8px 12px;
  font-size: 14px;
  flex: 1;
  border: 1px solid lightgray;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;
export default AutoResizeTextarea;
