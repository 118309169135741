import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { ReactNode } from 'react';

interface IProps {
  columns: any;
  contentComponent: ReactNode;
}
function LatestTable({ columns, contentComponent }: IProps) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            style={{
              borderBottom: '2px solid #BDBDBD',
            }}
          >
            {columns.map((column: any, idx: number) => (
              <TableCell
                key={column.type}
                align="left"
                style={{
                  backgroundColor: '#ffffff',
                  padding: idx === 0 ? '4px 4px 4px 18px' : 4,
                  fontSize: 13,
                }}
              >
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {contentComponent}
      </Table>
    </TableContainer>
  );
}
export default LatestTable;
