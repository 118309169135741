import { useReducer } from 'react';

const initialState = {
  isOpenDetail: false,
  toggle: null,
  filter: { isAll: 'true', withdrawalStatus: 'ALL' },
  selected: null,
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        isOpenDetail: true,
        selected: action.payload.selected,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        isOpenDetail: false,
        selected: action.payload.selected,
      };
    case 'SET_TOGGLE':
      return {
        ...state,
        toggle: action.payload,
      };
    case 'SET_FILTER':
      return {
        ...state,
        filter: action.payload,
      };
    default:
      throw Error(`Unhandled Type Error: ${action.type}`);
  }
}
function usePointReducer() {
  return useReducer(reducer, initialState);
}

export default usePointReducer;
