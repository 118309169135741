import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import FlexWrap from '@components/common/FlexWrap';
import { getCurrentMenuArr } from '@/utils/ktp';
import store from '@/store';
import { useQuery } from 'react-query';
import { signOut } from '@/api/ktp';

interface IMenu {
  path: string;
  title: string;
}
function SideMenu() {
  const { pathname } = useLocation();
  const history = useHistory();
  const [menuArr, setMenuArr] = useState<Array<IMenu>>();
  const isProd = store.getState().serverState === 'prod';

  const { refetch } = useQuery(['logOut'], signOut, {
    retry: false,
    enabled: false,
    onSuccess: () => {
      localStorage.removeItem('loginToken');
      localStorage.removeItem('autoLogin');
      history.push('/');
    },
  });

  useEffect(() => {
    setMenuArr(getCurrentMenuArr(pathname) as Array<IMenu>);
  }, [pathname]);

  const onClick = (path: string) => {
    if (path === '/log-check') {
      window.open(
        'https://ktp.console.nhncloud.com/project/wIOHNU3a/data-analytics/log-crash-search#log-search',
        '_blank',
      );
      return;
    }

    if (path.includes('test')) {
      store.dispatch({ type: 'SET_SERVER_TEST' });
    } else {
      store.dispatch({ type: 'SET_SERVER_PROD' });
    }
    history.push(path);
  };

  const onPressLogout = () => {
    refetch();
  };

  const isActive = (path: string) => {
    if (pathname.split('/')[1] === 'developer') {
      return pathname === path;
    }
    return pathname.includes(path);
  };

  return (
    <Container padding="40px 0" isProd={isProd}>
      <ListContainer>
        {menuArr?.map((item, idx) => (
          <ListItem
            key={idx}
            active={isActive(item.path)}
            onClick={() => onClick(item.path)}
            isProd={isProd}
          >
            {item.title}
          </ListItem>
        ))}
      </ListContainer>
      <LogoutContainer>
        <LogoutBtn onClick={onPressLogout}>로그아웃</LogoutBtn>
      </LogoutContainer>
    </Container>
  );
}

const Container = styled(FlexWrap)<{ isProd: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 280px;
  height: calc(100vh - 80px);
  background-color: ${(props) => (props.isProd ? '#FFFFFF' : '#67af67')};
`;
const ListContainer = styled.ul`
  width: 100%;
`;
const ListItem = styled.li<{ active: boolean; isProd: boolean }>`
  padding: 10px 40px;
  background-color: ${(props) => (props.active ? '#354EFF' : '#FFFFFF')};
  color: ${(props) => (props.active ? '#FFFFFF' : '#2F2F2F')};
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  background-color: ${(props) =>
    props.active ? '#354EFF' : props.isProd ? '#ffffff' : '#67af67'};
`;
const LogoutContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 40px;
`;
const LogoutBtn = styled.button`
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  background-color: #2f2f2f;
  padding: 10px 40px;
  color: #ffffff;
  border-radius: 10px;
`;

export default SideMenu;
