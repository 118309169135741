import styled from 'styled-components';
import { TableRow } from '@material-ui/core';

import { GetKioskFranchiseeStatusContent } from '@/types/api/kiosk';

import CustomTableCell from '@/components/common/CustomTableCell';
import { formatKioskFranchiseeStatus } from '@/utils/kiosk';
import Typography from '@/components/common/Typography';

type KioskListProps = {
  data: GetKioskFranchiseeStatusContent;
  idx: number;
  size: number;
  number: number;
  totalElements: number;
};

function KioskFranchiseeList({
  data,
  idx,
  size,
  number,
  totalElements,
}: KioskListProps) {
  const { kioskId, serviceType, permission, name, createdDate } = data;
  const kioskServiceType = formatKioskFranchiseeStatus(serviceType);
  const kioskPermission = formatKioskFranchiseeStatus(permission);

  // index 구하기 => 전체 요소 - 한페이지 보여지는 수 - 현재페이지 - 현재페이지 인덱스
  const indexNumber = totalElements - size * number - idx;

  return (
    <StyledTableRow idx={idx}>
      <CustomTableCell idx={0} width="50">
        {indexNumber < 10 ? String(indexNumber).padStart(2, '0') : indexNumber}
      </CustomTableCell>
      <CustomTableCell width="50">
        <Typography
          fontSize="12px"
          color={kioskServiceType === '매장' ? '#FF2D55' : '#246CF6'}
          background="#F5F6F7"
          padding="2px 4px 2px 4px"
          fontWeight="600"
          borderRadius="3px"
          lineHeight="150%"
        >
          {kioskServiceType}
        </Typography>
      </CustomTableCell>
      <CustomTableCell width="50">
        <Typography
          fontSize="12px"
          color="#3A3B3E"
          background={kioskPermission === '내부' ? '#E5E6E8' : '#fff'}
          padding="2px 4px 2px 4px"
          fontWeight="600"
          borderRadius="3px"
          lineHeight="150%"
        >
          {kioskPermission}
        </Typography>
      </CustomTableCell>
      <CustomTableCell width="400">{name}</CustomTableCell>
    </StyledTableRow>
  );
}

const StyledTableRow = styled(TableRow)<{ idx: number }>`
  background-color: #ffffff;
  border-bottom: ${(props) =>
    (props.idx + 1) % 5 === 0
      ? '2px solid rgb(220, 220, 220)'
      : '1px solid rgb(220, 220, 220)'};
  :hover {
    background-color: #f7f9fc;
  }
`;

export default KioskFranchiseeList;
