import React from 'react';
import styled from 'styled-components';
import { FormField } from '../form/FormField';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import ConfirmModal from '../confirmModal/ConfirmModal';
import {
  SALES_STATUS_LABEL_LIST,
  TODO_STATUS_LABEL_LIST,
} from '@/constants/sales/label';
import { SalesStatus, TodoStatus } from '@/types/sales.types';

interface IFormInput {
  salesStatus?: SalesStatus;
  todoStatus?: TodoStatus;
  todoDueDate?: string;
}

type Props = {
  onClose: () => void;
  onSave: (data: IFormInput) => void;
  defaultValues?: IFormInput;
};

function FinalModal({ onClose, onSave, defaultValues }: Props) {
  const {
    register,
    handleSubmit,
    watch: watchInformation,
  } = useForm<IFormInput>({
    defaultValues: defaultValues || {
      salesStatus: 'INQUIRY',
      todoStatus: 'CONTACT',
      todoDueDate: format(new Date(), 'yyyy-MM-dd'),
    },
  });

  return (
    <ConfirmModal
      title='상태 확인'
      onCancel={onClose}
      onOK={handleSubmit(onSave)}
    >
      <Form>
        <FormField.Dropdown
          register={register('salesStatus')}
          label='상태'
          options={SALES_STATUS_LABEL_LIST}
          watch={watchInformation}
        />
        <FormField.Dropdown
          register={register('todoStatus')}
          label='Do List'
          options={TODO_STATUS_LABEL_LIST}
          watch={watchInformation}
        />
        <FormField.DatePicker
          register={register('todoDueDate')}
          label='Do List 기한'
          watch={watchInformation}
        />
      </Form>
    </ConfirmModal>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
`;

export default FinalModal;
