import { postQnaAnswer } from '@/api/ktp';
import { postQnaMedicalAnswer } from '@/api/medical';
import useGetRequestPath from '@/hooks/common/useGetRequestPath';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';

type Props = {
  id?: number;
};

function QnaType({ id = 0 }: Props) {
  const [content, setContent] = useState('');
  const isMedical = useGetRequestPath(/medical/);
  const queryClient = useQueryClient();

  const { mutate: sendAnswer } = useMutation(
    () => {
      return isMedical
        ? postQnaMedicalAnswer({
            touristId: id,
            content,
          })
        : postQnaAnswer({
            customerId: id,
            content,
          });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('qnaDetail');
        setContent('');
      },
    },
  );

  return (
    <Wrapper>
      <Title>문의 유형</Title>
      <ContentsWrapper>
        <TypeWrapper>
          <label htmlFor="normal_qna">일반 문의</label>
          <InputRadio id="normal_qna" type="radio" defaultChecked />
        </TypeWrapper>
        <TextareaStyle
          name=""
          id=""
          placeholder="문의 답변을 입력해주세요."
          maxLength={2000}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        ></TextareaStyle>
      </ContentsWrapper>
      <SendButton
        type="button"
        onClick={() => sendAnswer()}
        disabled={content.length === 0}
      >
        발송하기
      </SendButton>
    </Wrapper>
  );
}

export default QnaType;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.h6`
  margin: 0 0 16px;
  color: #000;
  font-size: 18px;
  font-weight: 700;
`;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
`;

const TypeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  width: 210px;
  padding: 12px;
  color: #246cf6;
  background-color: #f4f8ff;
`;

const InputRadio = styled.input`
  margin-bottom: 3px;
`;

const TextareaStyle = styled.textarea`
  border: 1px solid #e5e6e8;
  border-radius: 20px;
  height: 100%;
  padding: 20px;
  resize: none;
`;

const SendButton = styled.button`
  border-radius: 6px;
  padding: 10px 24px;
  margin: 16px 0 0;
  font-size: 16px;
  color: #fff;
  background: #246cf6;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
