import { TableBody } from '@material-ui/core';
import RefundStatusItem from './RefundStatusItem';

function RefundStatusList({ refunds, refundType, onOpenModal }) {
  return (
    <TableBody>
      {refunds.length !== 0 ? (
        refunds.map((refund, idx) => (
          <RefundStatusItem
            key={refund.refundIndex}
            idx={idx}
            refund={refund}
            refundType={refundType}
            onOpenModal={onOpenModal}
          />
        ))
      ) : (
        <p>
          설정한 기간에 해당하는 환급내역이 없습니다. 날짜 형식을 확인하세요.
        </p>
      )}
    </TableBody>
  );
}
export default RefundStatusList;
