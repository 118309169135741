import { useReducer } from 'react';

const initialState = {
  filter: {
    transferView: '',
    paymentStatus: '',
    orderBy: 'DEFAULT',
    mailSendCounts: '',
  },
  size: 100,
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'SET_TRANSFERVIEW':
      return {
        ...state,
        filter: {
          transferView: action.payload,
          paymentStatus: '',
          orderBy: 'DEFAULT',
          mailSendCounts: '',
        },
      };
    case 'SET_SIZE':
      return {
        ...state,
        size: action.payload,
      };
    case 'SET_FILTER':
      return {
        ...state,
        filter: {
          ...state.filter,
          orderBy: action.payload.orderBy,
          paymentStatus: action.payload.paymentStatus,
          mailSendCounts: action.payload.mailCount,
        },
      };
    case 'SET_SELECTED':
      return {
        ...state,
        selected: action.payload,
      };
    default:
      throw Error(`Unhandled Type Error: ${action.type}`);
  }
}

const useRemittanceReducer = () => {
  return useReducer(reducer, initialState);
};

export default useRemittanceReducer;
