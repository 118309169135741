import React, { useEffect } from 'react';

function useLockBodyScroll(isOpenModal: boolean) {
  useEffect(() => {
    if (isOpenModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpenModal]);
}

export default useLockBodyScroll;
