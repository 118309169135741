import { useReducer } from 'react';

const initialState = {
  isModal: false,
  selectedData: {
    type: undefined,
    noticeIndex: undefined,
  },
};
const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'CLOSE_MODAL':
      return initialState;
    case 'OPEN_MODAL':
      return {
        isModal: true,
        selectedData: {
          type: action.payload.type,
          noticeIndex: action.payload.noticeIndex,
        },
      };
    default:
      break;
  }
};

function useNoticeReducer() {
  return useReducer(reducer, initialState);
}

export default useNoticeReducer;
