import React, { KeyboardEvent, useCallback, useState } from 'react';
import styled from 'styled-components';
import { DataTableColumn, DataTableRow, Sort } from '@/types/sales.types';
import useUpdateUrlParams from '@/hooks/sales/useUpdateUrlParams';
import FilterDefaultIcon from '../../../assets/common/filter_default.png';
import FilterUpIcon from '../../../assets/common/filter_up.png';
import FilterDownIcon from '../../../assets/common/filter_down.png';
import TableBodyRow from './TableBodyRow';

type Props = {
  columns: DataTableColumn[];
  rows: DataTableRow[] | DataTableRow[][];
  onClickRow?: (id: number) => void;
  initialSortedColumn: string;
  initialSortType: Sort;
};

function DataTable({
  columns,
  rows,
  onClickRow = () => {},
  initialSortedColumn,
  initialSortType,
}: Props) {
  const [sortType, setSortType] = useState(initialSortType);
  const [sortedColumn, setSortedColumn] = useState(initialSortedColumn);

  const updateUrlParams = useUpdateUrlParams();

  const sortIcon = useCallback(
    (column) => {
      if (column !== initialSortedColumn) {
        return FilterDefaultIcon;
      }

      if (initialSortType === 'none') {
        return FilterDefaultIcon;
      } else if (initialSortType === 'ascending') {
        return FilterUpIcon;
      } else {
        return FilterDownIcon;
      }
    },
    [initialSortType, initialSortedColumn],
  );

  const onSort = useCallback(
    ({ sortedColumn, sortType }: { sortedColumn: string; sortType: Sort }) => {
      updateUrlParams({ sortedColumn, sortType });
    },
    [updateUrlParams],
  );

  const onSortRows = useCallback(
    (type: string) => {
      let currentSortType: Sort;
      if (type !== sortedColumn) {
        currentSortType = 'ascending';
      } else {
        currentSortType =
          sortType === 'none'
            ? 'ascending'
            : sortType === 'ascending'
            ? 'descending'
            : 'none';
      }

      if (currentSortType === 'none') {
        setSortType('none');
      } else {
        setSortType(currentSortType);
        setSortedColumn(type);
      }

      onSort({ sortedColumn: type, sortType: currentSortType });
    },
    [sortType, sortedColumn, setSortType, setSortedColumn, onSort],
  );

  const isSortedColumn = useCallback(
    (field: string) => {
      return initialSortType !== 'none' && initialSortedColumn === field;
    },
    [initialSortType, initialSortedColumn],
  );

  const onEnterColumn = (e: KeyboardEvent, type: string) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSortRows(type);
    }
  };

  const onEnterRow = (e: KeyboardEvent, index: number) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onClickRow(index);
    }
  };

  return (
    <Table>
      <TableHead>
        <TableHeadRow role='row'>
          {columns.map(({ type, title, width, sortable, align = 'center' }) => (
            <TableHeader
              key={type}
              width={width}
              sortable={sortable}
              role='columnheader'
              scope='col'
              onClick={() => sortable && onSortRows(type)}
              aria-sort={sortedColumn === type ? sortType : 'none'}
              tabIndex={sortable ? 0 : -1}
              onKeyDown={(e) => onEnterColumn(e, type)}
            >
              <Header isSorted={isSortedColumn(type)} align={align}>
                <HeaderLabel>{title}</HeaderLabel>
                {sortable && (
                  <img src={sortIcon(type)} width='24px' height='24px' alt='' />
                )}
              </Header>
            </TableHeader>
          ))}
        </TableHeadRow>
      </TableHead>
      <tbody>
        {Array.isArray(rows) && Array.isArray(rows[0])
          ? (rows as DataTableRow[][]).map((row, rowsIndex) =>
              row.map((data, rowIndex) => (
                <TableBodyRow
                  key={data.id}
                  columns={columns}
                  data={data}
                  onClickRow={onClickRow}
                  onEnterRow={onEnterRow}
                  hasDivider={rowsIndex === 1 && rowIndex === 0}
                  highlight={rowsIndex === 0}
                />
              )),
            )
          : (rows as DataTableRow[]).map((data) => (
              <TableBodyRow
                key={data.id}
                columns={columns}
                data={data}
                onClickRow={onClickRow}
                onEnterRow={onEnterRow}
              />
            ))}
      </tbody>
    </Table>
  );
}

const Table = styled.table`
  width: 100%;
  background-color: #ffffff;
  color: #030303;
  border-collapse: collapse;
`;

const TableHeadRow = styled.tr`
  height: 48px;
  border-bottom: 1px solid #cbccce;
`;

const TableHead = styled.thead``;

const TableHeader = styled.th<{
  width?: number;
  sortable?: boolean;
}>`
  padding: 0 20px;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  cursor: ${({ sortable = false }) => (sortable ? 'pointer' : 'default')};
`;

const Header = styled.div<{
  isSorted: boolean;
  align?: 'center' | 'left' | 'right';
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ align = 'center' }) => align};
  color: ${({ isSorted }) => (isSorted ? '#246CF6' : '#030303')};
`;

const HeaderLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`;

export default DataTable;
