import { createGlobalStyle, DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  narrowPC: '(max-width: 1700px)',
};

export const GlobalStyle = createGlobalStyle`
  * {
  box-sizing: border-box;
  font-family: 'Pretendard', sans-serif;
  }
  html,
  body,
  h1,
  h2,
  h3,
  p {
    margin: 0;
    padding: 0;
  }
  html {
    @media screen and (min-width: 1280px){
      overflow-y: hidden;
    }
  }
  ul {
    padding: 0;
  }
  li {
    list-style: none;
  }
  button{
    border:none;
      cursor:pointer;
      background-color:transparent;
      padding: 0;
      :hover{
        opacity:0.8;
      }
      transition:200ms all ease-in;
  }
`;
