import React from 'react';
import { Bar } from 'react-chartjs-2';

const EtcBar = ({ statistics }) => {
  const {
    numberOfUsers,
    numberOfReservation,
    numberOfCompletedReservation,
    numberOfAcceptedReservation,
  } = statistics;
  const data = {
    labels: ['전체 사용자', '전체 식사', '수락된 식사', '완료된 식사'],
    datasets: [
      {
        label: 'Count',
        data: [
          numberOfUsers,
          numberOfReservation,
          numberOfAcceptedReservation,
          numberOfCompletedReservation,
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(80, 48, 105, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(80, 48, 105, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: 'y',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      title: {
        display: true,
        font: {
          size: 20,
        },
        text: '기타 통계',
      },
    },
  };

  return <Bar width={400} height={400} data={data} options={options} />;
};

export default EtcBar;
