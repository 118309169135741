import styled from 'styled-components';

import { KioskTrendDetailInfo, KioskTrendInfo } from '@/types/api/kiosk';
import { BillType, KioskDetailRemainingInfo } from '@/types/api/kiosk';
import { cashMonitoringDataType } from '@/types/kiosk.type';

import {
  formatBillType,
  formatKioskTotalCount,
  formatKioskTrendInfo,
} from '@/utils/kiosk';

import FlexWrap from '@/components/common/FlexWrap';
import Typography from '@/components/common/Typography';
import { trendInfoDetailData } from '@/constants/kiosk';
import { useLocation } from 'react-router-dom';

export type CashBalanceMonitorProps = {
  remainingInfo: KioskDetailRemainingInfo;
  billType: BillType;
  cashMonitoringData: cashMonitoringDataType;
  trendInfo?: KioskTrendInfo;
};

function CashMonitor({
  remainingInfo,
  billType,
  cashMonitoringData,
  trendInfo,
}: CashBalanceMonitorProps) {
  const { pathname } = useLocation();
  const { type, maxValue } = cashMonitoringData;
  const totalCount = formatKioskTotalCount(type, remainingInfo);
  const trendInfoDetail = formatKioskTrendInfo(type, trendInfo);

  return (
    <FlexWrap flex="1" dir="column">
      <Item
        active={!!billType[type]}
        bgColor={pathname.includes('charging-detail') ? '#ffffff' : '#E5E6E8'}
      >
        <Typography>
          {type.toUpperCase()}({formatBillType(billType[type])})
        </Typography>
        <Typography color="#5F6165">
          <Typography
            color={
              trendInfoDetail && trendInfoDetail.isNeedCharge
                ? '#FF2D55'
                : '#212121'
            }
          >
            {totalCount}
          </Typography>{' '}
          / {maxValue}
        </Typography>
      </Item>
      {trendInfoDetail &&
        trendInfoDetailData.columns.map((trendInfoData) => {
          const trendInfoDataType =
            trendInfoData.type as keyof KioskTrendDetailInfo;
          return (
            <Item active={!!billType[type]}>
              <Typography>{trendInfoData.title}</Typography>
              <Typography
                color={
                  trendInfoDetail && trendInfoDetail.isNeedCharge
                    ? '#FF2D55'
                    : '#212121'
                }
              >
                {trendInfoDetail ? trendInfoDetail[trendInfoDataType] : '-'}
              </Typography>
            </Item>
          );
        })}
    </FlexWrap>
  );
}

const Item = styled.div<{ active: boolean; bgColor?: string }>`
  opacity: ${(props) => (props.active ? 1 : 0.4)};
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  padding: 12px 20px;
  background-color: ${(props) => props.bgColor || '#ffffff'};
  span {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;

export default CashMonitor;
