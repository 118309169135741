import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import LogoCompanyIcon from '@/assets/receipt/logo.png';
import LogoServiceIcon from '@/assets/receipt/logo_service.png';
import DashedLineImg from '@/assets/receipt/dashedLine.png';
import FlexWrap from '@/components/common/FlexWrap';
import TableRow from '@/components/ktp/refund-status/TableRow';

export interface IRefundRow {
  title?: string;
  subTitle?: string;
  content?: string | null;
  isLast?: boolean;
  info?: string;
  hasComma?: boolean;
  isForPrinting?: boolean;
  refundAfter?: boolean;
  hasBarcode?: boolean;
}
export interface IData {
  purchaseSn: string;
  refundAfter: boolean;
  barcodeS3Path: null | string;
  rowDataArr: Array<IRefundRow>;
}
interface IProps {
  info: IData;
  index: number;
  style?: any;
  setFiles: any;
  nation?: any;
}
function ReceiptImage({ info, index, style, setFiles, nation }: IProps) {
  const receiptRef = useRef<HTMLDivElement | null>(null);
  const fileRef = useRef(null);
  useEffect(() => {
    if (fileRef.current) {
      setFiles && setFiles((prev: any) => [...prev, fileRef.current]);
    }
  }, []);

  return (
    //@ts-ignore
    <Wrapper ref={fileRef}>
      <ImageContainer dir="column" alignItems="center">
        <ReceiptContainer ref={receiptRef} className="notranslate">
          <ContentBox>
            <LogoContainer gap="20px" alignItems="center">
              <LogoCompany src={LogoCompanyIcon} />
              <LogoService src={LogoServiceIcon} />
            </LogoContainer>
            <AdditionalInfo isSerialNumber>{info?.purchaseSn}</AdditionalInfo>
            <AdditionalInfo>
              KTP 환급전표
              <br />
              Korea Taxfree Payment receipt fot Tax Refund
              <br />
              https://ktaxpay.com/
              <br />
              (국세청인정서식 Authorized by Korea NTS)
            </AdditionalInfo>
            <RowWrap>
              {info.rowDataArr.map((item, idx) => (
                <TableRow key={idx} {...item} refundAfter={info.refundAfter} />
              ))}
            </RowWrap>
            {info.refundAfter && (
              <>
                <BlackGuideText>
                  Pls present your passport, goods and receipt
                </BlackGuideText>
                <AdditionalInfo isSerialNumber>
                  {info.purchaseSn}
                </AdditionalInfo>
                <DashedLine src={DashedLineImg} />
              </>
            )}
            <GrayText>https://ktaxpay.com </GrayText>
            <FlexWrap justifyContent="space-evenly">
              <GrayText>석세스모드</GrayText>
              <GrayText>제 138-00078 호</GrayText>
            </FlexWrap>
            <br />
            <GrayText>{`- ${index + 1} - `}</GrayText>

            <>
              <br />
              <br />
              {(nation === 'CHN' || nation === 'JPN') && (
                <GrayText>※ 송금 수수료는 발신인 부담</GrayText>
              )}
            </>
          </ContentBox>
        </ReceiptContainer>
      </ImageContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  bottom: 200%;
`;
const ImageContainer = styled(FlexWrap)`
  margin: 36px 0 50px;
`;
const ReceiptContainer = styled.div`
  width: 335px;
  background-color: #fff;
`;
const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const LogoContainer = styled(FlexWrap)`
  margin: 40px 0 24px;
`;
const LogoCompany = styled.img`
  height: 29px;
  width: 148px;
`;
const LogoService = styled.img`
  height: 33px;
  width: 128px;
`;

const AdditionalInfo = styled.p<{ isSerialNumber?: boolean }>`
  text-align: center;
  color: #5f6165;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: ${(props) => (props.isSerialNumber ? '10px' : '35px')};
`;
const RowWrap = styled.div`
  padding: 0 20px;
`;
const GrayText = styled.p`
  color: #5f6165;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
`;
const DashedLine = styled.img`
  width: 100%;
  height: 1px;
  padding: 0 20px;
  margin-bottom: 16px;
`;
const BlackGuideText = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin: 100px 0 10px;
  text-align: center;
`;
export default ReceiptImage;
