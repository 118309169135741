import React from 'react';
import styled from 'styled-components';
import { TableRow } from '@material-ui/core';
import { format } from 'date-fns';

import CustomTableCell from '../../common/CustomTableCell';
import DetailBtn from '../../common/DetailBtn';

const ListItem = ({ data, onOpenModal, idx }) => {
  const {
    noticeIndex,
    title,
    createdDate,
    scheduledDate,
    isFixed,
    isInvisible,
  } = data;
  return (
    <>
      <StyledTableRow
        style={
          (idx + 1) % 5 === 0
            ? { borderBottom: '2px solid rgb(220, 220, 220)' }
            : { borderBottom: '1px solid rgb(220, 220, 220)' }
        }
      >
        <CustomTableCell width="40" idx={0}>
          {noticeIndex}
        </CustomTableCell>
        <CustomTableCell width="370">{title}</CustomTableCell>
        <CustomTableCell width="150">
          {format(new Date(createdDate), 'yyyy.MM.dd')}
        </CustomTableCell>
        <CustomTableCell width="150">
          {format(new Date(scheduledDate), 'yyyy.MM.dd HH:mm')}
        </CustomTableCell>
        <CustomTableCell width="80">{isFixed ? 'O' : '-'}</CustomTableCell>
        <CustomTableCell width="80">{isInvisible ? '-' : 'O'}</CustomTableCell>
        <CustomTableCell width="80">
          <DetailBtn onClick={onOpenModal}>상세보기</DetailBtn>
        </CustomTableCell>
      </StyledTableRow>
    </>
  );
};
const StyledTableRow = styled(TableRow)`
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;
export default ListItem;
