import React from 'react';
import ConfirmModal from './ConfirmModal';
import styled from 'styled-components';

type Props = {
  onKeep: () => void;
  onDelete: () => void;
};

function DeleteModal({ onKeep, onDelete }: Props) {
  return (
    <ConfirmModal
      title="삭제하시겠습니까?"
      onCancel={onKeep}
      onOK={onDelete}
      cancelLabel="닫기"
      okLabel="삭제">
      <Message>삭제한 히스토리는 더이상 복구되지 않습니다.</Message>
    </ConfirmModal>
  );
}

const Message = styled.p`
  font-size: 18px;
  font-weight: 500px;
  line-height: 27px;
  color: #5f6165;
  text-align: center;
`;

export default DeleteModal;
