import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';
import { StoreListType } from '@/hooks/ktp/usePushNotification';

type SearchFranchaseinessItemProps = {
  idx: number;
  store: StoreListType;
  selectedStoreList: StoreListType[];
  setSelectedStoreList: Dispatch<SetStateAction<StoreListType[]>>;
  filteredStoreList: StoreListType[];
};

function SearchFranchaseinessItem({
  idx,
  store,
  selectedStoreList,
  setSelectedStoreList,
  filteredStoreList,
}: SearchFranchaseinessItemProps) {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    const newArray = selectedStoreList.map((item) => item.keyword);
    if (newArray.length > 0 && newArray.includes(store.keyword)) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selectedStoreList, filteredStoreList]);

  const onClickSelect = (store: StoreListType) => {
    setSelectedStoreList((prev) => [...prev, store]);
    setIsSelected(true);
  };

  return (
    <FranchaseinessItem>
      <FranchaseinessContent flex={1}>{idx + 1}</FranchaseinessContent>
      <FranchaseinessContent flex={3}>
        {store.businessNumber}
      </FranchaseinessContent>
      <FranchaseinessContent flex={6}>{store.keyword}</FranchaseinessContent>
      <FranchaseinessContent flex={1.2}>
        <FranchaseinessBtn
          disabled={isSelected}
          onClick={() => onClickSelect(store)}
        >
          선택하기
        </FranchaseinessBtn>
      </FranchaseinessContent>
    </FranchaseinessItem>
  );
}

const FranchaseinessItem = styled(FlexWrap)`
  align-items: center;
  padding: 12px 40px;
  :first-child {
    border-top: 2px solid #bdbdbd;
  }
  :not(:last-child) {
    border-bottom: 1px solid #bdbdbd;
  }
`;
const FranchaseinessContent = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex};
`;
const FranchaseinessBtn = styled.button`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #354eff;
  border: 2px solid #354eff;
  padding: 4px 16px;
  border-radius: 4px;
  :hover {
    opacity: 0.8;
  }
  :disabled {
    color: #bdbdbd;
    background-color: #f5f5f5;
    border: 2px solid #bdbdbd;
    cursor: auto;
  }
`;

export default SearchFranchaseinessItem;
