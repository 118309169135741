import styled from 'styled-components';
import Select from 'react-select';

import FlexWrap from '@/components/common/FlexWrap';

export type IProps = {
  id: string;
  label?: string;
  options: any[];
  value: any;
  onChange: (value: any, id: string) => void;
};

function CashBillSetting({ id, label, options, value, onChange }: IProps) {
  return (
    <SelectContainer>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Select
        id={id}
        options={options}
        onChange={(value) => onChange(value, id)}
        value={value}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: '104px',
          }),
        }}
      />
    </SelectContainer>
  );
}

export default CashBillSetting;

const SelectContainer = styled(FlexWrap)<{
  padding?: string;
  flexDirection?: string;
  gap?: string;
}>`
  width: 100%;
  align-items: center;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  gap: ${({ gap }) => gap || '12px'};
  padding: ${({ padding }) => padding || '0'};
`;
const Label = styled.label`
  margin-right: 16px;
`;
const Button = styled.button`
  padding: 8px 24px;
  border-radius: 8px;
  background-color: #354eff;
  color: #ffffff;
`;
