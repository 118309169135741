import styled from 'styled-components';

interface IProps {
  disabled?: boolean;
  color?: string;
  onClick?: () => void;
  children?: any;
}
function DetailBtn({ disabled = false, color, onClick, children }: IProps) {
  return (
    <StyledBtn
      disabled={disabled}
      color={color}
      onClick={disabled ? () => {} : onClick}
    >
      {children}
    </StyledBtn>
  );
}
const StyledBtn = styled.button<{ disabled: boolean; color?: string }>`
  border: 1px solid ${(props) => props.color || '#bdbdbd'};
  border-radius: 4px;
  font-size: 13px;
  padding: 5px 15px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  background-color: ${(props) => (props.disabled ? '#F5F5F5' : '#ffffff')};
  width: 100px;
  color: ${(props) => props.color || '#757575'};
`;
export default DetailBtn;
