import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { getRefundDetail } from '@/api/ktp';
import DetailContent from '@/components/ktp/refund-status/DetailContent';
import Modal from '@/components/common/Modal';

function DetailModal({ onClose, selected }) {
  const { refundIndex } = selected;
  const [prevData, setPrevData] = useState();
  const [data, setData] = useState();
  const { isLoading: isFetchLoading, isError } = useQuery(
    ['franchiseeDetail', refundIndex],
    () => getRefundDetail(refundIndex),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setPrevData(data);
        setData(data);
      },
    },
  );

  const onModalClose = useCallback(() => {
    onClose('DETAIL');
  }, [onClose]);

  return (
    <Modal
      width="1600px"
      onClose={onModalClose}
      error={
        isError && '환급 상세보기 조회에 실패했습니다. 관리자에게 문의해주세요.'
      }
      isLoading={isFetchLoading}
    >
      {prevData && (
        <DetailContent
          setData={setData}
          data={data}
          refundIndex={refundIndex}
          onClose={onModalClose}
        />
      )}
    </Modal>
  );
}

export default React.memo(DetailModal);
