import axios from 'axios';
import api from '..';

const getTargetServerURL = () => {
  return 'https://erp.successmode.co.kr';
};

export const getHostApplicant = async () => {
  const res = await api.getAxios(`${getTargetServerURL()}/host-applicant`);
  return res.data;
};
export const acceptHost = async (id) => {
  const res = await api.deleteAxios(
    `${getTargetServerURL()}/host-applicant/${id}`,
  );
  return res.data;
};
export const rejectHost = async ({ id, rejectReason }) => {
  const res = await api.putAxios(
    `${getTargetServerURL()}/host-applicant/${id}`,
    {
      rejectReason,
    },
  );
  return res.data;
};
export const getStatistics = async () => {
  const res = await api.getAxios(`${getTargetServerURL()}/statistics`);
  return res.data;
};
export const getUserInfo = async () => {
  const res = await api.getAxios(`${getTargetServerURL()}/users`);
  return res.data;
};
