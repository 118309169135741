import React, { useEffect, useRef } from 'react';

interface IProps {
  content: string;
}
function AutoResizeDiv({ content }: IProps) {
  const divRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (divRef) {
      divRef.current!.innerHTML = content.replace(/\n/g, '<br/>');
    }
  }, [content]);
  return <div ref={divRef} style={{ fontSize: 14, marginBottom: '48px' }} />;
}
export default AutoResizeDiv;
