import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Typography from '@/components/common/Typography';
import store from '@/store';
import { BillType } from '@/types/api/kiosk';

type KioskTitleProps = {
  title: string;
  kioskId?: number;
  preTitle?: string;
  detailTitle?: string;
  billType?: BillType;
};

function KioskTitle({
  kioskId,
  preTitle,
  title,
  detailTitle,
  billType,
}: KioskTitleProps) {
  const history = useHistory();
  const serverState = store.getState().serverState;

  const onClickMoveKioskPage = (url: string) => {
    history.push({
      pathname: url,
    });
  };

  const onClickMoveKioskDetailPage = (url: string) => {
    history.push({
      pathname: url,
      state: { kioskId, preTitle: '키오스크 상태', title, billType },
    });
  };

  return (
    <Container>
      {preTitle && (
        <TitleBtn
          onClick={() =>
            onClickMoveKioskPage(`/kiosk/${serverState}/home`)
          }
        >
          <Typography
            fontSize="22px"
            fontWeight="600"
            lineHeight="33px"
            color="#80848A"
            margin="0 7px 0 0"
          >
            {preTitle + ' > '}
          </Typography>
        </TitleBtn>
      )}
      <TitleBtn
        onClick={() =>
          onClickMoveKioskDetailPage(
            `/kiosk/${serverState}/home/detail`,
          )
        }
      >
        <Typography
          fontSize="22px"
          fontWeight="600"
          lineHeight="33px"
          color={detailTitle ? '#80848A' : '#030303'}
          margin="0 7px 0 0"
        >
          {title}
          {detailTitle && ' >'}
        </Typography>
      </TitleBtn>
      {detailTitle && (
        <Typography
          fontSize="22px"
          fontWeight="600"
          lineHeight="33px"
          color="#030303"
          margin="0 7px 40px 0"
        >
          {detailTitle}
        </Typography>
      )}
    </Container>
  );
}
const Container = styled.div``;
const TitleBtn = styled.button`
  margin-bottom: 40px;
`;
export default KioskTitle;
