import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { acceptFranchisee, getControlCodeList } from '../../../api/ktp';
import ControlCodeList from './ControlCodeList';
import LoadingView from '@/components/common/LoadingView';

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

function AcceptFranchisee({
  onCancel,
  selected,
  setActive,
  balancePercentage,
}) {
  const { franchiseeIndex, businessNumber } = selected;
  const queryClient = useQueryClient();
  const [selectedCode, setSelectedCode] = useState(null);
  const { isLoading, data, isError, error, isSuccess } = useQuery(
    'controlCode',
    getControlCodeList,
  );

  const onClickControlCode = useCallback((controlCode) => {
    setSelectedCode((prevState) =>
      controlCode.valueCode === prevState?.valueCode ? null : controlCode,
    );
  }, []);

  const acceptMutation = useMutation((payload) => acceptFranchisee(payload), {
    onError: (error) => {
      console.log(error);
      alert(`가맹점 신청 수락 실패\nError Msg: ${error.message}`);
    },
    onSuccess: () => {
      alert('가맹점 신청 수락 완료');
      queryClient.invalidateQueries('franchiseeList');
    },
  });

  const onAccept = useCallback(() => {
    if (!selectedCode) {
      alert('연결할 가맹점 관리코드를 선택해주세요.');
      return;
    }

    if (selectedCode.codeStatus === 'IN_USED') {
      alert('이미 사용 중인 가맹점 관리코드입니다.');
      return;
    }
    const isOK = window.confirm('정말 승인하시겠습니까?');
    isOK &&
      acceptMutation.mutate({
        franchiseeIndex,
        franchiseeNumber: selectedCode.valueCode,
        franchiseeName: selectedCode.nameCode,
        balancePercentage,
        businessNumber,
      });
    setActive(false);
  }, [selectedCode, franchiseeIndex, businessNumber]);

  const columns = [
    '선택',
    '관리코드',
    '상세정보',
    '즉시환급여부',
    '사후환급여부',
    '사용가능여부',
  ];

  return (
    <>
      <DialogTitle id="form-dialog-title">가맹점 관리코드 목록</DialogTitle>
      <DialogContent>
        <DialogContentText>
          연결 가능한 가맹점 관리코드가 없다면 Refund 페이지로 이동하여 가맹점
          관리코드를 발급 받아주세요.
        </DialogContentText>
        {isLoading && <LoadingView />}
        {isError && (
          <ContentContainer>
            <DialogContentText>
              가맹점 관리코드 조회에 실패했습니다. 관리자에게 문의해주세요.
            </DialogContentText>
            <DialogContentText>{error.message}</DialogContentText>
          </ContentContainer>
        )}
        {isSuccess && (
          <TableContainer style={{ height: 500 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column} align="center">
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <ControlCodeList
                controlCodes={data || []}
                selectedCode={selectedCode}
                onClick={onClickControlCode}
              />
            </Table>
          </TableContainer>
        )}
        {isSuccess && data && data.length === 0 && (
          <ContentContainer>
            <DialogContentText>
              연결 가능한 가맹점 관리코드가 존재하지 않습니다.
            </DialogContentText>
          </ContentContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onAccept} color="primary">
          수락
        </Button>
        <Button onClick={() => onCancel(false)}>취소</Button>
      </DialogActions>
    </>
  );
}

export default React.memo(AcceptFranchisee);
