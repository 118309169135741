import React, { ChangeEvent, ChangeEventHandler } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import styled from 'styled-components';

import FormItemLayout from '@/components/medical/notice/FormItemLayout';
import { ICreateNoticeBody } from '@/types/api/medical';

interface IProps {
  state: ICreateNoticeBody;
  onChangeChecked: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  onChangeRadio: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  onChangeInput: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  isEditMode: boolean;
}
function Form({
  state,
  onChangeChecked,
  onChangeRadio,
  onChangeInput,
  isEditMode = false,
}: IProps) {
  return (
    <FormContainer>
      <FormControlLabel
        style={{ width: 230 }}
        control={
          <Checkbox
            checked={state.fixed}
            onChange={onChangeChecked}
            name="fixed"
            color="primary"
            disabled={!isEditMode}
          />
        }
        label="공지사항 화면 상단에 고정"
      />
      <ContentContainer>
        <FormItemLayout label="공지기간" required>
          <RadioGroup
            value={state.immediate ? 'immediate' : 'scheduled'}
            onChange={onChangeRadio}
          >
            <FormControlLabel
              value="immediate"
              control={
                <Radio
                  checked={state.immediate}
                  name="immediate"
                  color="primary"
                  size="small"
                  disabled={!isEditMode}
                />
              }
              label="즉시"
            />
            <FlexWrap>
              <FormControlLabel
                value="scheduled"
                control={
                  <Radio name="scheduled" color="primary" size="small" />
                }
                label="예약 공지"
                disabled={!isEditMode}
              />
              <TextField
                variant="outlined"
                disabled={!isEditMode || state.immediate}
                name="scheduledDate"
                onChange={onChangeInput}
                size="small"
                id="datetime-local"
                type="datetime-local"
                value={state.scheduledDate}
              />
            </FlexWrap>
          </RadioGroup>
        </FormItemLayout>
        <FormItemLayout label="제목" required>
          <StyledInput
            name="title"
            defaultValue={state.title}
            onChange={onChangeInput}
            placeholder="공지사항 제목을 입력해주세요."
            disabled={!isEditMode}
          />
        </FormItemLayout>
      </ContentContainer>
    </FormContainer>
  );
}
const FormContainer = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 0;
`;
const FlexWrap = styled.div`
  display: flex;
  align-items: center;
`;
const StyledInput = styled.input`
  padding: 8px 12px;
  border: 1px solid lightgray;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  flex: 1;
`;
export default Form;
