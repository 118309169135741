import React, { Fragment, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useMutation } from 'react-query';
import FlexWrap from '@/components/common/FlexWrap';
import {
  formatAccountEngInfo,
  formatAccountInfo,
  formatMedicalRefundInfo,
  formatMedicalServiceAccount,
  formatMedicalServiceInfo,
  formatRefundAddressInfo,
  formatRefundPaymentInfo,
} from '@/constants/medical';
import { comma } from '@/utils/ktp';
import { getServiceType } from '@/utils/medical';
import { downloadSupplyDocument, updateRefundDetail } from '@/api/medical';
import SupplyStatementFile from './SupplyStatementFile';
import { convertComponentToImage } from '@/utils/common';
import downloadIcon from '@assets/common/download.png';
import Icon from '@/components/common/Icon';
import Typography from '@/components/common/Typography';
import checkIconV2 from '@/assets/common/checkbox_v2.png';
import checkedIconV2 from '@/assets/common/checkbox_v2_fill.png';
import RemittanceStatusRadio from './RemittanceStatusRadio';
import MailingStatusRadio from './MailingStatusRadio';
import PaymentWayRadio from './PaymentWayRadio';
import ModifyNeedCheckbox from '@/components/common/Remittance/ModifyNeedCheckbox';

function DetailModalContent({ data, setData, refundIndex, onClose }) {
  const { refundInfo, serviceInfo, customerInfo } = data;
  const [isEdit, setIsEdit] = useState(false);
  const [isModifyNeeded, setIsModifyNeeded] = useState(
    data?.isModifyNeeded || false,
  );
  const [selectedAccountInfo, setSelectedAccountInfo] = useState(
    data?.selectedAccountInfo || [],
  );
  const checkPaymentInfo = customerInfo;
  const isDirectPaymentType = customerInfo.paymentType === 'DIRECT';
  const fileRef = useRef(null);

  const formattedRefundInfo = formatMedicalRefundInfo(
    refundInfo,
    serviceInfo,
    customerInfo,
  ); // 기본정보
  const formattedPaymentInfo = formatRefundPaymentInfo(
    customerInfo,
    refundInfo.nation,
  ); // 고객정보1
  const formattedAddressInfo = formatRefundAddressInfo(customerInfo); // 고객정보2
  const formattedAccountInfo = formatAccountInfo(customerInfo); // 고객정보3
  const formattedAccountEngInfo = formatAccountEngInfo(customerInfo); // 고객정보4
  const formattedServiceAccount = formatMedicalServiceAccount(serviceInfo); // 시술정보1
  const formattedServiceInfo = formatMedicalServiceInfo(serviceInfo); // 시술정보2

  const updateMutation = useMutation(
    ['updateMedicalInfo'],
    (payload) => updateRefundDetail(refundIndex, payload),
    {
      retry: false,
      onSuccess: () => {
        setIsEdit(false);
        alert('한급현황 수정이 완료되었습니다.');
      },
    },
  );

  // 의료공급확인서 다운
  const { data: downloadData, mutate: downloadMutation } = useMutation(
    ['downloadSupplyDocument'],
    (payload) => downloadSupplyDocument(payload),
    {
      retry: false,
      onSuccess: () => {
        setTimeout(() => {
          uploadExcelImage();
        }, 500);
      },
    },
  );

  const uploadExcelImage = async () => {
    const blob = await convertComponentToImage(fileRef.current);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `의료공급확인서_${refundIndex}`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const onClickEdit = () => {
    if (isEdit) {
      if (customerInfo.accountInfo?.nation !== undefined) {
        customerInfo.accountInfo.nation = refundInfo.nation;
      }
      const payload = {
        transferDate: refundInfo.paymentCompleteDate,
        memo: refundInfo.memo,
        mailSendCount: refundInfo.mailSendCount,
        mailSendDate: refundInfo.lastSentDate,
        paymentStatus: refundInfo.paymentStatus,
        needFullPayment: refundInfo.needFullPayment,
        accountInfo: customerInfo.accountInfo
          ? JSON.stringify(customerInfo.accountInfo)
          : null,
        isModifyNeeded,
        selectedAccountInfo,
      };
      updateMutation.mutate(payload);
    } else {
      setIsEdit(true);
    }
  };

  const onChangeMemo = (e) => {
    setData((prev) => {
      return {
        ...prev,
        refundInfo: {
          ...prev.refundInfo,
          memo: e.target.value,
        },
      };
    });
  };

  const onClickDownload = () => {
    const payload = { refundIndex };
    downloadMutation(payload);
  };

  const onChange = (e) => {
    const name = e.target.name;
    setData((prev) => {
      return {
        ...prev,
        customerInfo: {
          ...prev.customerInfo,
          accountInfo: {
            ...prev.customerInfo.accountInfo,
            [name]: e.target.value,
          },
        },
      };
    });
  };

  const selectModifyNeed = () => {
    if (!isEdit) return;
    if (isModifyNeeded) {
      setSelectedAccountInfo([]);
    }
    setIsModifyNeeded(!isModifyNeeded);
  };

  const selectCell = (item) => {
    if (isEdit) {
      if (selectedAccountInfo.includes(item?.option?.name)) {
        const filteredCell = selectedAccountInfo.filter(
          (cell) => cell !== item?.option?.name,
        );
        setSelectedAccountInfo(filteredCell);
      } else {
        setSelectedAccountInfo((prev) => [...prev, item?.option?.name]);
      }
    }
  };

  return (
    <>
      <StyledContainer>
        <FlexWrap
          justifyContent="space-between"
          alignItems="center"
          margin="0 0 32px 0"
        >
          <Title>환급 현황 상세보기</Title>
          <FlexWrap gap="16px">
            <CloseBtn onClick={onClose}>닫기</CloseBtn>
            <EditButton isEdit={isEdit} onClick={onClickEdit}>
              {isEdit ? '수정완료' : '수정하기'}
            </EditButton>
          </FlexWrap>
        </FlexWrap>
        {/* 송금상태, 송금일자 */}
        {isDirectPaymentType ? (
          <FlexWrap alignItems="center" margin="0 0 36px 0">
            <RemittanceStatusRadio
              refundInfo={refundInfo}
              isEdit={isEdit}
              setData={setData}
            />
            <MailingStatusRadio
              refundInfo={refundInfo}
              isEdit={isEdit}
              setData={setData}
            />
          </FlexWrap>
        ) : (
          // {/* 결제방식 (즉시환급만 보여짐 / 키오스크(x)) */}
          <PaymentWayRadio
            refundInfo={refundInfo}
            isEdit={isEdit}
            setData={setData}
          />
        )}
        {data?.isModifyPossible && (
          <ModifyNeedCheckbox
            isModifyNeeded={isModifyNeeded}
            onClick={selectModifyNeed}
          />
        )}
        <MemoInput
          placeholder="메모를 입력하세요. (최대 1000자)"
          disabled={!isEdit}
          onChange={onChangeMemo}
          value={refundInfo.memo}
        />
        {/* 기본정보 */}
        <FlexWrap
          alignItems="center"
          justifyContent="space-between"
          margin="0 0 24px 0"
        >
          <Typography fontSize="18px" lineHeight="150%" fontWeight="700">
            No.{refundIndex}
          </Typography>
          <Download onClick={onClickDownload}>
            <Icon imgUrl={downloadIcon} widht="16px" height="16px" />
            <Typography>의료 공급 내역서</Typography>
          </Download>
        </FlexWrap>
        <Table>
          {formattedRefundInfo.map((item, idx) => (
            <TableContainer key={idx}>
              <TableHead
                isValue={!item.option.value || item.option.value === '-'}
              >
                {item.label ? item.label : ''}
              </TableHead>
              <TableContent>
                {typeof item.option?.value === 'number'
                  ? comma(item.option?.value) + '원'
                  : item.option?.value}
              </TableContent>
            </TableContainer>
          ))}
        </Table>
        {isDirectPaymentType && (
          <Fragment>
            {/* 송금정보1 */}
            <Typography
              fontSize="18px"
              lineHeight="150%"
              fontWeight="700"
              margin="0 0 32px 0"
            >
              송금 정보
            </Typography>
            <Table>
              {formattedPaymentInfo?.map((items, index) => (
                <Fragment key={index}>
                  {items.map((item, idx) =>
                    ['firstNameEng', 'lastNameEng'].includes(
                      item.option.name,
                    ) && !['CHN', 'JPN'].includes(refundInfo.nation) ? (
                      <></>
                    ) : (
                      <TableContainer key={idx}>
                        <TableHead
                          isValue={
                            !item?.option?.value || item?.option?.value === '-'
                          }
                        >
                          {item?.label && data?.isModifyPossible ? (
                            <Checkbox
                              justifyContent="space-between"
                              onClick={() => selectCell(item)}
                            >
                              {item.label}
                              {item?.option?.name !== 'email' && (
                                <Icon
                                  imgUrl={
                                    selectedAccountInfo.includes(
                                      item?.option?.name,
                                    )
                                      ? checkedIconV2
                                      : checkIconV2
                                  }
                                  width="24px"
                                  height="24px"
                                  margin="0 21px 0 0"
                                />
                              )}
                            </Checkbox>
                          ) : (
                            item.label
                          )}
                        </TableHead>
                        <TransferInput
                          name={item?.option?.name}
                          value={item?.option?.value || ''}
                          disabled={
                            item?.option?.name === '' ||
                            item?.option?.name === 'email' ||
                            !isEdit
                          }
                          placeholder="-"
                          onChange={onChange}
                        />
                      </TableContainer>
                    ),
                  )}
                </Fragment>
              ))}
            </Table>
            {/* 송금정보2 */}
            <Table>
              {formattedAddressInfo?.map((items, index) => (
                <Fragment key={index}>
                  {items.map((item, idx) => (
                    <TableContainer key={idx}>
                      <TableHead
                        isValue={
                          !item.option.value || item.option.value === '-'
                        }
                      >
                        {item?.label && data?.isModifyPossible ? (
                          <Checkbox
                            justifyContent="space-between"
                            onClick={() => selectCell(item)}
                          >
                            {item.label}
                            <Icon
                              imgUrl={
                                selectedAccountInfo.includes(item?.option?.name)
                                  ? checkedIconV2
                                  : checkIconV2
                              }
                              width="24px"
                              height="24px"
                              margin="0 21px 0 0"
                            />
                          </Checkbox>
                        ) : (
                          <>
                            <Label> {item.label} </Label>
                            <LabelNation>{item.nationLabel}</LabelNation>
                          </>
                        )}
                      </TableHead>
                      <TransferInput
                        name={item.option.name}
                        value={item.option.value || ''}
                        disabled={item?.option?.name === '' || !isEdit}
                        placeholder="-"
                        onChange={onChange}
                      />
                    </TableContainer>
                  ))}
                </Fragment>
              ))}
            </Table>
            {/* 송금정보3 */}
            <Table>
              {formattedAccountInfo?.map((items, index) => (
                <Fragment key={index}>
                  {items.map((item, idx) => (
                    <TableContainer key={idx}>
                      <TableHead
                        isValue={
                          !item.option.value || item.option.value === '-'
                        }
                      >
                        {item?.label && data?.isModifyPossible ? (
                          <Checkbox
                            justifyContent="space-between"
                            onClick={() => selectCell(item)}
                          >
                            <>
                              <Label> {item.label} </Label>
                              <LabelNation>{item.nationLabel}</LabelNation>
                            </>
                            <Icon
                              imgUrl={
                                selectedAccountInfo.includes(item?.option?.name)
                                  ? checkedIconV2
                                  : checkIconV2
                              }
                              width="24px"
                              height="24px"
                              margin="0 21px 0 5px"
                            />
                          </Checkbox>
                        ) : (
                          <>
                            <Label> {item.label} </Label>
                            <LabelNation>{item.nationLabel}</LabelNation>
                          </>
                        )}
                      </TableHead>
                      <TransferInput
                        name={item.option.name}
                        value={item.option.value || ''}
                        disabled={item?.option?.name === '' || !isEdit}
                        placeholder="-"
                        onChange={onChange}
                      />
                    </TableContainer>
                  ))}
                </Fragment>
              ))}
            </Table>
            {/* 송금정보4 중국인 */}
            {refundInfo.nation === 'CHN' && (
              <Table>
                {formattedAccountEngInfo?.map((items, index) => (
                  <Fragment key={index}>
                    {items.map((item, idx) => (
                      <TableContainer key={idx}>
                        <TableHead
                          isValue={
                            !item.option.value || item.option.value === '-'
                          }
                        >
                          {item?.label && data?.isModifyPossible ? (
                            <Checkbox
                              justifyContent="space-between"
                              onClick={() => selectCell(item)}
                            >
                              <>
                                <Label> {item.label} </Label>
                                <LabelNation>{item.nationLabel}</LabelNation>
                              </>
                              <Icon
                                imgUrl={
                                  selectedAccountInfo.includes(
                                    item?.option?.name,
                                  )
                                    ? checkedIconV2
                                    : checkIconV2
                                }
                                width="24px"
                                height="24px"
                                margin="0 21px 0 5px"
                              />
                            </Checkbox>
                          ) : (
                            <>
                              <Label> {item.label} </Label>
                              <LabelNation>{item.nationLabel}</LabelNation>
                            </>
                          )}
                        </TableHead>
                        <TransferInput
                          name={item.option.name}
                          value={item.option.value || ''}
                          disabled={item?.option?.name === '' || !isEdit}
                          placeholder="-"
                          onChange={onChange}
                        />
                      </TableContainer>
                    ))}
                  </Fragment>
                ))}
              </Table>
            )}
          </Fragment>
        )}
        {/* 시술정보1 */}
        <Typography
          fontSize="18px"
          lineHeight="150%"
          fontWeight="700"
          margin="0 0 32px 0"
        >
          시술정보
        </Typography>
        <Table>
          {formattedServiceAccount?.map((items, index) => (
            <Fragment key={index}>
              {items.map((item, idx) => (
                <TableContainer key={idx}>
                  <TableHead
                    isValue={!item.option.value || item.option.value === '-'}
                  >
                    {item.label}
                  </TableHead>
                  <TransferInput
                    name={item.option.name}
                    value={
                      typeof item.option?.value === 'number'
                        ? comma(item.option?.value) + '원'
                        : item.option?.value
                    }
                    disabled
                    placeholder={item.placeholder}
                  />
                </TableContainer>
              ))}
            </Fragment>
          ))}
        </Table>
        {/* 시술정보2 */}
        <Table dir="column">
          <ServiceInfoWrapper>
            {formattedServiceInfo.map((service, idxS) => (
              <ServiceInfoContainer key={idxS}>
                {service.map((item, idx) => (
                  <TableContainer key={idx} isService>
                    {idxS === 0 && (
                      <TableHead
                        isService
                        isValue={
                          !item.option.value || item.option.value === '-'
                        }
                      >
                        {item.label}
                      </TableHead>
                    )}
                    <TableContent isService>
                      {item.option?.name === 'productType'
                        ? getServiceType(item.option?.value)
                        : comma(item.option?.value) + '원'}
                    </TableContent>
                  </TableContainer>
                ))}
              </ServiceInfoContainer>
            ))}
          </ServiceInfoWrapper>
        </Table>
      </StyledContainer>
      {downloadData && (
        <SupplyStatementFile data={downloadData} fileRef={fileRef} />
      )}
    </>
  );
}
const StyledContainer = styled.div`
  width: 100%;
`;
const Title = styled.h2`
  font-size: 28px;
`;
export const SubTitle = styled.p`
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
`;
const Table = styled(FlexWrap)`
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid #2f2f2f;
  border-right: 1px solid #2f2f2f;
  margin-bottom: 36px;
`;
const TableContainer = styled(FlexWrap)`
  flex-direction: ${(props) => (props.isService ? 'column' : 'row')};
  width: 50%;
  border-bottom: 1px solid #2f2f2f;
  align-items: center;
  background-color: #f5f5f5;
`;
const TableHead = styled(FlexWrap)`
  width: 35%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding-left: 21px;
  min-height: 56px;
  background-color: #e0e0e0;
  border-left: 1px solid #2f2f2f;
  border-right: 1px solid #2f2f2f;
  color: ${(props) => props.isValue && '#757575'};
  ${(props) =>
    props.isService &&
    css`
      width: 100%;
      border-right: none;
      border-bottom: 1px solid #2f2f2f;
    `}
`;
const Label = styled.span``;
const LabelNation = styled.span`
  margin-top: 5px;
  font-size: 12px;
  word-break: break-all;
`;

const TableContent = styled(FlexWrap)`
  padding-left: 21px;
  ${(props) =>
    props.isService &&
    css`
      width: 100%;
      padding: 12px 21px;
      border-left: 1px solid #2f2f2f;
    `}
`;
export const TransferStatus = styled(FlexWrap)`
  position: relative;
  flex: 1;
  align-items: center;
`;
const ServiceInfoWrapper = styled(FlexWrap)`
  width: 100%;
  flex-direction: column;
`;
const ServiceInfoContainer = styled(FlexWrap)`
  width: 100%;
`;
export const TransferContainer = styled(FlexWrap)`
  padding-left: 21px;
  gap: 20px;
  align-items: center;
`;
const TransferInput = styled.input`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  outline: none;
  padding-left: 21px;
  flex: 1;
  border: none;
  background-color: #ffffff;
  min-height: ${(props) => (props.isRadio ? 'auto' : '56px')};
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f2f2f2;
    `}
`;
const EditButton = styled.button`
  background-color: ${(props) => (props.isEdit ? '#757575' : '#354eff')};
  color: #ffffff;
  border-radius: 4px;
  padding: 12px 44px;
  cursor: pointer;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
`;
const Download = styled.button`
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  color: #246cf6;
  cursor: pointer;
`;
const CloseBtn = styled.button`
  color: #3a3b3e;
  background-color: #e5e6e8;
  padding: 12px 44px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
`;
const MemoInput = styled.textarea`
  padding: 24px 20px;
  width: 100%;
  height: 72px;
  overflow: auto;
  margin-bottom: 40px;
  border-radius: 6px;
  outline: none;
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  color: #3a3b3e;
  resize: none;
`;

const Checkbox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  justify-content: ${({ justifyContent }) => justifyContent};
`;
export default DetailModalContent;
