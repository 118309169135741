import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import closeIcon from '@/assets/common/close.png';

interface IProps {
  img: string;
  setIsPreviewModal: Dispatch<SetStateAction<Boolean>>;
}

function HospitalPreviewModal({ img, setIsPreviewModal }: IProps) {
  const onClick = () => {
    setIsPreviewModal(false);
  };
  return (
    <Wrapper>
      <Container>
        <Image src={img} width="100%" height="100%" />
        <Icon src={closeIcon} width="25px" height="25px" onClick={onClick} />
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40vh;
  height: 40vh;
  background: #ffffff;
  padding: 30px;
  transform: translate(-50%, -50%);
  border-radius: 8px;
`;
const Image = styled.img`
  object-fit: contain;
  border-radius: 8px;
`;
const Icon = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
export default HospitalPreviewModal;
