import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, TableBody } from '@material-ui/core';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import PageIndicator from '@components/common/PageIndicator';
import SearchBox from '@components/common/SearchBox';
import store from '@/store';
import { QNA_TABLE_DATA } from '@constants/ktp';
import DataTable from '@components/common/DataTable';
import Title from '@components/common/Title';
import LoadingView from '@/components/common/LoadingView';
import QnaItem from '@/components/qna/QnaItem';
import QnaFilter from '@/components/qna/QnaFilter';
import QnaModal from '@/components/qna/Qna.modal';
import useQnaReducer from '@/hooks/ktp/useQnaReducer';
import { getQnaList, patchQnaRead } from '@/api/ktp';
import { QnaResponse } from '@/types/qna.type';
import useRemittanceReducer from '@/hooks/ktp/useRemittanceReducer';
import DetailModal from '@/components/ktp/refund-status/DetailModal';
import QnaSize from '@/components/qna/QnaSize';

function MedicalQna() {
  const [state, dispatch] = useQnaReducer();
  const [refundState, refundDispatch] = useRemittanceReducer();
  const { selected: refundSelected } = refundState;
  const { toggle, selected } = state;
  const [size, setSize] = useState(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageList, setPageList] = useState<Array<number>>([0]);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [Qnafilter, setQnaFilter] = useState('ALL');
  const { serverState } = store.getState();
  const { columns } = QNA_TABLE_DATA;
  const queryClient = useQueryClient();

  useEffect(() => {
    setTotalPage(0);
  }, [serverState, searchKeyword]);

  useEffect(() => {
    setCurrentPage(0);
  }, [totalPage]);

  const { data, isLoading } = useQuery(
    ['qnaList', Qnafilter, size, currentPage, searchKeyword],
    () => {
      const params = {
        status: Qnafilter,
        page: currentPage,
        size,
        keyword: searchKeyword,
      };
      return getQnaList(params);
    },
    {
      onSuccess: (data: any) => {
        if (currentPage === 0) {
          setTotalPage(data?.totalPages);
          setPageList(
            data?.totalPages < 4
              ? Array.from({ length: data?.totalPages + 1 }, (v, i) => i)
              : [0, 1, 2, 3, 4],
          );
        }
      },
      retry: false,
    },
  ) as any;

  const { mutate: readQna } = useMutation(
    (selected: number) => patchQnaRead(selected),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('qnaList');
      },
    },
  );

  const onQnaModal = {
    open: (selected: number, isNew: boolean) => {
      if (isNew) {
        readQna(selected);
      }
      dispatch({
        type: 'OPEN_MODAL',
        payload: { selected },
      });
    },
    close: () => {
      dispatch({
        type: 'CLOSE_MODAL',
      });
    },
  };

  const onRefundModal = {
    open: (refundIndex: number) => {
      refundDispatch({
        type: 'SET_SELECTED',
        payload: { refundIndex },
      });
    },
    close: () => {
      refundDispatch({ type: 'SET_SELECTED', selected: null });
    },
  };

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <StyledContainer maxWidth="xl">
      <Title>FAQ</Title>
      <SearchBox
        searchKeyword={searchKeyword}
        data={[]}
        setSearchKeyword={setSearchKeyword}
        setCurrentPage={setCurrentPage}
      />
      <FilterWrapper>
        <QnaFilter
          currentTransferView={Qnafilter}
          onClickChangeTransfer={(value) => setQnaFilter(value)}
        />
        <QnaSize size={size} setSize={setSize} />
      </FilterWrapper>
      <DataTable
        columns={columns}
        dispatch={dispatch}
        toggle={toggle}
        contentComponent={
          <TableBody>
            {data?.content.map((question: QnaResponse) => (
              <QnaItem
                key={question.questionId}
                id={question.questionId}
                data={question}
                onClick={() =>
                  onQnaModal.open(question.questionId, question.isNew)
                }
              />
            ))}
          </TableBody>
        }
      />
      {data?.content?.length === 0 && (
        <ContentContainer>문의가 존재하지 않습니다.</ContentContainer>
      )}
      <PageIndicator
        totalPage={totalPage}
        pageList={pageList}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setPageList={setPageList}
      />
      {selected && (
        <QnaModal
          qnaId={selected}
          onClose={onQnaModal.close}
          onOpenRefundModal={onRefundModal.open}
        />
      )}
      {refundSelected?.refundIndex && (
        <DetailModal onClose={onRefundModal.close} selected={refundSelected} />
      )}
    </StyledContainer>
  );
}

export default MedicalQna;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  color: gray;
  font-size: 16px;
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 56px;
`;
