import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';

function Redirect() {
  const location = useLocation();
  const isLogin = localStorage.getItem('loginToken');

  const fetchManifest = async () => {
    const response = await axios.get(
      'https://s3.ap-northeast-2.amazonaws.com/admin.successmode.co.kr/manifest.json',
    );
    return response.data;
  };

  useQuery(['manifest', location], fetchManifest, {
    onSuccess: (res) => {
      const currentVersion = localStorage.getItem('webVersion');
      const newVersion = res.web_version;

      if (currentVersion !== newVersion) {
        localStorage.setItem('webVersion', newVersion);
        window.location.reload();
      }
    },
    staleTime: 0,
  });

  return null;
}

export default Redirect;
