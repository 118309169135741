import React from 'react';
import styled from 'styled-components';
import { Container, Divider } from '@material-ui/core';
import CertificationDoughnut from '../../../components/etc/noonch/CertificationDoughnut';
import AgeBar from '../../../components/etc/noonch/AgeBar';
import EtcBar from '../../../components/etc/noonch/EtcBar';
import GenderDoughnut from '../../../components/etc/noonch/GenderDoughnut';
import LoadingView from '../../../components/common/LoadingView';
import { useQuery } from 'react-query';
import { getStatistics } from '../../../api/successmode';

const Statistics = () => {
  const { data, isLoading } = useQuery('statisticsData', getStatistics, {
    retry: false,
  });

  if (isLoading) {
    return <LoadingView />;
  }

  if (!data) {
    return null;
  }
  return (
    <StyledContainer>
      <ChartContainer>
        <ChartContainerItem>
          <AgeBar statistics={data} />
        </ChartContainerItem>
        <ChartContainerItem>
          <EtcBar statistics={data} />
        </ChartContainerItem>
      </ChartContainer>
      <StyledDivider />
      <ChartContainer>
        <ChartContainerItem>
          <CertificationDoughnut statistics={data} />
        </ChartContainerItem>
        <ChartContainerItem>
          <GenderDoughnut statistics={data} />
        </ChartContainerItem>
      </ChartContainer>
    </StyledContainer>
  );
};
const ChartContainer = styled.div`
  display: flex;
  margin-top: 3%;
  margin-bottom: 5%;
  align-items: center;
  justify-content: center;
`;

const ChartContainerItem = styled.div`
  width: 100%;
  margin-right: 10%;
`;

const StyledContainer = styled(Container)`
  margin: 0;
`;

const StyledDivider = styled(Divider)`
  border: 1px solid;
`;

export default Statistics;
