import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import ActionBtn from '@/components/common/ActionBtn';
import Form from '@/components/medical/notice/Form';
import FormItemLayout from '@/components/medical/notice/FormItemLayout';
import QuillEditor from '@/components/medical/notice/QuillEditor';
import Modal from '@/components/common/Modal';
import FlexWrap from '@/components/common/FlexWrap';
import { ICreateNoticeBody } from '@/types/api/medical';
import useNoticeDetail from '@/hooks/medical/useNoticeDetail';

interface IProps {
  data: any;
  onCloseModal: () => void;
}
function DetailModal({ data, onCloseModal }: IProps) {
  const quillRef = useRef(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [state, setState] = useState<ICreateNoticeBody>({
    fixed: false,
    immediate: true,
    scheduledDate: '',
    visible: false,
    title: '',
    writer: '',
    content: '',
  });

  const { isFetching, noticeData, onUpdateNotice, onDeleteNotice } =
    useNoticeDetail({
      onCloseModal,
      noticeIndex: data?.noticeIndex,
    });
  const setHtmlContent = (value: string) => {
    setState((prev) => ({ ...prev, content: value }));
  };
  useEffect(() => {
    if (!noticeData) {
      return;
    }
    setState(noticeData);
  }, [noticeData]);

  const onEdit = () => {
    if (isEditMode) {
      if (!state.immediate && !state.scheduledDate) {
        alert('예약 발송 날짜를 입력해주세요.');
        return;
      }
      if (!state.title || !state.content || !state.writer) {
        alert('필수 입력 항목을 채워주세요.');
        return;
      }
      onUpdateNotice({ noticeIndex: data?.noticeIndex, body: state });
    } else {
      setIsEditMode((prev) => !prev);
    }
  };

  const onChangeInput: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const onChangeChecked: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void = (e) => {
    setState({ ...state, [e.target.name]: e.target.checked });
  };
  const onChangeRadio: (
    event: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => void = (e) => {
    setState({
      ...state,
      immediate: e.target.name === 'immediate' ? true : false,
      ...(e.target.name === 'immediate' && {
        scheduledDate: new Date().toISOString().slice(0, 16),
      }),
    });
  };
  const onUpdateVisible = () => {
    onUpdateNotice({
      noticeIndex: data?.noticeIndex,
      body: { ...state, visible: !state.visible },
    });
  };
  return (
    <Modal onClose={onCloseModal} isLoading={isFetching}>
      <Wrapper>
        <Title>공지사항 상세보기</Title>
        <Form
          state={state}
          onChangeChecked={onChangeChecked}
          onChangeInput={onChangeInput}
          onChangeRadio={onChangeRadio}
          isEditMode={isEditMode}
        />
        <QuillEditor
          htmlContent={state.content}
          setHtmlContent={setHtmlContent}
          isEditMode={isEditMode}
          quillRef={quillRef}
        />
        <FormItemLayout
          label="작성자"
          required
          style={{ justifyContent: 'flex-end', marginTop: 20 }}
          labelWidth={80}
        >
          <StyledInput
            name="writer"
            defaultValue={state.writer}
            onChange={onChangeInput}
            placeholder="작성자 입력"
            disabled={!isEditMode}
          />
        </FormItemLayout>
        <BtnContainer gap="10px">
          {!isEditMode && (
            <>
              <ActionBtn
                title={state.visible ? '공지사항 내리기' : '공지사항 올리기'}
                onClick={onUpdateVisible}
                size="big"
                bgColor="#757575"
              />
              <ActionBtn
                title="삭제"
                onClick={onDeleteNotice}
                size="big"
                bgColor="#ffffff"
                color="#E42939"
                outlineColor="#E42939"
              />
            </>
          )}
          <ActionBtn
            title={isEditMode ? '수정완료' : '수정하기'}
            onClick={onEdit}
            size="big"
          />
        </BtnContainer>
      </Wrapper>
    </Modal>
  );
}
const Title = styled.h2`
  font-size: 28px;
  margin-bottom: 40px;
`;
const Wrapper = styled.div`
  width: 100%;
`;
const BtnContainer = styled(FlexWrap)`
  justify-content: flex-end;
  margin-top: 20px;
`;
const StyledInput = styled.input`
  padding: 8px 12px;
  border: 1px solid lightgray;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
`;
export default DetailModal;
