import styled from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';
import Typography from '@/components/common/Typography';
import {
  BillType,
  KioskDetailDepositInfo,
  KioskDetailRemainingInfo,
  KioskLackCriteriaType,
  KioskTrendInfo,
} from '@/types/api/kiosk';
import Cash from './Cash';
import CashBalanceList from './CashBalanceList';
import store from '@/store';
import CashSetting from './CashSetting';

type CashBalanceProps = {
  kioskIndex: number;
  remainingInfo: KioskDetailRemainingInfo;
  depositInfo: KioskDetailDepositInfo[];
  billType: BillType;
  isCharging?: boolean;
  trendInfo?: KioskTrendInfo;
  kioskLackCriteria?: KioskLackCriteriaType;
  onClick?: (value1: string, value2: string) => void;
};

function CashBalance({
  billType,
  kioskIndex,
  remainingInfo,
  depositInfo,
  trendInfo,
  onClick,
  isCharging,
  kioskLackCriteria,
}: CashBalanceProps) {
  const serverState = store.getState().serverState;

  return (
    <Container>
      {isCharging && (
        <CashSetting
          kioskIndex={kioskIndex}
          prevBillType={billType}
          kioskLackCriteria={kioskLackCriteria}
        />
      )}
      <FlexWrap alignItems='center' justifyContent='space-between'>
        <Typography fontSize='18px' fontWeight='600' lineHeight='26px'>
          현금 잔량
        </Typography>
        {!isCharging && (
          <MoreBtn
            onClick={() =>
              onClick &&
              onClick(
                '현금 관리 내역',
                `/kiosk/${serverState}/home/charging-detail`,
              )
            }
          >
            + 더보기
          </MoreBtn>
        )}
      </FlexWrap>
      <Cash
        remainingInfo={remainingInfo}
        billType={billType}
        trendInfo={trendInfo}
      />
      {depositInfo.length < 1 ? (
        <Typography>데이터가 없습니다.</Typography>
      ) : (
        <CashBalanceList depositInfo={depositInfo} billType={billType} />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const MoreBtn = styled.button`
  color: #757575;
`;

export default CashBalance;
