import { useState } from 'react';
import styled from 'styled-components';
import { TableRow } from '@material-ui/core';

import CustomTableCell from '@/components/common/CustomTableCell';
import Typography from '@/components/common/Typography';
import WithdrawalDetailModal from '../WithdrawalDetailModal';
import { BillType, KioskDetailWithdrawalInfo } from '@/types/api/kiosk';
import { comma } from '@/utils/kiosk';

type WithdrawalInfoItemProps = {
  data: KioskDetailWithdrawalInfo;
  idx: number;
  isDetail?: boolean;
  billType: BillType;
};

function WithdrawalInfoItem({
  data,
  idx,
  isDetail = false,
  billType,
}: WithdrawalInfoItemProps) {
  const [isModal, setIsModal] = useState(false);
  const {
    id,
    refundIds,
    createdDate,
    refund,
    bd1Count,
    bd1TotalCount,
    bd2Count,
    bd2TotalCount,
    bd3Count,
    bd3TotalCount,
    hp1Count,
    hp1TotalCount,
  } = data;
  const { bd1, bd2, bd3, hp1 } = billType;
  const onClickOpenModal = () => {
    setIsModal(true);
  };

  const onCloseModal = () => {
    setIsModal(false);
  };

  return (
    <>
      <StyledTableRow idx={idx} isDetail={isDetail} onClick={onClickOpenModal}>
        <CustomTableCell idx={0} width="60" fontSize="14px" fontWeight={500}>
          {refundIds &&
            (refundIds.length > 1
              ? `${refundIds[0]} 외${refundIds?.length - 1}`
              : refundIds[0])}
        </CustomTableCell>
        <CustomTableCell width="120" fontSize="14px" fontWeight={500}>
          {createdDate}
        </CustomTableCell>
        <CustomTableCell width="100" fontSize="14px" fontWeight={500}>
          {comma(refund) + '원'}
        </CustomTableCell>
        <CustomTableCell
          width="100"
          fontSize="14px"
          fontWeight={500}
          color={bd1 ? '#80848A' : '#CBCCCE'}
        >
          {bd1Count > 0 && '-'}
          {bd1Count}
          <Typography> {` (${bd1TotalCount})`}</Typography>
        </CustomTableCell>
        <CustomTableCell
          width="100"
          fontSize="14px"
          fontWeight={500}
          color={bd2 ? '#80848A' : '#CBCCCE'}
        >
          {bd2Count > 0 && '-'}
          {bd2Count}
          <Typography> {` (${bd2TotalCount})`}</Typography>
        </CustomTableCell>
        <CustomTableCell
          width="100"
          fontSize="14px"
          fontWeight={500}
          color={bd3 ? '#80848A' : '#CBCCCE'}
        >
          {bd3Count > 0 && '-'}
          {bd3Count}
          <Typography> {` (${bd3TotalCount})`}</Typography>
        </CustomTableCell>
        <CustomTableCell
          width="100"
          fontSize="14px"
          fontWeight={500}
          color={hp1 ? '#80848A' : '#CBCCCE'}
        >
          {hp1Count > 0 && '-'}
          {hp1Count}
          <Typography>{` (${hp1TotalCount})`}</Typography>
        </CustomTableCell>
      </StyledTableRow>
      {/* 환급현황 상세보기 */}
      {isModal && (
        <WithdrawalDetailModal onClose={onCloseModal} refundIndex={id} />
      )}
    </>
  );
}

const StyledTableRow = styled(TableRow)<{ idx: number; isDetail: boolean }>`
  background-color: #ffffff;
  border-bottom: ${(props) =>
    (props.idx + 1) % 5 === 0
      ? '2px solid rgb(220, 220, 220)'
      : '1px solid rgb(220, 220, 220)'};
  :hover {
    background-color: #f7f9fc;
  }
  cursor: ${(props) => props.isDetail && 'pointer'};
  span {
    font-weight: 500;
  }
`;

export default WithdrawalInfoItem;
