import React from 'react';
import ActionBtn from '@/components/common/ActionBtn';
import FlexWrap from '@/components/common/FlexWrap';

const SUCCESS_HOME = 'https://successmode.co.kr/';
const MEETING_ROOM =
  'https://w2.applyto.kr/register/mylist_MyQr.asp?ukey=_9DE2384&School_id=537289&inning=&Z19_SN=&part_id=&student_id=&A50_ID=&number_id=&gate_id=';

const SuccessHome = () => {
  const handleClickButton = (path) => {
    window.open(path, '_blank');
  };
  return (
    <FlexWrap gap="8px">
      <ActionBtn
        title="회사 홈페이지"
        outlineColor="#354EFF"
        bgColor="#ffffff"
        color="#354EFF"
        size="big"
        onClick={() => handleClickButton(SUCCESS_HOME)}
      />
      <ActionBtn
        title="회의실 예약"
        outlineColor="#354EFF"
        bgColor="#ffffff"
        color="#354EFF"
        size="big"
        onClick={() => handleClickButton(MEETING_ROOM)}
      />
    </FlexWrap>
  );
};

export default SuccessHome;
