import { format } from 'date-fns';
import { useReducer } from 'react';

const initialState = {
  toggle: null,
  dateFilter: 'ALL',
  filter: {
    refundStatus: '',
    departureStatus: '',
    paymentStatus: '',
    departureDateInputStatus: '',
    paymentType: null,
    startDate: '2022-01-01',
    endDate: format(new Date(), 'yyyy-MM-dd'),
  },
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'SET_SELECTED':
      return {
        ...state,
        selected: action.payload,
      };
    case 'SET_FILTER_DATE':
      return {
        ...state,
        filter: {
          ...state.filter,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    case 'SET_DATEFILTER':
      return {
        ...state,
        dateFilter: action.payload,
      };
    case 'SET_TOGGLE':
      return {
        ...state,
        toggle: action.payload,
      };
    case 'SET_FILTER':
      return {
        ...state,
        filter: { ...state.filter, [action.payload.key]: action.payload.value },
      };
    case 'SET_PAYMENT_TYPE':
      return {
        ...state,
        filter: {
          ...state.filter,
          paymentType: action.payload,
        },
      };
    default:
      throw Error(`Unhandled Type Error: ${action.type}`);
  }
}

const useRefundReducer = () => {
  return useReducer(reducer, initialState);
};

export default useRefundReducer;
