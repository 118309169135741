import { useState } from 'react';
import { TableBody } from '@material-ui/core';
import { useQuery } from 'react-query';

import { getKioskStatus } from '@/api/kiosk';
import DataTable from '@/components/common/DataTable';
import PageIndicator from '@/components/common/PageIndicator';
import SearchBox from '@/components/common/SearchBox';
import { kioskStatusData } from '@/constants/kiosk';
import useKioskReducer from '@/hooks/kiosk/useKioskReducer';
import {
  GetKioskStatusPayload,
  GetKioskStatusResponse,
} from '@/types/api/kiosk';
import KioskList from './KioskList';
import KioskTitle from './KioskTitle';
import { getKeywords } from '@/api/kiosk';
import ViewDateSelector from '@/components/common/ViewDateSelector';
import LoadingView from '@/components/common/LoadingView';
import KioskFilter from '../kioskFilter/KioskFilter';
import { KioskActiveTypes } from '@/types/kiosk.type';
import styled from 'styled-components';

function KioskStatusList() {
  const [keyword, setKeyword] = useState<string>('');
  const [size, setSize] = useState<number>(100);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageList, setPageList] = useState<Array<number>>([0]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [kioskActiveTypeFilter, setKioskActiveTypeFilter] = useState<
    KioskActiveTypes[]
  >(['ACTIVATED']);
  const { columns } = kioskStatusData;

  const [dispatch] = useKioskReducer();

  // 키워드 검색
  const { data: keywords } = useQuery('keywords', getKeywords, {
    retry: false,
  });

  const { data: kioskData, isLoading } = useQuery<
    GetKioskStatusResponse,
    GetKioskStatusPayload
  >(
    ['kioskStatus', currentPage, keyword, size, kioskActiveTypeFilter],
    () =>
      getKioskStatus({
        page: currentPage,
        size: size,
        keyword,
        view:
          kioskActiveTypeFilter.length === 2 ? 'ALL' : kioskActiveTypeFilter[0],
      }),
    {
      onSuccess: (data) => {
        if (currentPage === 0) {
          setTotalPage(data.totalPages);
          setPageList(
            data.totalPages < 4
              ? Array.from({ length: data.totalPages + 1 }, (v, i) => i)
              : [0, 1, 2, 3, 4],
          );
        }
      },
      retry: false,
    },
  );
  const onChange = (size: number) => {
    setSize(size);
  };

  return (
    <>
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          <KioskTitle title='홈' />
          {keywords && (
            <SearchBox
              searchKeyword={keyword}
              data={keywords}
              setSearchKeyword={setKeyword}
              setCurrentPage={setCurrentPage}
            />
          )}
          <Wrapper>
            <KioskFilter
              kioskActiveTypes={kioskActiveTypeFilter}
              setKioskActiveType={setKioskActiveTypeFilter}
            />
            <ViewDateSelector size={size} onChangeView={onChange} />
          </Wrapper>
          <DataTable
            columns={columns}
            dispatch={dispatch}
            contentComponent={
              <TableBody>
                {kioskData?.content.map((item, idx) => (
                  <KioskList
                    key={idx}
                    idx={idx}
                    data={item}
                    size={kioskData.size}
                    number={kioskData.number}
                    totalElements={kioskData.totalElements}
                  />
                ))}
              </TableBody>
            }
          />
          {pageList && (
            <PageIndicator
              totalPage={totalPage}
              pageList={pageList}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setPageList={setPageList}
            />
          )}
        </>
      )}
    </>
  );
}

export default KioskStatusList;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
