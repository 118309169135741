import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { Container } from '@material-ui/core';

import { getKeywords, getRefundList } from '@/api/medical';
import PageIndicator from '@components/common/PageIndicator';
import RefundStatusList from '@components/medical/refundStatus/RefundStatusList';
import Title from '@components/common/Title';
import { refundStatusData } from '@constants/medical';
import DataTable from '@components/common/DataTable';
import LoadingView from '@/components/common/LoadingView';
import HospitalDateSelector from '@/components/medical/common/HospitalDateSelector';
import useRefundReducer from '@hooks/medical/useRefundReducer';
import DetailModal from '@/components/medical/refundStatus/DetailModal';
import ViewDateSelector from '@/components/common/ViewDateSelector';
import FlexWrap from '@/components/common/FlexWrap';
import SearchBox from '@/components/common/SearchBox';
import Typography from '@/components/common/Typography';
import CheckboxForm from '@/components/common/CheckboxForm';
import { format } from 'date-fns';
import CancelModal from '@/components/ktp/refund-status/CancelModal';

function HospitalRefundStatus() {
  const [state, dispatch] = useRefundReducer();
  const { toggle, dateFilter, filter, selected } = state;
  const { startDate, endDate } = filter;

  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState<string>('');
  const [pageList, setPageList] = useState<any>();
  const [size, setSize] = useState(10);
  const [cancelModalInfo, setCancelModalInfo] = useState({
    isModal: false,
    info: {
      refundIndex: 0,
      storeName: '',
      customerName: '',
      customerNational: '',
      totalRefund: 0,
      totalAmount: 0,
      kioskRefund: false,
      paymentStatus: '',
      isKioskWithdrawn: false,
    },
  });

  const {
    columns,
    refundStatusRadio,
    departureStatusRadio,
    paymentStatusRadio,
    departureDateInputStatusRadio,
  } = refundStatusData;

  const { isLoading, data } = useQuery(
    ['refundList', filter, page, keyword, size],
    () => getRefundList(filter, page, keyword, size),
    {
      onSuccess: (data) => {
        if (page === 0) {
          setTotalPage(data.totalPages);
          setPageList(
            data.totalPages < 4
              ? Array.from({ length: data.totalPages + 1 }, (v, i) => i)
              : [0, 1, 2, 3, 4],
          );
        }
      },
      retry: false,
    },
  );

  // 필터 토글 닫기
  const closeFilterToggle = () => {
    dispatch({ type: 'SET_TOGGLE', payload: null });
  };

  // 필터값 적용
  const onFilterCheckBox = useCallback(
    (e, key) => {
      closeFilterToggle();
      dispatch({
        type: 'SET_FILTER',
        payload: {
          key,
          value: e.target.value,
        },
      });
      setPage(0);
      setPageList([0, 1, 2, 3, 4]);
    },
    [dispatch],
  );

  // 출국예정일 클릭 / 건별 한도조회
  const onOpenModal = (e: any, payload: any) => {
    const {
      isRefundStatusCancel,
      isAfterRefundType,
      departure,
      isDeparture,
      nation,
    } = payload;
    const target = e.target;
    const isRefundOpenModal =
      target.className.includes('scheduledDeparture') &&
      !departure &&
      isAfterRefundType &&
      !isRefundStatusCancel &&
      !isDeparture &&
      nation !== 'KOR';
    if (isRefundOpenModal) return;

    // 환급상세보기
    dispatch({
      type: 'SET_SELECTED',
      payload,
    });
  };

  // 환급상세보기 닫기
  const onCloseModal = () => {
    dispatch({ type: 'SET_SELECTED', selected: null });
  };

  useEffect(() => {
    setPage(0);
  }, [dateFilter]);

  // 키워드 검색
  const { data: keywords } = useQuery('keywords', getKeywords, {
    retry: false,
  });

  useEffect(() => {
    const todayDate = format(new Date(), 'yyyy-MM-dd');
    const currDate = '20' + endDate.replace(/\//g, '-');
    if (currDate !== todayDate) {
      dispatch({
        type: 'SET_FILTER_DATE',
        payload: {
          startDate,
          endDate: format(new Date(), 'yyyy-MM-dd'),
        },
      });
    }
  }, []);

  if (isLoading) {
    return <LoadingView />;
  }
  const onChangeView = (value: number) => {
    setSize(value);
  };

  return (
    <StyledContainer maxWidth='xl'>
      <Title>환급 현황</Title>
      {keywords && (
        <SearchBox
          searchKeyword={keyword}
          data={keywords}
          setSearchKeyword={setKeyword}
          setCurrentPage={setPage}
        />
      )}
      <FlexWrap
        gap='12px'
        alignItems='center'
        justifyContent='flex-end'
        margin='0 0 22px 0 '
      >
        <FlexWrap alignItems='center' margin='0 20px 0 0'>
          <Checkbox
            type='checkbox'
            checked={filter.paymentType}
            onChange={() => {
              dispatch({ type: 'SET_TOGGLE', payload: null });
              dispatch({
                type: 'SET_PAYMENT_TYPE',
                payload: filter.paymentType === 'DIRECT' ? null : 'DIRECT',
              });
              setPage(0);
              setPageList([0, 1, 2, 3, 4]);
            }}
          />
          <Typography fontWeight='500'>전액 결제건만 보기</Typography>
        </FlexWrap>
        <ViewDateSelector isMedical size={size} onChangeView={onChangeView} />
        <HospitalDateSelector
          top='120px'
          right='50px'
          dateFilter={dateFilter}
          dispatch={dispatch}
          position='static'
        />
      </FlexWrap>
      <DataTable
        toggle={toggle}
        columns={columns}
        dispatch={dispatch}
        contentComponent={
          data ? (
            <RefundStatusList
              refunds={data.content || []}
              onOpenModal={onOpenModal}
              setCancelModalInfo={setCancelModalInfo}
            />
          ) : (
            <p>환급 현황이 없습니다.</p>
          )
        }
      />
      {toggle && (
        <CheckboxForm
          valueArray={
            toggle === 'refundStatus'
              ? refundStatusRadio
              : toggle === 'departureStatus'
              ? departureStatusRadio
              : toggle === 'paymentStatus'
              ? paymentStatusRadio
              : departureDateInputStatusRadio
          }
          defaultValue={filter[toggle]}
          type={toggle}
          onFilter={(e) => onFilterCheckBox(e, toggle)}
          style={
            toggle === 'refundStatus'
              ? {
                  top: 330,
                  right: 350,
                }
              : toggle === 'departureStatus'
              ? { top: 330, right: 290 }
              : toggle === 'paymentStatus'
              ? { top: 330, right: 150 }
              : { top: 330, right: 100 }
          }
        />
      )}
      {pageList && (
        <PageIndicator
          totalPage={totalPage}
          pageList={pageList}
          currentPage={page}
          setCurrentPage={setPage}
          setPageList={setPageList}
        />
      )}
      {selected?.refundIndex && (
        <DetailModal onClose={onCloseModal} selected={selected} />
      )}
      {cancelModalInfo.isModal && (
        <CancelModal
          onClose={() => {
            setCancelModalInfo((prev) => ({ ...prev, isModal: false }));
          }}
          selected={cancelModalInfo.info}
          isMedical
        />
      )}
    </StyledContainer>
  );
}
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
`;
const Checkbox = styled.input`
  width: 24px;
  height: 24px;
  margin: 0;
  margin-right: 6px;
`;
const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
`;
export default HospitalRefundStatus;
