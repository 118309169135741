import { useState } from 'react';
import { Container, TableBody } from '@material-ui/core';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import {
  getKioskFranchiseeKeywords,
  getKioskFranchiseeStatus,
} from '@/api/kiosk';
import useKioskReducer from '@/hooks/kiosk/useKioskReducer';
import {
  GetKioskFranchiseeStatusResponse,
  GetKioskStatusPayload,
} from '@/types/api/kiosk';

import DataTable from '@/components/common/DataTable';
import PageIndicator from '@/components/common/PageIndicator';
import SearchBox from '@/components/common/SearchBox';
import { kioskFranchiseeStatusData } from '@/constants/kiosk';
import ViewDateSelector from '@/components/common/ViewDateSelector';
import LoadingView from '@/components/common/LoadingView';
import KioskTitle from '@/components/kiosk/kioskStatus/KioskTitle';
import KioskFranchiseeList from '@/components/kiosk/kioskStatus/KioskFranchiseeList';

function KtpKioskStatus() {
  const [keyword, setKeyword] = useState<string>('');
  const [size, setSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageList, setPageList] = useState<Array<number>>([0]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const { columns } = kioskFranchiseeStatusData;

  const [dispatch] = useKioskReducer();

  // 키워드 검색
  const { data: keywords } = useQuery(
    'kioskFranchiseeKeywords',
    getKioskFranchiseeKeywords,
    {
      retry: false,
    },
  );

  const { data: ktpKioskData, isLoading } = useQuery<
    GetKioskFranchiseeStatusResponse,
    GetKioskStatusPayload
  >(
    ['kioskFranchiseeStatus', currentPage, keyword, size],
    () =>
      getKioskFranchiseeStatus({
        page: currentPage,
        size: size,
        keyword,
      }),
    {
      onSuccess: (data) => {
        if (currentPage === 0) {
          setTotalPage(data.totalPages);
          setPageList(
            data.totalPages < 4
              ? Array.from({ length: data.totalPages }, (v, i) => i)
              : [0, 1, 2, 3, 4],
          );
        }
      },
      retry: false,
    },
  );
  const onChange = (size: number) => {
    setSize(size);
  };

  return (
    <StyledContainer maxWidth="xl">
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          <KioskTitle title="가맹점 현황" />
          {keywords && (
            <SearchBox
              searchKeyword={keyword}
              data={keywords}
              setSearchKeyword={setKeyword}
              setCurrentPage={setCurrentPage}
            />
          )}
          <ViewDateSelector size={size} onChangeView={onChange} />
          <DataTable
            columns={columns}
            dispatch={dispatch}
            contentComponent={
              <TableBody>
                {ktpKioskData?.content.map((item, idx) => (
                  <KioskFranchiseeList
                    key={idx}
                    idx={idx}
                    data={item}
                    size={ktpKioskData.size}
                    number={ktpKioskData.number}
                    totalElements={ktpKioskData.totalElements}
                  />
                ))}
              </TableBody>
            }
          />
          {pageList && (
            <PageIndicator
              totalPage={totalPage}
              pageList={pageList}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setPageList={setPageList}
            />
          )}
        </>
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  padding-top: 16px;
`;

export default KtpKioskStatus;
