import React, { useState } from 'react';
import { useMutation } from 'react-query';
import styled, { css } from 'styled-components';

import { applyFranchisee } from '@/api/medical';
import { applicationTableData } from '@/constants/medical';
import ActionBtn from '@/components/common/ActionBtn';

function ApplicationContent({ hospitalIndex, onClose }) {
  const [inputData, setInputData] = useState({
    accountNumber: null,
    taxFreeNumber: null,
    bankName: null,
    withdrawalDate: null,
    expiryDate: null,
  });
  const { accountNumber, bankName, taxFreeNumber, withdrawalDate, expiryDate } =
    inputData;
  const mutation = useMutation(
    (payload) => applyFranchisee(payload, hospitalIndex),
    {
      onSuccess: () => {
        alert('가맹점 신청이 완료되었습니다.');
        onClose();
      },
      onError: (err) => {
        console.log(err);
      },
      retry: false,
    },
  );
  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const onChangeInputTaxRefund = (e) => {
    console.log('e', e.target);
    const { name, value } = e.target;
  };
  const onSubmit = () => {
    if (!hospitalIndex) {
      alert('계정 생성을 먼저 해주세요.');
      return;
    }
    if (!taxFreeNumber) {
      alert('특례적용의료기관 지정번호를 입력해주세요.');
      return;
    }
    if (!accountNumber) {
      alert('계좌번호를 입력해주세요.');
      return;
    }
    if (bankName === '은행 선택') {
      alert('은행명을 선택해주세요.');
      return;
    }
    if (withdrawalDate === '출금일 선택') {
      alert('출금일을 선택해주세요.');
      return;
    }
    mutation.mutate({
      accountNumber,
      bankName,
      withdrawalDate,
      taxFreeNumber,
      expiryDate,
    });
  };
  return (
    <TableContainer>
      <FlexTitle>
        <Title>가맹점 신청</Title>
        <small>( 회원 계정 생성 후 바로 가맹점 신청을 해주세요.)</small>
      </FlexTitle>
      <StyledTable width="100%" align="center">
        {applicationTableData.map((items) => (
          <StyledTr>
            {items.map((item) => (
              <>
                <StyledTh>{item.label}</StyledTh>
                <StyledTd>
                  {!['은행명', '자동이체 출금일'].includes(item.label) ? (
                    <StyledInput
                      {...item.option}
                      value={inputData[item.option.name]}
                      onChange={onChangeInput}
                    />
                  ) : item?.option?.name === 'taxFreeNumber' ? (
                    <StyledSelect
                      name={item.selectName}
                      onChange={onChangeInput}
                    >
                      {item.optionList?.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </StyledSelect>
                  ) : (
                    <StyledSelect
                      name={item.selectName}
                      onChange={onChangeInput}
                    >
                      {item.optionList?.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </StyledSelect>
                  )}
                </StyledTd>
              </>
            ))}
          </StyledTr>
        ))}
      </StyledTable>
      <ActionBtn title="신청하기" onClick={onSubmit} bottom="-45px" right="0" />
    </TableContainer>
  );
}

const TableContainer = styled.div`
  position: relative;
`;
const Title = styled.h3`
  margin: 0;
`;
const FlexTitle = styled.div`
  display: flex;
  align-items: end;
  margin: 20px 0;
  gap: 20px;
`;
const borderStyles = css`
  border-collapse: collapse;
  border: 1px solid black;
`;
const StyledTable = styled.table`
  ${borderStyles}
  margin: 0 0 40px;
`;
const StyledTd = styled.td`
  ${borderStyles}
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  min-width: 120px;
  width: 350px;
`;
const StyledTh = styled.td`
  ${borderStyles}
  padding-left: 16px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  background-color: #d3d3d3;
  width: 190px;
`;

const StyledTr = styled.tr`
  ${borderStyles}
  padding: 8px 15px;
  font-size: 14px;
`;
const StyledInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  padding: 8px 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const StyledSelect = styled.select`
  padding: 8px;
  border: none;
  outline: none;
`;
export default ApplicationContent;
