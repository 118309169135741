import React from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import styled from 'styled-components';

const HostAcceptDialogContent = ({ title, subTitle, contents }) => {
  return (
    <>
      <DialogContentTitle>[ {title} ]</DialogContentTitle>
      {subTitle && <DialogContentSubTitle>{subTitle}</DialogContentSubTitle>}
      <StyledDialogContentText>{contents}</StyledDialogContentText>
    </>
  );
};

const DialogContentTitle = styled.p`
  color: lightslategray;
  font-size: small;
  font-weight: bold;
  margin-bottom: 1%;
`;

const DialogContentSubTitle = styled.p`
  color: black;
  font-size: smaller;
  font-weight: bolder;
  margin-bottom: 0.5%;
`;

const StyledDialogContentText = styled(DialogContentText)`
  font-size: smaller;
  margin-bottom: 7%;
`;

export default HostAcceptDialogContent;
