import React, { useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import styled from 'styled-components';
import HostAcceptDialog from './HostAcceptDialog';

const HostApplicantListItem = ({ host }) => {
  const {
    id,
    name,
    gender,
    phoneNumber,
    email,
    job,
    profileImagePathList,
    rejectReason,
  } = host;

  const [open, setOpen] = useState(false);
  const [userIndex, setUserIndex] = useState(-1);

  const handleClickOpen = () => {
    setUserIndex(id);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  return (
    <>
      <TableRowWithStyle onClick={handleClickOpen}>
        <TableCell component={'th'}>{id}</TableCell>
        <TableCell>
          <ProfileImage src={profileImagePathList[0]} />
        </TableCell>
        <TableCell>
          {name}({gender === 'male' ? '남' : '여'})
        </TableCell>
        <TableCell>{phoneNumber}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>{job}</TableCell>
        <TableCell>{rejectReason}</TableCell>
      </TableRowWithStyle>
      <HostAcceptDialog open={open} close={handleClose} info={host} />
    </>
  );
};

const TableRowWithStyle = styled(TableRow)`
  &:hover {
    cursor: pointer;
    background-color: gainsboro;
  }
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 70%;
  overflow: hidden;
  object-fit: cover;
`;

export default HostApplicantListItem;
