import React, { forwardRef, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  label: string;
  children: ReactNode;
  required?: boolean;
  error?: boolean;
};

const Field = forwardRef<HTMLDivElement, Props>(
  ({ label, children, required = false, error = false }, ref) => {
    return (
      <FieldContainer ref={ref} error={error}>
        <Label required={required}>{label}</Label>
        {children}
      </FieldContainer>
    );
  },
);

const FieldContainer = styled.div<{ error: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  height: 57px;
  padding: 16px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ error = false }) => (error ? 'red' : '#e5e6e8')};
  background: #ffffff;
`;

const Label = styled.label<{ required?: boolean }>`
  min-width: 50px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #5f6165;

  ${({ required = false }) =>
    required &&
    `&::after {
      content:'*';
      color: red;
      margin-left: 4px;
    }`}
`;

export default Field;
