import React from 'react';
import { Bar } from 'react-chartjs-2';

const AgeBar = ({ statistics }) => {
  const { numberOfUsers, teensToEighties } = statistics;
  const data = {
    labels: ['10대', '20대', '30대', '40대', '50-80대'],
    datasets: [
      {
        label: 'Count',
        data: [
          teensToEighties[0],
          teensToEighties[1],
          teensToEighties[2],
          teensToEighties[3],
          teensToEighties[4] +
            teensToEighties[5] +
            teensToEighties[6] +
            teensToEighties[7],
          3,
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      title: {
        display: true,
        font: {
          size: 20,
        },
        text: '사용자 연령대',
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return <Bar width={400} height={400} data={data} options={options} />;
};

export default AgeBar;
