import React from 'react';
import { getStatus } from '@/api/sales';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import MultiSelection from '../common/multiSelection/MultiSelection';
import ReloadIcon from '../../assets/common/reload.png';
import { SalesStatus, ServiceType, TodoStatus } from '@/types/sales.types';
import { DEFAULT_FILTER } from '@/constants/sales/data';

type Props = {
  serviceType: ServiceType[];
  setServiceType: (data: ServiceType[]) => void;
  salesStatus: SalesStatus[];
  setSalesStatus: (data: SalesStatus[]) => void;
  todoStatus: TodoStatus[];
  setTodoStatus: (data: TodoStatus[]) => void;
};

function SalesFilter({
  serviceType,
  setServiceType,
  salesStatus,
  setSalesStatus,
  todoStatus,
  setTodoStatus,
}: Props) {
  const { data: statusData } = useQuery(['status'], getStatus, {
    refetchOnWindowFocus: false,
  });

  const onResetFilter = () => {
    setServiceType(DEFAULT_FILTER.serviceType as ServiceType[]);
    setSalesStatus(DEFAULT_FILTER.salesStatus as SalesStatus[]);
    setTodoStatus(DEFAULT_FILTER.todoStatus as TodoStatus[]);
  };

  return (
    <Filter>
      <MultiSelections>
        <MultiSelection
          key='serviceTypes'
          name='serviceTypes'
          options={statusData?.serviceTypes as ServiceType[]}
          selectedItems={serviceType}
          setSelectedItems={setServiceType}
        />
        <MultiSelection
          key='salesStatuses'
          name='salesStatuses'
          options={statusData?.salesStatuses as SalesStatus[]}
          selectedItems={salesStatus}
          setSelectedItems={setSalesStatus}
        />
        <MultiSelection
          key='todoStatuses'
          name='todoStatuses'
          options={statusData?.todoStatuses as TodoStatus[]}
          selectedItems={todoStatus}
          setSelectedItems={setTodoStatus}
        />
      </MultiSelections>
      <ResetButton type='button' onClick={onResetFilter}>
        <img src={ReloadIcon} alt='' width='20px' height='20px' />
        <span>필터 초기화</span>
      </ResetButton>
    </Filter>
  );
}

const Filter = styled.div``;

const MultiSelections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
`;

const ResetButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 6px 12px;
  background-color: #e5e6e8;
  font-size: 14px;
  font-weight: 600;
  color: #3a3b3e;
  border-radius: 4px;
`;

export default SalesFilter;
