import React from 'react';
import { useHistory } from 'react-router-dom';

function useUpdateUrlParams() {
  const history = useHistory();

  const updateUrlParams = (params: Record<string, string>) => {
    const currentUrl = new URL(window.location.href);
    Object.entries(params).forEach(([key, value]) => {
      currentUrl.searchParams.set(key, value);
    });
    history.push(
      `${currentUrl.pathname}?${currentUrl.searchParams.toString()}`,
    );
  };

  return updateUrlParams;
}

export default useUpdateUrlParams;
