import { TableBody } from '@material-ui/core';

import { BillType, KioskDetailDepositInfo } from '@/types/api/kiosk';
import CashItem from './CashItem';
import KioskDataTable from './KioskDataTable';
import { getKioskDetailCashBalance } from '@/utils/kiosk';

type CashBalanceListProps = {
  depositInfo: KioskDetailDepositInfo[];
  billType: BillType;
};

function CashBalanceList({ depositInfo, billType }: CashBalanceListProps) {
  const { columns } = getKioskDetailCashBalance(billType);

  return (
    <KioskDataTable
      columns={columns}
      contentComponent={
        <TableBody>
          {depositInfo.map((item, idx) => (
            <CashItem key={idx} idx={idx} data={item} billType={billType} />
          ))}
        </TableBody>
      }
    />
  );
}

export default CashBalanceList;
