import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import DetailContent from './DetailContent';

import { getWithdrawalDetail, updateTpointData } from '../../../api/ktp';
import ActionBtn from '@/components/common/ActionBtn';
import Modal from '@/components/common/Modal';

function DetailModal({ onClose, selected }) {
  const { pointsIndex, pointStatus, isRead } = selected;
  const {
    isLoading: isFetchLoading,
    data,
    isError,
    isSuccess,
  } = useQuery(['withdrawalDetail'], () => getWithdrawalDetail(pointsIndex));
  const queryClient = useQueryClient();
  const [active, setActive] = useState(pointStatus === 'WITHDRAW');

  const updateMutation = useMutation(
    (body) => updateTpointData(pointsIndex, body),
    {
      onError: (err) => {
        console.log(err);
        alert(`포인트 정보 변경에 실패하였습니다. 관리자에게 문의해주세요.`);
      },
      onSuccess: () => {
        queryClient.invalidateQueries('withdrawalLists');
      },
    },
  );

  useEffect(() => {
    isRead === false && updateMutation.mutate({ isRead: true });
  }, []);

  const onModalClose = useCallback(() => {
    onClose('DETAIL', null);
  }, []);

  const onWithdrawal = useCallback(() => {
    const isOk = window.confirm('정산을 완료하였습니까?');
    if (isOk) {
      setActive(false);
      updateMutation.mutate({ pointStatus: 'COMPLETE' });
    }
  }, [updateMutation]);

  return (
    <Modal
      onClose={onModalClose}
      isLoading={isFetchLoading}
      error={
        isError && '상세보기 조회에 실패했습니다. 관리자에게 문의해주세요.'
      }
    >
      <Wrapper>
        {isSuccess && <DetailContent data={data} />}
        <BtnContainer>
          <ActionBtn title="정산하기" onClick={onWithdrawal} size="big" />
        </BtnContainer>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export default React.memo(DetailModal);
