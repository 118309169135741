import React from 'react';
import styled from 'styled-components';

type DivideLineProps = {
  color?: string;
  height?: string;
  margin?: string;
};

function DivideLine({ height, color, margin }: DivideLineProps) {
  return <Divide height={height} color={color} margin={margin} />;
}

const Divide = styled.div<{ height?: string; color?: string; margin?: string }>`
  height: ${(props) => (props.height ? props.height : '1px')};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  background-color: ${(props) => (props.color ? props.color : 'black')};
`;

export default DivideLine;
