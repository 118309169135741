import { getHomeData } from '@/api/medical';
import { IGetHomeDataPayload, IHomeDateResponse } from '@/types/api/medical';
import { useQuery } from 'react-query';

function useHomeData(payload: IGetHomeDataPayload) {
  return useQuery<IHomeDateResponse, IGetHomeDataPayload>(
    ['homeData', payload],
    () => getHomeData(payload),
    {
      retry: false,
    },
  );
}

export default useHomeData;
