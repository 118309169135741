import { DatasType } from '@/pages/medical/HospitalRemittanceStatus';
import { TableBody } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import RemittanceStatusItem from './RemittanceStatusItem';

type RemittanceStatusListProps = {
  datas: DatasType[];
  setRemittanceDatas: Dispatch<SetStateAction<DatasType[]>>;
  onOpenModal: (e: any, value: number, status: string) => void;
};

function RemittanceStatusList({
  datas,
  setRemittanceDatas,
  onOpenModal,
}: RemittanceStatusListProps) {
  return (
    <TableBody>
      {datas?.map((data: any, idx: number) => (
        <RemittanceStatusItem
          key={idx}
          idx={idx}
          data={data}
          datas={datas}
          setRemittanceDatas={setRemittanceDatas}
          onOpenModal={onOpenModal}
        />
      ))}
    </TableBody>
  );
}

export default RemittanceStatusList;
