import React from 'react';
import { TableRow } from '@material-ui/core';
import styled from 'styled-components';

import CustomTableCell from '@/components/common/CustomTableCell';
import { KioskInfoType } from '@/types/api/ktp';
import { determineKioskStatus } from '@/utils/ktp';

type KioskListProps = {
  data: KioskInfoType;
  index: number;
};

function KioskList({ data, index }: KioskListProps) {
  const { storeName, bd1Count, bd2Count, hp1Count, kioskStatus } = data;
  const error = determineKioskStatus(kioskStatus);
  return (
    <StyledTableRow>
      <CustomTableCell idx={0} width="90">
        {index + 1}
      </CustomTableCell>
      <CustomTableCell width="240">{storeName}</CustomTableCell>

      <CustomTableCell
        width="170"
        color={kioskStatus.bd2Lack ? '#FF2D55' : '#212121'}
      >
        {bd2Count}/1000
      </CustomTableCell>
      <CustomTableCell
        width="170"
        color={kioskStatus.bd1Lack ? '#FF2D55' : '#212121'}
      >
        {bd1Count}/1000
      </CustomTableCell>
      <CustomTableCell
        width="170"
        color={kioskStatus.hp1Lack ? '#FF2D55' : '#212121'}
      >
        {hp1Count}/300
      </CustomTableCell>
      <CustomTableCell
        width="170"
        color={determineKioskStatus(kioskStatus).color}
      >
        {error.cashAlert}
        {error.cashAlert && error.message && ' / '}
        {error.message}
      </CustomTableCell>
    </StyledTableRow>
  );
}

const StyledTableRow = styled(TableRow)`
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;
export default KioskList;
