import React, { useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { createNotice } from '../../../api/ktp';
import Preview from './Preview';
import Form from './Form';
import ActionBtn from '@/components/common/ActionBtn';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1.5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(
  ({ children, classes, onCloseModal, ...other }) => {
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            const isOK = window.confirm(
              '작성 중인 내용은 저장되지 않습니다. 정말로 나가시겠습니까?',
            );
            if (!isOK) {
              return;
            }
            onCloseModal();
          }}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  },
);

function AddModal({ onCloseModal, selectedData: { noticeIndex } }) {
  const [state, setState] = useState({
    isFixed: false,
    isImmediate: true,
    scheduledDate: '',
    title: '',
    subTitle1: '',
    subTitle2: '',
    subTitle3: '',
    content1: '',
    content2: '',
    content3: '',
    link: 'NONE',
    isInvisible: false,
    noticeType: 'NONE',
  });
  const [imgList, setImgList] = useState({
    mainImg: null,
    subImg1: null,
    subImg2: null,
    subImg3: null,
  });
  const mutation = useMutation((payload) => createNotice(payload), {
    onError: () =>
      alert('공지사항 등록에 실패하였습니다. 관리자에게 문의해주세요.'),
    onSuccess: (statusCode) => {
      if (statusCode === 200) {
        alert('공지사항이 정상적으로 게시되었습니다.');
        onCloseModal();
      }
    },
  });
  const onSubmit = () => {
    if (!state.isImmediate && !state.scheduledDate) {
      alert('예약 발송 날짜를 입력해주세요.');
      return;
    }
    if (!state.title || !state.content1 || state.noticeType === 'NONE') {
      alert('필수 입력 항목을 채워주세요.');
      return;
    }
    const isOK = window.confirm(
      'KTP의 모든 사용자에게 해당 내용으로 공지사항을 올리시겠습니까?',
    );
    if (!isOK) {
      return;
    }
    const formData = new FormData();
    formData.append('dataList', JSON.stringify(state));
    formData.append('mainImg', imgList.mainImg);
    formData.append('subImg1', imgList.subImg1);
    formData.append('subImg2', imgList.subImg2);
    formData.append('subImg3', imgList.subImg3);
    mutation.mutate(formData);
  };
  const onChangeInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const onChangeImage = (e) => {
    setImgList({ ...imgList, [e.target.name]: e.target.files[0] });
  };
  const onChangeChecked = (e) => {
    setState({ ...state, [e.target.name]: e.target.checked });
  };
  const onChangeRadio = (e) => {
    setState({
      ...state,
      isImmediate: e.target.name === 'isImmediate' ? true : false,
      ...(e.target.name === 'isImmediate' && { scheduledDate: '' }),
    });
  };
  const onChangeSelect = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <Dialog
      style={{ height: '100%' }}
      fullWidth={true}
      open={true}
      maxWidth="lg"
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="customized-dialog-title" onCloseModal={onCloseModal} />
      <DialogContent style={{ padding: 50 }}>
        <FormAndPreview>
          <Form
            state={state}
            onChangeChecked={onChangeChecked}
            onChangeImage={onChangeImage}
            onChangeInput={onChangeInput}
            onChangeRadio={onChangeRadio}
            onChangeSelect={onChangeSelect}
          />
          <Preview
            state={state}
            mainImg={imgList.mainImg}
            subImg1={imgList.subImg1}
            subImg2={imgList.subImg2}
            subImg3={imgList.subImg3}
          />
        </FormAndPreview>
      </DialogContent>
      <DialogActions>
        <BtnContainer>
          <ActionBtn title="발송하기" onClick={onSubmit} size="big" />
        </BtnContainer>
      </DialogActions>
    </Dialog>
  );
}

const FormAndPreview = styled.div`
  display: flex;
`;

const BtnContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: 0 44px 20px;
`;
export default AddModal;
