import React from 'react';
import HostApplicantListItem from '../../../components/etc/noonch/HostApplicantListItem';

import {
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import styled from 'styled-components';
import LoadingView from '../../../components/common/LoadingView';
import { useQuery } from 'react-query';
import { getHostApplicant } from '../../../api/successmode';

const HostApplicant = () => {
  const { data, isLoading } = useQuery('hostApplicantData', getHostApplicant, {
    retry: false,
  });
  if (isLoading) {
    return <LoadingView />;
  }

  if (!data) {
    return <div>데이터가 없습니다.</div>;
  }

  const columns = [
    'ID',
    '프로필',
    '이름(성별)',
    '연락처',
    'Email',
    '직업',
    '거절 사유',
  ];
  return (
    <StyledContainer>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((host) => (
              <HostApplicantListItem
                key={host.id}
                host={host}
              ></HostApplicantListItem>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  display: flex;
  margin: 0;
`;

export default HostApplicant;
