import styled from 'styled-components';
import ChatBox from './ChatBox';
import { QnaListResponse } from '@/types/qna.type';

type Props = {
  contentList: QnaListResponse[];
};

function QnaHistory({ contentList }: Props) {
  // Reverse 이유
  // 원본 데이터: 최신 건이 목록 최하단
  // 리버스 데이터: 최신 건이 목록 최상단
  // 채팅 형태의 UI를 사용
  // 스크롤이 가장 하단으로 내려가야 함.
  // 스타일에 flex-direction: column-reverse; 처리를 해서 데이터 거꾸로 출력.
  const reverseData = [...contentList].reverse();

  return (
    <PaddingWrapper>
      <Wrapper>
        {contentList?.length === 0 ? (
          <EmptyWrapper>
            <EmptyText>작성된 문의가 없습니다.</EmptyText>
          </EmptyWrapper>
        ) : (
          reverseData.map((chat) => {
            return (
              <ChatWrapper isQuestion={!chat.isQuestion} key={chat.createdDate}>
                <ChatBox
                  content={chat.content}
                  createdDate={chat.createdDate}
                  isQuestion={!chat.isQuestion}
                />
              </ChatWrapper>
            );
          })
        )}
      </Wrapper>
    </PaddingWrapper>
  );
}

export default QnaHistory;

type ChatStyleProps = {
  isQuestion: boolean;
};

const PaddingWrapper = styled.div`
  border: 1px solid #e5e6e8;
  border-radius: 20px;
  padding: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 14px;
  border-radius: 6px;
  width: 100%;
  min-height: 340px;
  max-height: 400px;
  padding: 24px 20px;
  background: #f5f6f7;
  overflow: auto;
  scroll-behavior: smooth;
`;

const ChatWrapper = styled.div<ChatStyleProps>`
  display: flex;
  justify-content: ${({ isQuestion }) =>
    isQuestion ? 'flex-end' : 'flex-start'};
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const EmptyText = styled.p`
  margin-top: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #cbccce;
`;
