import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import FlexWrap from './FlexWrap';
import { useLocation } from 'react-router-dom';

interface IProps {
  size: number;
  onChangeView: (view: number) => void;
  isMedical?: boolean;
}
function ViewDateSelector({ size, onChangeView, isMedical }: IProps) {
  const { pathname } = useLocation();

  const isRefundStatus = () => {
    return !!pathname.match(/\/refund-status/);
  };

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChangeView(Number(e.target.value));
  };

  return (
    <ViewContainer isMedical={isMedical}>
      <Select value={size} onChange={onChange}>
        <Option value='10'>10개씩 보기</Option>
        <Option value='100'>100개씩 보기</Option>
        <Option value='500'>500개씩 보기</Option>
        {isRefundStatus() && <Option value='10000'>1만개씩 보기</Option>}
      </Select>
    </ViewContainer>
  );
}

const ViewContainer = styled(FlexWrap)<{ isMedical?: boolean }>`
  margin: ${(props) => (props.isMedical ? '0' : '12px 0')};
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

const Select = styled.select`
  padding: 4px 8px;
`;
const Option = styled.option``;

export default ViewDateSelector;
