import React from 'react';
import { useQuery } from 'react-query';
import {
  Container,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import UserInfoListItem from '../../../components/etc/noonch/UserInfoListItem';
import styled from 'styled-components';
import LoadingView from '../../../components/common/LoadingView';
import { getUserInfo } from '../../../api/successmode';

const UserInfo = () => {
  const { data, isLoading } = useQuery('userInfo', getUserInfo);

  if (isLoading) {
    return <LoadingView />;
  }

  if (!data) {
    return null;
  }

  const columns = [
    'ID',
    '가입일',
    '프로필',
    '이름(등급)',
    '성별(생년월일)',
    '요청식사',
    '완료식사',
    '등록식사',
  ];
  return (
    <StyledContainer>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((userInfo) => (
              <UserInfoListItem
                key={userInfo.userIndex}
                userInfo={userInfo}
              ></UserInfoListItem>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  display: flex;
  margin: 0;
`;
export default UserInfo;
