import axios from 'axios';
import api from '..';

const getTargetServerURL = () => {
  return 'https://erp.successmode.co.kr';
};

export const convertJasypt = async ({
  category,
  password,
  configText,
}: any) => {
  const res = await api.postAxios(
    `${getTargetServerURL()}/developer/jasypt/${category}`,
    {
      password,
      configText,
    },
  );
  return res.data;
};
