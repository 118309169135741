import html2canvas from 'html2canvas';
import {
  IFormatedHomeData,
  IGetHomeDataResponse,
  IExcelResponse,
  IFormattedExcel,
} from '@/types/api/medical';

export const formatHomeData = (
  data: IGetHomeDataResponse,
): IFormatedHomeData => {
  return {
    refundSummary: data.refundSummary,
    tableData: [
      {
        title: '최근 환급내역',
        value: 'REFUND',
        data: data.latestRefundInfo,
      },
      {
        title: '주요 공지사항',
        value: 'NOTICE',
        data: data.notice,
      },
    ],
  };
};

export const formattedEquipment = (equipmentTypes: string[]) => {
  if (equipmentTypes.length === 0) {
    return '-';
  }

  const newEquipmentTypes = equipmentTypes.sort((a) =>
    a === 'KIOSK' ? -1 : 1,
  );
  const result = newEquipmentTypes.map((type) => {
    return type === 'KIOSK'
      ? '키오스크'
      : type === 'SCANNER'
      ? '스캐너'
      : '기타';
  });
  return result.length > 1 ? result[0] + ' 외' : result[0];
};

export const foramttedDateHypen = (date: string) => {
  const newDate = date.replace(/[^0-9]/g, '');
  return newDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
};

export const getFormattedTableData = (
  data: IExcelResponse,
): IFormattedExcel => {
  const {
    doubleEyeDto,
    rhinoplastyDto,
    breastDto,
    liposuctionDto,
    faceLiftDto,
    facialBoneDto,
    teethWhiteningDto,
    orthognathicDto,
    nevusDto,
    pimpleDto,
    hairRemovalDto,
    hairTransplantationDto,
    tatooDto,
    lipolysisDto,
    skinDto,
    othersDto,
  } = data.productAllDto;

  return {
    ...data,
    productAllDto: [
      {
        label: ' ① 쌍커풀수술 Double Eye-lid',
        price: doubleEyeDto?.price,
        vat: doubleEyeDto?.vat,
      },
      {
        label: ' ② 코성형수술 Rhinoplasty',
        price: rhinoplastyDto?.price,
        vat: rhinoplastyDto?.vat,
      },
      {
        label: ' ③ 유방수술 Breast Surgery',
        price: breastDto?.price,
        vat: breastDto?.vat,
      },
      {
        label: ' ④ 지방흡인술 Liposuction',
        price: liposuctionDto?.price,
        vat: liposuctionDto?.vat,
      },
      {
        label: ' ⑤ 주름살제거술 Face Lift',
        price: faceLiftDto?.price,
        vat: faceLiftDto?.vat,
      },
      {
        label: ' ⑥ 안면윤곽술 Facial bone contouring ',
        price: facialBoneDto?.price,
        vat: facialBoneDto?.vat,
      },
      {
        label: ' ⑦ 치아성형술 Teeth Whitening, Laminate, gingivoplasty',
        price: teethWhiteningDto?.price,
        vat: teethWhiteningDto?.vat,
      },
      {
        label: ' ⑧ 악안면 교정술 Orthognathic surgery',
        price: orthognathicDto?.price,
        vat: orthognathicDto?.vat,
      },
      {
        label:
          ' ⑨ 색소모반ㆍ주근깨ㆍ흑색점ㆍ기미치료술 Nevus pigmentosus, Freckles, Lentigo, Chloasma',
        price: nevusDto?.price,
        vat: nevusDto?.vat,
      },
      {
        label: ' ⑩ 여드름 치료술 Pimple',
        price: pimpleDto?.price,
        vat: pimpleDto?.vat,
      },
      {
        label: ' ⑪ 제모술 Hair removal',
        price: hairRemovalDto?.price,
        vat: hairRemovalDto?.vat,
      },
      {
        label: ' ⑫ 탈모치료술, 모발이식술 Hair loss , Hair transplantation',
        price: hairTransplantationDto?.price,
        vat: hairTransplantationDto?.vat,
      },
      {
        label:
          ' ⑬ 문신술 및 문신제거술, 피어싱 Tatoo, Tattoo removal, piercing ',
        price: tatooDto?.price,
        vat: tatooDto?.vat,
      },
      {
        label: ' ⑭ 지방융해술 Lipolysis',
        price: lipolysisDto?.price,
        vat: lipolysisDto?.vat,
      },
      {
        label: `⑮ 피부재생술, 피부미백술, 항노화치료술 및 모공축소술\nSkin regeneration, Skin Whitening, Anti-aging, Pore tightening`,
        price: skinDto?.price,
        vat: skinDto?.vat,
      },
      {
        label: ' ⑯ 기타(\t\t\t) Others',
        price: othersDto?.price,
        vat: othersDto?.vat,
      },
    ],
  };
};
export const convertComponentToImage = async (el: HTMLElement) => {
  const canvas = await html2canvas(el);
  return dataURItoBlob(canvas.toDataURL('image/png', 1.0));
};
export const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};
export const formatDatetimeForInput = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};
// YYYY-MM-DD 날짜 형태 변환
export const formattedDate = (date: Date) => {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};
