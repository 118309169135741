import React from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { format } from 'date-fns';

import { getNoticeDetailMedical } from '@/api/medical';
import { getNoticeDetailKtp } from '@/api/ktp';
import ActionBtn from '@/components/common/ActionBtn';
import Modal from '@/components/common/Modal';

function NoticeModal({ onClose, noticeIndex, isKtp = false }) {
  const { isLoading, data, isError, isSuccess } = useQuery(
    [isKtp ? 'ktpDetail' : 'hospitalDetail', noticeIndex],
    () =>
      isKtp
        ? getNoticeDetailKtp(noticeIndex)
        : getNoticeDetailMedical(noticeIndex),
  );

  return (
    <Modal
      error={
        isError && '공지사항 조회에 실패하였습니다. 관리자에게 문의해주세요.'
      }
      isLoading={isLoading}
      onClose={onClose}
    >
      {isSuccess && (
        <ModalContentConainer>
          <Title>{data.title}</Title>
          <SubInfo>{`작성일 | ${format(
            new Date(data.scheduledDate),
            'yyyy.MM.dd',
          )}   작성자 | ${data.writer}`}</SubInfo>
          <Content dangerouslySetInnerHTML={{ __html: data.content }}></Content>
          <Line />
        </ModalContentConainer>
      )}
      <ConfirmBtn title="확인" size="big" onClick={onClose} />
    </Modal>
  );
}
const ModalContentConainer = styled.div`
  width: 100%;
`;
const Title = styled.h2``;

const SubInfo = styled.p`
  font-size: 14px;
  line-height: 22px;
  padding: 36px 0 12px;
  color: #757575;
  white-space: pre-wrap;
  font-weight: 400;
  border-bottom: 1px solid #bdbdbd;
`;
const Content = styled.div`
  margin: 46px 0;
  height: 450px;
  overflow-y: scroll;
`;
const Line = styled.div`
  margin-bottom: 50px;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #bdbdbd;
`;
const ConfirmBtn = styled(ActionBtn)`
  border-top: 1px solid #bdbdbd;
  position: absolute;
  right: 0px;
  bottom: 0px;
`;
export default React.memo(NoticeModal);
