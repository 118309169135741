import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';

export type IProps = {
  id: string;
  defaultValue?: number | null;
  onChange: (selectedCriteria: any, id: string) => void;
};

function CashBillalarmSetting({ id, defaultValue, onChange }: IProps) {
  const [inputValue, setInputValue] = useState<string>(
    defaultValue?.toString() || '',
  );

  useEffect(() => {
    setInputValue(defaultValue?.toString() || '');
  }, [defaultValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    onChange(value, id);
  };

  return (
    <SelectContainer>
      <Input type='number' value={inputValue} onChange={handleChange} />
    </SelectContainer>
  );
}

export default CashBillalarmSetting;

const SelectContainer = styled(FlexWrap)<{
  padding?: string;
  flexDirection?: string;
  gap?: string;
}>`
  align-items: center;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  gap: ${({ gap }) => gap || '12px'};
  padding: ${({ padding }) => padding || '0'};
`;

const Input = styled.input`
  width: 104px;
  height: 38px;
  text-align: center;
  size: 14px;
  line-height: 21px;
  border: 1px solid #cccccc;
  border-radius: 4px;
`;
