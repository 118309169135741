import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { TableBody } from '@material-ui/core';

import { getKioskDetailWithdrawal } from '@/api/kiosk';
import LoadingView from '@/components/common/LoadingView';
import Paging from '@/components/common/Paging';
import Typography from '@/components/common/Typography';
import { GetKioskDetailWithdrawalResponse } from '@/types/api/kiosk';
import KioskDataTable from './kioskDetail/KioskDataTable';
import WithdrawalInfoItem from './kioskDetail/WithdrawalInfoItem';
import { StateType } from './KioskStatusDetail';
import KioskTitle from './KioskTitle';
import FlexWrap from '@/components/common/FlexWrap';
import ViewDateSelector from '@/components/common/ViewDateSelector';
import useRefundReducer from '@/hooks/medical/useRefundReducer';
import DateSelector from './DateSelector';
import { getKioskDetailWithdrawalInfo } from '@/utils/kiosk';

function KioskWithdrawalDetail() {
  const [state, dispatch] = useRefundReducer();
  const { filter, dateFilter } = state;

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [size, setSize] = useState(10);

  const { state: locationState } = useLocation<StateType>();
  const { title, preTitle, kioskId, detailTitle, billType } = locationState;

  const payload = {
    queryString: {
      page: pageNumber,
      size,
      startDate: filter.startDate,
      endDate: filter.endDate,
    },
    kioskIndex: kioskId,
  };

  const { data: kioskDetailWithdrawal } = useQuery<
    GetKioskDetailWithdrawalResponse,
    number
  >(['kioskDetailDepsit', payload], () => getKioskDetailWithdrawal(payload), {
    onSuccess: (data) => {
      setTotalPage(data.withdrawalInfo.totalPages);
    },
    retry: false,
  });

  useEffect(() => {
    setPageNumber(0);
  }, [dateFilter]);

  return (
    <>
      <FlexWrap alignItems="center" justifyContent="space-between">
        <KioskTitle
          kioskId={kioskId}
          title={title}
          preTitle={preTitle}
          detailTitle={detailTitle}
          billType={billType}
        />
        <FlexWrap alignItems="center" gap="25px">
          <ViewDateSelector
            isMedical
            size={size}
            onChangeView={(value) => setSize(value)}
          />
          <DateSelector
            top="120px"
            right="50px"
            dateFilter={dateFilter}
            dispatch={dispatch}
            position="static"
          />
        </FlexWrap>
      </FlexWrap>
      {kioskDetailWithdrawal?.withdrawalInfo?.content ? (
        <>
          <KioskDataTable
            columns={
              getKioskDetailWithdrawalInfo(kioskDetailWithdrawal?.billType)
                ?.columns
            }
            contentComponent={
              kioskDetailWithdrawal.withdrawalInfo?.content?.length < 1 ? (
                <Typography>데이터가 없습니다.</Typography>
              ) : (
                <TableBody>
                  {kioskDetailWithdrawal.withdrawalInfo?.content?.map(
                    (item, idx) => (
                      <WithdrawalInfoItem
                        key={idx}
                        idx={idx}
                        data={item}
                        isDetail
                        billType={kioskDetailWithdrawal?.billType}
                      />
                    ),
                  )}
                </TableBody>
              )
            }
          />
          <Paging
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalPage={totalPage}
          />
        </>
      ) : (
        <LoadingView />
      )}
    </>
  );
}

export default KioskWithdrawalDetail;
