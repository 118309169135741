import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Icon from '@/components/common/Icon';
import Typography from '@/components/common/Typography';
import ViewDateSelector from '@/components/common/ViewDateSelector';
import filterIcon from '@assets/common/filter.png';
import RemittanceModal from './RemittanceModal';
import FlexWrap from '@/components/common/FlexWrap';
import RemittancePaymentModal from './RemittancePaymentModal';
import { DatasType } from '../../ktp/remmitance-status/RemittanceStatusList';
import {
  mailCountFilter,
  paymentFilter,
  sortFilterAll,
  sortFilterPayment,
} from '@/constants/ktp';
import { useMutation } from 'react-query';
import {
  emittanceDownload,
  emittanceMoinBizDownload,
  updatePaymentStatus,
} from '@/api/ktp';
import { AxiosError } from 'axios';
import {
  IExcelResponse,
  RemittanceDownloadPayload,
  UpdatePaymentsPayload,
} from '@/types/api/medical';
import {
  emittanceMedicalDownload,
  emittanceMoinBizMedicalDownload,
  updateMedicalPaymentStatus,
} from '@/api/medical';
import RemittanceDownloadFile from '@/components/medical/remmitanceStatus/RemittanceDownloadFile';
import {
  convertComponentToImage,
  formattedDate,
  getFormattedTableData,
} from '@/utils/common';
import JSZip from 'jszip';
import ReceiptImage from '@/components/ktp/refund-status/ReceiptImage';
import { formatReceiptForm } from '@/utils/ktp';
import LoadingView from '../LoadingView';

export type ListType = {
  title: string;
  isSelected: boolean;
  value: string;
  isDisable?: boolean;
};

export type FilterListType = {
  orderBy: ListType[];
  paymentStatus: ListType[];
  mainCount: ListType[];
};

type RemittanceFilterProps = {
  size: number;
  currentTransferView: string;
  onClickChangeTransfer: (value: string) => void;
  onChangeView: (value: number) => void;
  applyFilter: (value1: string, value2: string, value3: string) => void;
  isFiltered: boolean;
  isSelected: boolean;
  selectedDatas: DatasType[];
  isFilterModal: boolean;
  setIsFilterModal: Dispatch<SetStateAction<boolean>>;
  isPaymentModal: boolean;
  setIsPaymentModal: Dispatch<SetStateAction<boolean>>;
  isMedical?: boolean;
};

function RemittanceFilter({
  size,
  currentTransferView,
  onClickChangeTransfer,
  onChangeView,
  applyFilter,
  isFiltered,
  isSelected,
  selectedDatas,
  isFilterModal,
  setIsFilterModal,
  isPaymentModal,
  setIsPaymentModal,
  isMedical = false,
}: RemittanceFilterProps) {
  const [filterList, setFilterList] = useState<FilterListType>({
    orderBy: [],
    paymentStatus: [],
    mainCount: [],
  });
  const [files, setFiles] = useState([]);
  const [fileDownLoading, setFileDownLoading] = useState(false);

  const mutation = useMutation<number, AxiosError, UpdatePaymentsPayload>(
    isMedical ? updateMedicalPaymentStatus : updatePaymentStatus,
    {
      retry: false,
      onSuccess: () => {
        alert(`${selectedDatas.length}건을 송금 중으로 변경했습니다.`);
      },
      onError: () => {
        alert('서버와 연결이 원활하지 않습니다. 다시 시도해주세요.');
      },
    },
  );

  const { mutate: moinBizMutation } = useMutation<
    Blob,
    AxiosError,
    RemittanceDownloadPayload
  >(
    (payload) =>
      isMedical
        ? emittanceMoinBizMedicalDownload(payload)
        : emittanceMoinBizDownload(payload),
    {
      retry: false,
      onSuccess: (res) => {
        const date = formattedDate(new Date());

        const blob = new Blob([res]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `모인비즈_송금내역_통합_${date}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);
      },
      onError: () => {
        alert('서버와 연결이 원활하지 않습니다. 다시 시도해주세요.');
      },
    },
  );

  const { data: downloadDatas, mutate: downloadMutation } = useMutation<
    any,
    AxiosError,
    RemittanceDownloadPayload
  >(
    (payload) =>
      isMedical
        ? emittanceMedicalDownload(payload)
        : emittanceDownload(payload),
    {
      retry: false,
      onError: () => {
        alert('서버와 연결이 원활하지 않습니다. 다시 시도해주세요.');
      },
    },
  );

  const onClickRemittingBtn = () => {
    mutation.mutate({
      paymentStatus: 'PAYMENT_IN_PROGRESS',
      data: selectedDatas.map((item) => ({ refundIndex: item.refundId })),
    });
  };

  const onClickDownload = (nation?: string) => {
    const ids = selectedDatas.map((item) => item.refundId);

    moinBizMutation({ ids, nation });
    downloadMutation({ ids });
  };

  useEffect(() => {
    const newSortFilter = currentTransferView
      ? sortFilterPayment
      : sortFilterAll;

    setFilterList({
      ...filterList,
      orderBy: newSortFilter,
      paymentStatus: paymentFilter,
      mainCount: mailCountFilter,
    });
  }, [currentTransferView]);

  useEffect(() => {
    if (files.length === downloadDatas?.data.length) {
      handleUploadAndNotify();
    }
  }, [files]);

  const handleUploadAndNotify = async () => {
    setFileDownLoading(true);
    const result = await uploadExcelImage();
    setFileDownLoading(false);
    if (result.success) {
      alert('압축 파일 생성 및 다운로드가 완료되었습니다!');
    } else {
      alert('압축 파일 생성 또는 다운로드 중 오류가 발생하였습니다.');
    }
    setFiles([]);
  };

  const uploadExcelImage = async () => {
    try {
      const zip = new JSZip();

      // 파일들을 순차적으로 처리하기 위해 for...of 루프 사용
      for (const file of files) {
        const blob = await convertComponentToImage(file);
        const index = files.indexOf(file); // 파일의 인덱스 가져오기

        // 이미지 파일 추가 (Blob 객체 직접 전달)
        zip.file(`${index + 1}.png`, blob);
      }

      // 압축 파일 생성
      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const url = URL.createObjectURL(zipBlob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `확인서.zip`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);

      // 압축 파일 생성 및 다운로드가 성공적으로 완료된 경우
      return { success: true };
    } catch (error) {
      console.error('압축 파일 생성 중 오류 발생:', error);
      // 압축 파일 생성에 실패한 경우
      return { success: false, error };
    }
  };

  return (
    <>
      <Container>
        <FlexWrap>
          <TransferViewContainer>
            <TransferView
              isActive={currentTransferView === ''}
              onClick={() => onClickChangeTransfer('')}
            >
              전체보기
            </TransferView>
            <TransferView
              isActive={currentTransferView === 'MODIFY_NEEDED'}
              onClick={() => onClickChangeTransfer('MODIFY_NEEDED')}
            >
              정보수정 필요
            </TransferView>
            <TransferView
              isActive={currentTransferView === 'TRANSFER_AVAILABLE'}
              onClick={() => onClickChangeTransfer('TRANSFER_AVAILABLE')}
            >
              송금 가능
            </TransferView>
            <TransferView
              isActive={currentTransferView === 'MAIL_SEND_NEEDED'}
              onClick={() => onClickChangeTransfer('MAIL_SEND_NEEDED')}
            >
              메일 발송 필요
            </TransferView>
          </TransferViewContainer>
          <Remitting isSelected={isSelected} onClick={onClickRemittingBtn}>
            송금중
          </Remitting>
          <RemittanceCompelte
            isSelected={isSelected}
            onClick={() => setIsPaymentModal(true)}
          >
            송금 완료
          </RemittanceCompelte>
          {currentTransferView === 'TRANSFER_AVAILABLE' &&
            (fileDownLoading ? (
              <Remitting isSelected={isSelected}>
                <LoadingView />
              </Remitting>
            ) : (
              <RemittanceCompelte
                isSelected={isSelected}
                onClick={() => onClickDownload()}
              >
                확인서 다운
              </RemittanceCompelte>
            ))}
          {currentTransferView === 'TRANSFER_AVAILABLE' &&
            (fileDownLoading ? (
              <Remitting isSelected={isSelected}>
                <LoadingView />
              </Remitting>
            ) : (
              <RemittanceCompelte
                isSelected={isSelected}
                onClick={() => onClickDownload('jpn')}
              >
                일본 확인서 다운
              </RemittanceCompelte>
            ))}
        </FlexWrap>
        <FilterContainer>
          <FilterDetail
            onClick={() => setIsFilterModal(true)}
            isFiltered={isFiltered}
          >
            <Icon imgUrl={filterIcon} width="20px" height="20px" />
            <Typography fontSize="14px" lineHeight="21px" fontWeight="600">
              필터
            </Typography>
          </FilterDetail>
          <ViewDateSelector size={size} onChangeView={onChangeView} />
        </FilterContainer>
        {isFilterModal && (
          <>
            <RemittanceModal
              filterList={filterList}
              setFilterList={setFilterList}
              currentTransferView={currentTransferView}
              setIsModal={setIsFilterModal}
              applyFilter={applyFilter}
            />
            <Backdrop onClick={() => setIsFilterModal(false)} />
          </>
        )}
        {isPaymentModal && (
          <>
            <RemittancePaymentModal
              selectedDatas={selectedDatas}
              setIsModal={setIsPaymentModal}
              isMedical={isMedical}
            />
            <Backdrop onClick={() => setIsPaymentModal(false)} />
          </>
        )}
        {downloadDatas && isMedical
          ? downloadDatas?.data?.map((file: IExcelResponse, idx: number) => (
              <RemittanceDownloadFile
                key={idx}
                data={getFormattedTableData(file)}
                setFiles={setFiles}
              />
            ))
          : downloadDatas?.data?.map((file: any, idx: number) => (
              <ReceiptImage
                key={idx}
                info={formatReceiptForm(file)}
                index={0}
                nation={file.nation}
                setFiles={setFiles}
                style={{
                  height: '90%',
                  overflowY: 'scroll',
                }}
              />
            ))}
      </Container>
    </>
  );
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 80;
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 56px;
`;
const TransferViewContainer = styled.div`
  display: flex;
  background-color: #fff;
  align-items: center;
`;
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const FilterDetail = styled.div<{ isFiltered?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 32px;
  background-color: ${(props) => (props.isFiltered ? '#A7A7A7' : '#e5e6e8')};
  border-radius: 6px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
const TransferView = styled.div<{ isActive?: boolean }>`
  padding: 16px 32px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #a7a7a7;
  white-space: nowrap;
  cursor: pointer;
  ${(props) =>
    props.isActive &&
    css`
      color: #1856cd;
      border-bottom: 2px solid #246cf6;
    `}
`;
const Remitting = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 45px;
  height: 40px;
  margin-left: 20px;
  white-space: nowrap;
  background-color: ${(props) => (props.isSelected ? '#246CF6' : '#e9f0fe')};
  color: #fff;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  cursor: ${(props) => (props.isSelected ? 'pointer' : 'default')};
`;

const RemittanceCompelte = styled(Remitting)`
  background-color: ${(props) => (props.isSelected ? '#3A3B3E' : '#E5E6E8')};
`;
export default RemittanceFilter;
