import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';

import Typography from '@/components/common/Typography';

function KioskDataTable({ columns, contentComponent }: any) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            style={{
              borderBottom: '2px solid #BDBDBD',
            }}
          >
            {columns.map((column: any, idx: number) => (
              <TableCell
                key={idx}
                align={column.type === 'customerGender' ? 'center' : 'left'}
                style={{
                  backgroundColor: '#ffffff',
                  padding: idx === 0 ? '4px 4px 4px 18px' : 4,
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                {column.title}{' '}
                {column.type === 'count' && (
                  <Typography fontSize="16px" fontWeight="600" color="#80848A">
                    (잔량)
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {contentComponent}
      </Table>
    </TableContainer>
  );
}

export default KioskDataTable;
