import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { updateKioskBillType } from '@/api/kiosk';
import { UpdateKioskBillTypePayload } from '@/types/api/kiosk';

function useUpdateKioskBillType() {
  return useMutation<null, AxiosError, UpdateKioskBillTypePayload>(
    (payload) => updateKioskBillType(payload),
    {
      retry: false,
      onSuccess: () => {
        alert('권종 변경이 완료되었습니다.');
      },
      onError: (e) => {
        alert('에러 발생');
      },
    },
  );
}

export default useUpdateKioskBillType;
