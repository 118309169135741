import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { StoreListType } from '@/hooks/ktp/usePushNotification';
import FlexWrap from '../../common/FlexWrap';
import SearchFranchaseinessItem from './SearchFranchaseinessItem';

type SearchFranchaseinessListProps = {
  selectedStoreList: StoreListType[];
  setSelectedStoreList: Dispatch<SetStateAction<StoreListType[]>>;
  filteredStoreList: StoreListType[];
};

function SearchFranchaseinessList({
  filteredStoreList,
  selectedStoreList,
  setSelectedStoreList,
}: SearchFranchaseinessListProps) {
  return (
    <>
      <Container>
        <Header>
          <HeaderColumn flex={1}>No.</HeaderColumn>
          <HeaderColumn flex={3}>사업자등록번호</HeaderColumn>
          <HeaderColumn flex={6}>상호명</HeaderColumn>
          <HeaderColumn flex={1.2}>선택</HeaderColumn>
        </Header>
        <FranchaseinessList>
          {filteredStoreList.map((store, idx) => (
            <SearchFranchaseinessItem
              key={idx}
              idx={idx}
              store={store}
              selectedStoreList={selectedStoreList}
              setSelectedStoreList={setSelectedStoreList}
              filteredStoreList={filteredStoreList}
            />
          ))}
        </FranchaseinessList>
      </Container>
    </>
  );
}

const Container = styled.div`
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #bdbdbd;
`;
const Header = styled(FlexWrap)`
  padding: 12px 40px;
`;
const HeaderColumn = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex};
`;
const FranchaseinessList = styled.div``;

export default SearchFranchaseinessList;
