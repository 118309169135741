import React from 'react';
import styled from 'styled-components';
import { ImageList, ImageListItem } from '@material-ui/core';

const HostAcceptDialogImageContent = ({ title, profileImagePathList }) => {
  return (
    <>
      <DialogContentTitle>[ {title} ]</DialogContentTitle>
      <ImageList cols={3}>
        {profileImagePathList.map((path) => (
          <ImageListItem key={path}>
            <ProfileImage src={path} alt={'Not Found'} />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};

const DialogContentTitle = styled.p`
  color: lightslategray;
  font-size: small;
  font-weight: bold;
  margin-bottom: 1%;
`;

const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
`;

export default HostAcceptDialogImageContent;
