import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { TableBody } from '@material-ui/core';

import FlexWrap from '@/components/common/FlexWrap';
import Typography from '@/components/common/Typography';
import LatestRefundList from '@/components/medical/home/LatestRefundList';
import LatestTable from '@/components/medical/home/LatestTable';
import { homeColumnList } from '@/constants/medical';
import { ILatestRefundInfoItem } from '@/types/api/medical';
import store from '@/store';

type LeastesRefundDetailType = {
  data: ILatestRefundInfoItem[];
};

function LeastestRefundDetail({ data }: LeastesRefundDetailType) {
  const history = useHistory();
  const { refund } = homeColumnList;
  const serverState = store.getState().serverState;
  return (
    <>
      <TitleWrap justifyContent="space-between">
        <Typography
          fontSize="20px"
          lineHeight="28px"
          color="#212121"
          margin="0 0 28px 0"
          fontWeight="500"
        >
          최근 환급 내역
        </Typography>
        <MoreBtn
          onClick={() =>
            history.push(`/refund/medical/${serverState}/refund-status`)
          }
        >
          + 더보기
        </MoreBtn>
      </TitleWrap>
      <LatestTable
        columns={refund}
        contentComponent={
          <TableBody>
            {data.map((item, idx) => (
              <LatestRefundList key={idx} data={item} />
            ))}
          </TableBody>
        }
      />
    </>
  );
}

const TitleWrap = styled(FlexWrap)``;

const MoreBtn = styled.button`
  color: #757575;
  margin-top: 20px;
`;
export default LeastestRefundDetail;
