import React, { ChangeEventHandler, Dispatch, useState } from 'react';
import styled, { css } from 'styled-components';
import { format } from 'date-fns';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { attachedSlash } from '@/utils/ktp';
import FlexWrap from '@components/common/FlexWrap';
import ActionBtn from '@components/common/ActionBtn';

const useStyles = makeStyles((theme) => ({
  FormControl: {
    width: 100,
    textAlign: 'center',
    marginRight: 24,
  },
  DateControl: {
    width: 140,
    fontSize: 14,
    borderBottom: 0,
  },
  Button: {
    padding: 2,
    marginLeft: 16,
  },
  Select: {
    fontSize: 14,
  },
}));

interface IProps {
  dateSelector: 'ALL' | 'CUSTOM';
  dispatch: Dispatch<any>;
}
function DateSelector({ dateSelector, dispatch }: IProps) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleStartDateChange:
    | ChangeEventHandler<HTMLInputElement>
    | undefined = (e) => {
    setStartDate(attachedSlash(e.target.value));
  };
  const handleEndDateChange:
    | ChangeEventHandler<HTMLInputElement>
    | undefined = (e) => {
    setEndDate(attachedSlash(e.target.value));
  };

  const onChange = (e: any) => {
    dispatch({ type: 'SET_DATESELECTOR', payload: e.target.value });
    if (e.target.value === 'ALL') {
      dispatch({
        type: 'SET_FILTER_DATE',
        payload: {
          startDate: '20/01/01',
          endDate: format(new Date(), 'yy/MM/dd'),
        },
      });
      setStartDate('');
      setEndDate('');
    }
  };
  const onApply = () => {
    if (!startDate || startDate.length !== 8) {
      alert('시작일을 정확히 입력해주세요.');
      return;
    }

    if (!endDate || endDate.length !== 8) {
      alert('종료일을 정확히 입력해주세요.');
      return;
    }

    dispatch({ type: 'SET_FILTER_DATE', payload: { startDate, endDate } });
  };
  const classes = useStyles();

  return (
    <DateContainer>
      <FormControl className={classes.FormControl}>
        <Select
          className={classes.Select}
          value={dateSelector}
          onChange={onChange}
        >
          <MenuItem className={classes.Select} value="ALL">
            전체기간
          </MenuItem>
          <MenuItem className={classes.Select} value="CUSTOM">
            기간선택
          </MenuItem>
        </Select>
      </FormControl>
      {dateSelector === 'CUSTOM' && (
        <FlexWrap margin="0 20px 0 0">
          <StyledInput
            placeholder="YY/MM/DD"
            value={startDate}
            onChange={handleStartDateChange}
            maxLength={8}
          />
          <StyledText>~</StyledText>
          <StyledInput
            placeholder="YY/MM/DD"
            value={endDate}
            onChange={handleEndDateChange}
            maxLength={8}
          />
          <ActionBtn
            size="small"
            title="적용"
            onClick={onApply}
            height="26px"
            margin="0 0 0 8px"
          />
        </FlexWrap>
      )}
    </DateContainer>
  );
}
const DateContainer = styled(FlexWrap)`
  justify-content: flex-end;
  align-items: center;
`;
const StyledText = styled.span`
  margin: 0 10px;
`;
const StyledInput = styled.input`
  width: 100px;
  padding: 4px 8px;
  border: 1px solid lightgray;
`;

export default DateSelector;
