import React from 'react';
import styled from 'styled-components';
import FlexWrap from '../common/FlexWrap';
import useGetRequestPath from '@/hooks/common/useGetRequestPath';

type Props = {
  size: number;
  setSize: React.Dispatch<React.SetStateAction<number>>;
};

function QnaSize({ size, setSize }: Props) {
  const isMedical = useGetRequestPath(/medical/);

  return (
    <ViewContainer isMedical={!!isMedical}>
      <Select value={size} onChange={(e) => setSize(Number(e.target.value))}>
        <option value="10">10개씩 보기</option>
        <option value="100">100개씩 보기</option>
        <option value="500">500개씩 보기</option>
      </Select>
    </ViewContainer>
  );
}

export default QnaSize;

const ViewContainer = styled(FlexWrap)<{ isMedical?: boolean }>`
  margin: ${(props) => (props.isMedical ? '0' : '25px 0')};
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

const Select = styled.select`
  padding: 4px 8px;
`;
