import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import styled from 'styled-components';

import RefundList from './RefundList';
import {
  getRefundListByfranchisee,
  getRefundListByfranchiseePaging,
} from '../../../api/ktp';
import TotalRefundList from './TotalRefundList';
import DateSelector from '@/components/common/DateSelector';
import Modal from '@/components/common/Modal';
import FlexWrap from '@/components/common/FlexWrap';
import Paging from '@/components/common/Paging';
import ViewDateSelector from '@/components/common/ViewDateSelector';

const initialState = {
  dateSelector: 'ALL',
  filter: {
    startDate: '20/01/01',
    endDate: format(new Date(), 'yy/MM/dd'),
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_FILTER_DATE':
      return {
        ...state,
        filter: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    case 'SET_DATESELECTOR':
      return {
        ...state,
        dateSelector: action.payload,
      };
    default:
      throw Error(`Unhandled Type Error: ${action.type}`);
  }
}

function RefundListModal({ onClose, selected }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { filter, dateSelector } = state;
  const [totalPage, setTotalPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [size, setSize] = useState(10);
  const pages = { size, page: pageNumber };
  const { franchiseeIndex, storeName } = selected;
  useEffect(() => {
    setPageNumber(0);
  }, [filter]);

  const { data: pagingDatas } = useQuery(
    ['refundListPaging', franchiseeIndex, filter, pages],
    () => getRefundListByfranchiseePaging(franchiseeIndex, filter, pages),
    {
      retry: false,
      onSuccess: (data) => {
        setTotalPage(data.totalPages);
      },
    },
  );
  const { isLoading, data, isSuccess, isError } = useQuery(
    ['refundList', franchiseeIndex, filter],
    () => getRefundListByfranchisee(franchiseeIndex, filter),
    {
      retry: false,
    },
  );
  const onChange = (value) => {
    setSize(value);
  };
  const onModalClose = useCallback(() => {
    onClose('REFUND');
  }, [onClose]);

  const columnsRefundList = [
    'No',
    '환급상태',
    '환급일자',
    '판매금액',
    '환급금액',
    '고객결제금액',
  ];
  const columnsTotalRefund = [
    '환급건수',
    '환급취소건수',
    '총상품금액',
    '환급액',
    '결제금액',
  ];
  return (
    <Modal
      onClose={onModalClose}
      error={
        isError && '환급 현황 조회에 실패했습니다. 관리자에게 문의해주세요.'
      }
      isLoading={isLoading}
    >
      {isSuccess && (
        <>
          <FlexWrap style={{ width: '100%' }} justifyContent="flex-end">
            <DateSelector dateSelector={dateSelector} dispatch={dispatch} />
          </FlexWrap>
          <Title>{`${storeName} 환급현황`}</Title>
          <TableContainer style={{ marginBottom: 30 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {columnsTotalRefund.map((column) => (
                    <TableCell
                      key={column}
                      align="center"
                      style={{
                        backgroundColor: '#f1f3f5',
                        padding: 5,
                        fontSize: 13,
                      }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TotalRefundList totalData={data.totalRefundData} />
            </Table>
          </TableContainer>
          <TableContainer style={{ marginBottom: 30 }}>
            <ViewDateSelector size={size} onChangeView={onChange} />
            <Table>
              <TableHead>
                <TableRow>
                  {columnsRefundList.map((column) => (
                    <TableCell
                      key={column}
                      align="center"
                      style={{
                        backgroundColor: '#f1f3f5',
                        padding: 5,
                        fontSize: 13,
                      }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {pagingDatas && <RefundList refundData={pagingDatas?.content} />}
            </Table>
            {pagingDatas && (
              <Paging
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalPage={totalPage}
              />
            )}
          </TableContainer>
        </>
      )}
    </Modal>
  );
}
const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 16px;
`;
export default React.memo(RefundListModal);
