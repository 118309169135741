import FlexWrap from '@/components/common/FlexWrap';
import React from 'react';
import { SubTitle, TransferContainer } from './DetailContent';
import { TransferInput } from './RemittanceStatusRadio';

type Props = {
  detailRefundInfo: any;
  isEdit: boolean;
  setData: React.Dispatch<React.SetStateAction<unknown>>;
};

const PAYMENT_WAY_LIST = [
  {
    name: '즉시환급',
    value: false,
  },
  {
    name: '사후환급',
    value: true,
  },
];

function PaymentWayRadio({ detailRefundInfo, isEdit, setData }: Props) {
  const onChangeNeedFullPayment = (paymentStatus: boolean) => {
    setData((prev: any) => {
      return {
        ...prev,
        detailRefundInfo: {
          ...prev.detailRefundInfo,
          needFullPayment: paymentStatus,
        },
      };
    });
  };

  return (
    <FlexWrap alignItems="center">
      <SubTitle>결제 방식</SubTitle>
      <TransferContainer dir="row" gap="20px">
        {PAYMENT_WAY_LIST.map((payment) => {
          return (
            <FlexWrap>
              <TransferInput
                type="radio"
                checked={!detailRefundInfo.needFullPayment}
                onChange={() => onChangeNeedFullPayment(payment.value)}
                disabled={!isEdit}
                isRadio
              />
              <label>{payment.name}</label>
            </FlexWrap>
          );
        })}
      </TransferContainer>
    </FlexWrap>
  );
}

export default PaymentWayRadio;
