import React, { KeyboardEvent, useRef } from 'react';
import Field from './Field';
import styled from 'styled-components';
import 'react-datetime/css/react-datetime.css';
import { TextField } from '@material-ui/core';
import { FieldValues, UseFormRegister } from 'react-hook-form';

type Props<T extends FieldValues> = {
  register: ReturnType<UseFormRegister<T>>;
  label: string;
  watch: (name: keyof T) => any;
};

function DatePicker<T extends FieldValues>({ register, label }: Props<T>) {
  const containerRef = useRef(null);

  const onEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Container ref={containerRef}>
      <Field label={label}>
        <TextField {...register} type="date" onKeyDown={onEnter} />
      </Field>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
`;

export default DatePicker;
