import { TableBody } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import RemittanceStatusItem from './RemittanceStatusItem';
export type DatasType = {
  refundId: number;
  refundDate: string;
  storeName: string;
  nation: string;
  customerName: string;
  refundAmount: number;
  infoTypedDate: string;
  departureDate: string;
  departureStatus: string;
  uploadImagePath: string;
  paymentStatus: string;
  mailSentCount: number;
  accountInfo: string;
  isChecked?: boolean;
};

type RemittanceStatusListProps = {
  datas: DatasType[];
  setRemittanceDatas: Dispatch<SetStateAction<DatasType[]>>;
  onOpenModal: (e: any, value: number, status: string) => void;
  transferView: string;
};

function RemittanceStatusList({
  datas,
  setRemittanceDatas,
  onOpenModal,
  transferView,
}: RemittanceStatusListProps) {
  return (
    <TableBody>
      {datas?.map((data: any, idx: number) => (
        <RemittanceStatusItem
          key={idx}
          idx={idx}
          data={data}
          datas={datas}
          setRemittanceDatas={setRemittanceDatas}
          onOpenModal={onOpenModal}
          transferView={transferView}
        />
      ))}
    </TableBody>
  );
}

export default RemittanceStatusList;
