import React, { useState, useEffect, FormEventHandler } from 'react';
import styled from 'styled-components';
import { Container } from '@material-ui/core';
import { useQuery } from 'react-query';
import { getFilteredFranchiseeLists, getSearchKeywords } from '@/api/ktp';

import FranchiseeStatusList from '@components/ktp/franchisee-status/FranchiseeStatusList';
import RefundListModal from '@components/ktp/franchisee-status/RefundListModal';
import DetailModal from '@components/ktp/franchisee-status/DetailModal';
import SignUpModal from '@components/ktp/franchisee-status/SignUpModal';
import CheckboxForm from '@components/common/CheckboxForm';
import PageIndicator from '@components/common/PageIndicator';
import SearchBox from '@components/common/SearchBox';
import store from '@/store';
import { franchiseeStatusData } from '@constants/ktp';
import DataTable from '@components/common/DataTable';
import useFranchiseeReducer from '@hooks/ktp/useFranchiseeReducer';
import Title from '@components/common/Title';
import ActionBtn from '@components/common/ActionBtn';
import LoadingView from '@/components/common/LoadingView';
import GlobalSignUpModal from '@/components/ktp/franchisee-status/GlobalSignUpModal';

function FranchiseeStatus() {
  const [state, dispatch] = useFranchiseeReducer();
  const { modalType, selected, toggle, filter } = state;
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageList, setPageList] = useState<Array<number>>([0]);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const { serverState } = store.getState();
  const { columns, franchiseeStatusRadio, isReadRadio } = franchiseeStatusData;

  useEffect(() => {
    setTotalPage(0);
  }, [serverState, searchKeyword]);

  const {
    isLoading,
    data: franchiseeData,
    isSuccess,
  } = useQuery(
    ['franchiseeList', filter, currentPage, searchKeyword],
    () => getFilteredFranchiseeLists(filter, currentPage, searchKeyword),
    {
      onSuccess: (data) => {
        if (currentPage === 0) {
          setTotalPage(data.totalPages);
          setPageList(
            data.totalPages < 4
              ? Array.from({ length: data.totalPages + 1 }, (v, i) => i)
              : [0, 1, 2, 3, 4],
          );
        }
      },
      retry: false,
    },
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [totalPage]);

  const { data: keywords } = useQuery('keywords', getSearchKeywords, {
    retry: false,
  });
  const onOpen = (
    type: 'DETAIL' | 'REFUND' | 'CREATE' | 'CREATE_GLOBAL',
    selected: any,
  ) => {
    dispatch({
      type: 'OPEN_MODAL',
      payload: { type, selected },
    });
  };

  const onClose = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const onFilter: FormEventHandler<HTMLFieldSetElement> | undefined = (e) => {
    const { name, value } = e.target as any;
    dispatch({ type: 'SET_TOGGLE', payload: null });
    dispatch({
      type: 'SET_FILTER',
      payload: { ...filter, [name]: value },
    });
    setCurrentPage(0);
    setPageList([0, 1, 2, 3, 4]);
  };
  const onCreate = () => {
    onOpen('CREATE', null);
  };
  const onCreateGlobal = () => {
    onOpen('CREATE_GLOBAL', null);
  };

  if (isLoading) {
    return <LoadingView />;
  }

  if (!franchiseeData) {
    return <></>;
  }

  return (
    <StyledContainer maxWidth="xl">
      <Title>가맹점 현황</Title>
      {keywords && (
        <SearchBox
          searchKeyword={searchKeyword}
          data={keywords}
          setSearchKeyword={setSearchKeyword}
          setCurrentPage={setCurrentPage}
        />
      )}
      {franchiseeData && (
        <DataTable
          columns={columns}
          dispatch={dispatch}
          toggle={toggle}
          contentComponent={
            <FranchiseeStatusList
              franchisees={franchiseeData.content}
              onOpen={onOpen}
            />
          }
        />
      )}
      {isSuccess && franchiseeData.content?.length === 0 && (
        <ContentContainer>가맹점 목록이 존재하지 않습니다.</ContentContainer>
      )}
      {modalType === 'REFUND' && (
        <RefundListModal onClose={onClose} selected={selected} />
      )}
      {modalType === 'DETAIL' && (
        <DetailModal onClose={onClose} selected={selected} />
      )}
      {modalType === 'CREATE' && <SignUpModal onClose={onClose} />}
      {modalType === 'CREATE_GLOBAL' && <GlobalSignUpModal onClose={onClose} />}
      {toggle && (
        <CheckboxForm
          valueArray={toggle === 'isRead' ? isReadRadio : franchiseeStatusRadio}
          defaultValue={filter[toggle]}
          type={toggle}
          onFilter={onFilter}
        />
      )}
      <ActionBtn
        title="회원 계정 생성 및 가맹점 신청"
        onClick={onCreate}
        size="big"
        margin="20px 0 0"
        right="70px"
      />
      <ActionBtn
        title="해외 간편 결제 계정 생성"
        bgColor="#FFF"
        color="#354eff"
        outlineColor="#354eff"
        onClick={onCreateGlobal}
        size="big"
        margin="20px 0 0"
        right="290px"
      />
      {pageList && (
        <PageIndicator
          totalPage={totalPage}
          pageList={pageList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setPageList={setPageList}
        />
      )}
    </StyledContainer>
  );
}
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  color: gray;
  font-size: 16px;
`;
export default FranchiseeStatus;
