import styled from 'styled-components';
import AutoResizeTextarea from '../../common/AutoResizeTextarea';
import FormItemLayout from './FormItemLayout';

function SubFormItem({
  state,
  order,
  onChangeInput,
  onChangeImage,
  isEditMode,
}) {
  return (
    <>
      <FormItemLayout label={`소제목 ${order}`}>
        <StyledInput
          name={`subTitle${order}`}
          onChange={onChangeInput}
          defaultValue={state[`subTitle${order}`]}
          placeholder="공지사항 소제목을 입력해주세요."
        />
      </FormItemLayout>
      <FormItemLayout label={`이미지 ${order}`}>
        <StyledInput
          type="file"
          name={`subImg${order}`}
          accept="image/*"
          onChange={onChangeImage}
          disabled={isEditMode}
        />
      </FormItemLayout>
      <FormItemLayout label={`내용 ${order}`} required={order === 1}>
        {isEditMode &&
          Boolean(state.content1 || state.content2 || state.content3) && (
            <AutoResizeTextarea
              name={`content${order}`}
              onChangeInput={onChangeInput}
              placeholder="공지사항 내용을 입력해주세요."
              text={state[`content${order}`]}
            />
          )}
        {!isEditMode && (
          <AutoResizeTextarea
            name={`content${order}`}
            onChangeInput={onChangeInput}
            placeholder="공지사항 내용을 입력해주세요."
            text={state[`content${order}`]}
          />
        )}
      </FormItemLayout>
    </>
  );
}
const StyledInput = styled.input`
  padding: 8px 12px;
  border: 1px solid lightgray;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  flex: 1;
  width: ${(props) => props.width}px;
`;
export default SubFormItem;
