import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Container, TableBody } from '@material-ui/core';
import { useQuery, useQueryClient } from 'react-query';

import { getPushLists } from '@api/ktp';
import { pushNotificationData } from '@constants/ktp';
import ListItem from '@components/ktp/push-notification/ListItem';
import AddOrDetailModal from '@components/ktp/push-notification/AddOrDetailModal';
import Title from '@components/common/Title';
import ActionBtn from '@components/common/ActionBtn';
import DataTable from '@components/common/DataTable';
import usePushReducer from '@hooks/ktp/usePushReducer';
import LoadingView from '@/components/common/LoadingView';

function PushNotification() {
  const queryClient = useQueryClient();
  const [state, dispatch] = usePushReducer();
  const { isModal, selectedData } = state as any;
  const { isLoading, data: pushLists } = useQuery('pushLists', () =>
    getPushLists(),
  );
  const { columns } = pushNotificationData;
  const onCloseModal = useCallback(() => {
    dispatch({ type: 'CLOSE_MODAL' });
    queryClient.removeQueries('pushDetail');
  }, []);

  const onOpenModal = useCallback((type, pushIndex) => {
    dispatch({ type: 'OPEN_MODAL', payload: { type, pushIndex } });
  }, []);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <>
      <StyledContainer maxWidth="xl">
        <Title>푸시 알림 관리</Title>
        <DataTable
          columns={columns}
          contentComponent={
            <TableBody>
              {pushLists?.responseList?.map((item: any, idx: number) => (
                <ListItem
                  key={item.pushIndex}
                  data={item}
                  idx={idx}
                  onOpenModal={() => onOpenModal('DETAIL', item.pushIndex)}
                />
              ))}
            </TableBody>
          }
        />
        {pushLists?.responseList?.length === 0 && (
          <ContentContainer>
            발송된 알림 메시지가 존재하지 않습니다.
          </ContentContainer>
        )}
        {isModal && (
          <AddOrDetailModal
            selectedData={selectedData}
            onCloseModal={onCloseModal}
          />
        )}
      </StyledContainer>
      <ActionBtn
        title="추가하기"
        onClick={() => onOpenModal('ADD', undefined)}
        margin="20px 0 0"
        right="70px"
        size="big"
      />
    </>
  );
}
const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  color: gray;
  font-size: 16px;
`;
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow-y: scroll;
  height: 70%;
`;
export default PushNotification;
