import styled from 'styled-components';
import { TableRow } from '@material-ui/core';

import CustomTableCell from '@/components/common/CustomTableCell';
import Typography from '@/components/common/Typography';
import { BillType, KioskDetailDepositInfo } from '@/types/api/kiosk';

type DataListProps = {
  data: KioskDetailDepositInfo;
  idx: number;
  billType: BillType;
};

function CashItem({ data, idx, billType }: DataListProps) {
  const { bd1, bd2, bd3, hp1 } = billType;

  return (
    <StyledTableRow idx={idx}>
      <CustomTableCell idx={0} width="120" fontSize="14px" fontWeight={500}>
        {data.createdDate.slice(0, 10)}
      </CustomTableCell>
      <CustomTableCell width="90" fontSize="14px" fontWeight={500}>
        {data.managerName}
      </CustomTableCell>
      <CustomTableCell
        width="120"
        fontSize="14px"
        fontWeight={500}
        color={bd1 ? '#80848A' : '#CBCCCE'}
      >
        {data.bd1Count > 0 && '+'}
        {data.bd1Count}
        <Typography>{` (${data.bd1TotalCount})`}</Typography>
      </CustomTableCell>
      <CustomTableCell
        width="120"
        fontSize="14px"
        fontWeight={500}
        color={bd2 ? '#80848A' : '#CBCCCE'}
      >
        {data.bd2Count > 0 && '+'}
        {data.bd2Count}
        <Typography>{` (${data.bd2TotalCount})`}</Typography>
      </CustomTableCell>
      <CustomTableCell
        width="120"
        fontSize="14px"
        fontWeight={500}
        color={bd3 ? '#80848A' : '#CBCCCE'}
      >
        {data.bd3Count > 0 && '+'}
        {data.bd3Count}
        <Typography>{` (${data.bd3TotalCount})`}</Typography>
      </CustomTableCell>
      <CustomTableCell
        width="120"
        fontSize="14px"
        fontWeight={500}
        color={hp1 ? '#80848A' : '#CBCCCE'}
      >
        {data.hp1Count > 0 && '+'}
        {data.hp1Count}
        <Typography>{` (${data.hp1TotalCount})`}</Typography>
      </CustomTableCell>
    </StyledTableRow>
  );
}

const StyledTableRow = styled(TableRow)<{ idx: number }>`
  background-color: #ffffff;
  border-bottom: ${(props) =>
    (props.idx + 1) % 5 === 0
      ? '2px solid rgb(220, 220, 220)'
      : '1px solid rgb(220, 220, 220)'};
  :hover {
    background-color: #f7f9fc;
  }
  span {
    font-weight: 500;
  }
`;

export default CashItem;
