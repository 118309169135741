import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Container, TableBody } from '@material-ui/core';
import { useQuery } from 'react-query';

import { getNoticeLists } from '@/api/ktp';
import ListItem from '@components/ktp/notice/ListItem';
import DetailModal from '@components/ktp/notice/DetailModal';
import AddModal from '@components/ktp/notice/AddModal';
import Title from '@components/common/Title';
import ActionBtn from '@components/common/ActionBtn';
import useNoticeReducer from '@hooks/ktp/useNoticeReducer';
import { NoticeData } from '@constants/ktp';
import DataTable from '@components/common/DataTable';
import LoadingView from '@/components/common/LoadingView';

function Notice() {
  const [state, dispatch] = useNoticeReducer();
  const { isModal, selectedData } = state as any;
  const { columns } = NoticeData;

  const { isLoading, data: noticeLists } = useQuery('noticeLists', () =>
    getNoticeLists(),
  );

  const onCloseModal = useCallback(() => {
    dispatch({ type: 'CLOSE_MODAL' });
  }, []);

  const onOpenModal = useCallback((type, noticeIndex) => {
    dispatch({ type: 'OPEN_MODAL', payload: { type, noticeIndex } });
  }, []);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <>
      <StyledContainer maxWidth="xl">
        <Title>공지사항</Title>
        <DataTable
          columns={columns}
          contentComponent={
            <TableBody>
              {noticeLists?.map((item: any, idx: number) => (
                <ListItem
                  key={item.noticeIndex}
                  data={item}
                  idx={idx}
                  onOpenModal={() => onOpenModal('DETAIL', item.noticeIndex)}
                />
              ))}
            </TableBody>
          }
        />
        {noticeLists?.length === 0 && (
          <ContentContainer>
            작성된 공지사항이 존재하지 않습니다.
          </ContentContainer>
        )}
        {isModal && selectedData.type === 'DETAIL' && (
          <DetailModal
            selectedData={selectedData}
            onCloseModal={onCloseModal}
          />
        )}
        {isModal && selectedData.type === 'ADD' && (
          <AddModal selectedData={selectedData} onCloseModal={onCloseModal} />
        )}
      </StyledContainer>
      <ActionBtn
        title="추가하기"
        onClick={() => onOpenModal('ADD', undefined)}
        margin="20px 0 0"
        right="70px"
        size="big"
      />
    </>
  );
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow-y: scroll;
  height: 70%;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  color: gray;
  font-size: 16px;
`;
export default Notice;
