import styled from 'styled-components';
import { Route, useRouteMatch } from 'react-router-dom';
import { Container } from '@material-ui/core';

import KioskStatusList from '@/components/kiosk/kioskStatus/KioskStatusList';
import KioskStatusDetail from '@/components/kiosk/kioskStatus/KioskStatusDetail';
import KioskChargingDetail from '@/components/kiosk/kioskStatus/KioskChargingDetail';
import KioskWithdrawalDetail from '@/components/kiosk/kioskStatus/KioskWithdrawalDetail';
import KioskErrorLogDetail from '@/components/kiosk/kioskStatus/KioskErrorLogDetail';

export type KioskStatusPropsType = {
  page: string;
  title: string;
  preTitle: string;
  prePage: string;
  kioskIndex: string;
};

function Home() {
  const { path } = useRouteMatch();
  return (
    <StyledContainer maxWidth="xl">
      <Route path={`${path}`} exact>
        <KioskStatusList />
      </Route>
      <Route path={`${path}/detail`}>
        <KioskStatusDetail />
      </Route>
      <Route path={`${path}/charging-detail`}>
        <KioskChargingDetail />
      </Route>
      <Route path={`${path}/withdrawal-detail`}>
        <KioskWithdrawalDetail />
      </Route>
      <Route path={`${path}/errorlog-detail`}>
        <KioskErrorLogDetail />
      </Route>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  padding-top: 16px;
`;

export default Home;
