import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';

import {
  deleteNotice,
  getNoticeDetailMedical,
  updateNotice,
} from '@/api/medical/index';
import {
  IGetNoticeDetailResponse,
  IUpdateNoticePayload,
} from '@/types/api/medical';

interface IProps {
  onCloseModal: () => void;
  noticeIndex: number;
}
function useNoticeDetail({ onCloseModal, noticeIndex }: IProps) {
  const { data: noticeData, isFetching } = useQuery<
    IGetNoticeDetailResponse,
    AxiosError
  >(['noticeDetail', noticeIndex], () => getNoticeDetailMedical(noticeIndex), {
    retry: false,
    staleTime: 10000000,
  });

  const updateMutation = useMutation<number, AxiosError, IUpdateNoticePayload>(
    (payload) => updateNotice(payload),
    {
      retry: false,
      onError: () =>
        alert('서버 요청에 실패하였습니다. 관리자에게 문의해주세요.'),
      onSuccess: (statusCode) => {
        if (statusCode === 200) {
          alert('정상적으로 처리되었습니다.');
          onCloseModal();
        }
      },
    },
  );
  const deleteMutation = useMutation<number, AxiosError, number>(
    (payload) => deleteNotice(payload),
    {
      retry: false,
      onSuccess: () => {
        alert('공지사항이 삭제되었습니다.');
        onCloseModal();
      },
      onError: () =>
        alert('서버 요청에 실패하였습니다. 관리자에게 문의해주세요.'),
    },
  );
  const onUpdateNotice = (payload: IUpdateNoticePayload) => {
    updateMutation.mutate(payload);
  };
  const onDeleteNotice = () => {
    deleteMutation.mutate(noticeIndex);
  };
  return {
    noticeData,
    isFetching,
    onUpdateNotice,
    onDeleteNotice,
  };
}

export default useNoticeDetail;
