import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { TableCell, TableRow, Checkbox } from '@material-ui/core';

const StyledTableRow = styled(TableRow)`
  ${(props) =>
    props.isInUsed
      ? css`
          cursor: no-drop;
        `
      : css`
          cursor: pointer;
          &:hover {
            background-color: gainsboro;
          }
        `}
`;

// REAPPLIED | REJECTED | WAIT | ACCEPTED | CANCEL
const ControlCodeItem = (props) => {
  const { controlCode, onClick, selectedCode } = props;
  const { valueCode, nameCode, immYn, afterYn, codeStatus } = controlCode;

  const onClickExtend = useCallback(() => {
    onClick(controlCode);
  }, [onClick, controlCode]);

  return (
    <StyledTableRow onClick={onClickExtend} isInUsed={codeStatus === 'IN_USED'}>
      <TableCell component={'th'} align="center">
        {codeStatus === 'IN_USED' ? (
          <Checkbox color="primary" disabled={codeStatus === 'IN_USED'} />
        ) : (
          <Checkbox
            color="primary"
            checked={selectedCode?.valueCode === valueCode}
          />
        )}
      </TableCell>
      <TableCell align="center">{valueCode}</TableCell>
      <TableCell align="center">{nameCode}</TableCell>
      <TableCell align="center">{immYn === '1' ? '가능' : '불가'}</TableCell>
      <TableCell align="center">{afterYn === '1' ? '가능' : '불가'}</TableCell>
      <TableCell align="center">
        {codeStatus === 'UN_USED' ? '가능' : '불가'}
      </TableCell>
    </StyledTableRow>
  );
};

export default ControlCodeItem;
