import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import DetailContent from './DetailContent';
import AcceptFranchisee from './AcceptFranchisee';

import {
  getFranchiseeDetail,
  updateIsRead,
  updateRejectMessage,
} from '../../../api/ktp';
import ActionBtn from '@/components/common/ActionBtn';
import Modal from '@/components/common/Modal';
import FlexWrap from '@/components/common/FlexWrap';

function DetailModal({ onClose, selected }) {
  const {
    franchiseeIndex,
    franchiseeStatus,
    isRead,
    createdDate,
    globalPayOnly,
  } = selected;
  const {
    isLoading: isFetchLoading,
    data,
    isError,
    isSuccess,
  } = useQuery(['franchiseeDetail', franchiseeIndex], () =>
    getFranchiseeDetail(franchiseeIndex),
  );

  const [acceptView, setAcceptView] = useState(false);
  const queryClient = useQueryClient();
  const [active, setActive] = useState(
    ['REJECTED', 'ACCEPTED', 'INIT'].indexOf(franchiseeStatus) === -1,
  );

  const updateIsReadMutation = useMutation(updateIsRead, {
    onError: (err) => {
      console.log(err);
      alert(`읽음 상태 변경에 실패하였습니다. ${err.message}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('franchiseeList');
    },
  });

  const rejectMutation = useMutation(updateRejectMessage, {
    onError: (err) => {
      console.log(err);
      alert(`가맹 신청 거절에 실패하였습니다. ${err.message}`);
    },
    onSuccess: () => {
      alert('가맹 신청 거절이 정상 처리되었습니다.');
      queryClient.invalidateQueries('frnachiseeList');
    },
  });

  useEffect(() => {
    isRead === false && updateIsReadMutation.mutate(franchiseeIndex);
  }, []);

  const onClickApproval = useCallback(() => {
    setAcceptView(true);
  }, [data]);

  const onModalClose = useCallback(() => {
    onClose('DETAIL');
  }, [onClose]);

  const onReject = async () => {
    const isOK = window.confirm('정말 거절하시겠습니까?');
    isOK && rejectMutation.mutate({ franchiseeIndex, rejectReason: '' });
    setActive(false);
  };

  return (
    <Modal
      onClose={onModalClose}
      isLoading={isFetchLoading}
      error={
        isError && '가맹 상세보기 조회에 실패했습니다. 관리자에게 문의해주세요.'
      }
    >
      {isSuccess && (
        <Wrapper>
          {acceptView ? (
            <AcceptFranchisee
              onCancel={setAcceptView}
              selected={selected}
              setActive={setActive}
              balancePercentage={data.detailBalanceInfo.balancePercentage}
            />
          ) : (
            <>
              <DetailContent
                data={data}
                franchiseeIndex={franchiseeIndex}
                franchiseeStatus={franchiseeStatus}
                createdDate={createdDate}
                setActive={setActive}
                globalPayOnly={globalPayOnly}
              />
              <BtnContainer gap="10px">
                <ActionBtn
                  active={active}
                  outlineColor="#E42939"
                  color="#E42939"
                  bgColor="#ffffff"
                  onClick={onReject}
                  title="거절"
                />
                <ActionBtn
                  active={active}
                  onClick={onClickApproval}
                  title="승인"
                />
              </BtnContainer>
            </>
          )}
        </Wrapper>
      )}
    </Modal>
  );
}
const Wrapper = styled.div`
  width: 100%;
`;
const BtnContainer = styled(FlexWrap)`
  justify-content: flex-end;
  margin-left: auto;
`;
export default React.memo(DetailModal);
