import React, { RefObject, useEffect, useRef, useState } from 'react';
import Field from './Field';
import styled from 'styled-components';
import ArrowDropDown from '../../../assets/common/arrow_drop_down.png';
import Portal from '@/components/common/Portal';
import StatusLabel from '../StatusLabel';
import { labelOption } from '@/types/sales.types';
import { FieldValues, UseFormRegister } from 'react-hook-form';

type DropdownProps<T extends FieldValues> = {
  register: ReturnType<UseFormRegister<T>>;
  label: string;
  options: labelOption[];
  watch: (name: keyof T) => any;
  container?: RefObject<HTMLDivElement>;
};

function Dropdown<T extends FieldValues>({
  register,
  label,
  options,
  container,
  watch,
}: DropdownProps<T>) {
  const containerRef = useRef(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [position, setPosition] = useState<'top' | 'bottom'>('top');
  const [selectedOption, setSelectedOption] = useState<labelOption>();

  const onTogglePopover = () => {
    return setIsOpenPopover((prev) => !prev);
  };

  const onEnter = (
    event: React.KeyboardEvent<HTMLDivElement>,
    value: string,
  ) => {
    if (event.key === 'Enter') {
      register.onChange({
        target: {
          value: value,
          name: register.name,
        },
      });
      setIsOpenPopover(false);
      setSelectedOption(options.find((v) => v.value === value));
    }
  };

  useEffect(() => {
    if (isOpenPopover && container?.current && popoverRef.current) {
      const { bottom: popoverBottom } =
        popoverRef.current.getBoundingClientRect();
      const { bottom: containerBottom } =
        container.current.getBoundingClientRect();
      const isOverflow = popoverBottom > containerBottom;
      if (isOverflow) {
        setPosition('bottom');
      }
    }
  }, [isOpenPopover, container, popoverRef]);

  useEffect(() => {
    const value = watch(register.name);
    const option = options.find((v) => v.value === value);
    setSelectedOption(option);
  }, [register, watch, options]);

  return (
    <Container ref={containerRef}>
      <Field label={label}>
        <Selector>
          {selectedOption && (
            <StatusLabel
              color={selectedOption.color}
              backgroundColor={selectedOption.backgroundColor}
              width={selectedOption.width}>
              {selectedOption.label}
            </StatusLabel>
          )}
          <Button type="button" onClick={onTogglePopover}>
            <ArrowImage
              src={ArrowDropDown}
              width="24px"
              height="24px"
              alt={`${label} 옵션 ${isOpenPopover ? '닫기' : '열기'}`}
              isOpenPopover={isOpenPopover}
            />
          </Button>
        </Selector>
      </Field>

      {isOpenPopover && (
        <Portal
          container={containerRef}
          onClickBackdrop={() => setIsOpenPopover(false)}>
          <Popover ref={popoverRef} position={position}>
            {options.map(({ value, label: optionLabel, ...styles }) => (
              <Radio
                key={value}
                tabIndex={0}
                onKeyDown={(e) => onEnter(e, value)}>
                <RadioInput
                  type="radio"
                  {...register}
                  onClick={() => setIsOpenPopover(false)}
                  value={value}
                  id={`${label}-${value}`}
                />
                <Label htmlFor={`${label}-${value}`}>
                  <StatusLabel {...styles}>{optionLabel}</StatusLabel>
                </Label>
              </Radio>
            ))}
          </Popover>
        </Portal>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Selector = styled.div`
  display: flex;
  gap: 16px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Popover = styled.aside<{ position: 'top' | 'bottom' }>`
  position: absolute;
  right: 4px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 24px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 0px 30px 0px #00000014;
  z-index: 50;

  ${({ position = 'top' }) => `${position}: calc(100% + 4px)`}
`;

const ArrowImage = styled.img<{ isOpenPopover: boolean }>`
  transform: ${({ isOpenPopover }) =>
    `rotate(${isOpenPopover ? '180deg' : '0deg'})`};
  transition: transform 200ms ease-in-out;
`;

const Radio = styled.div`
  position: relative;

  &:focus-within {
    outline: 2px solid #4d90fe;
    outline-offset: 2px;
  }
`;

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
`;

const Label = styled.label``;

export default Dropdown;
