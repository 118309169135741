import { add } from 'date-fns';
import { useState } from 'react';
import { useMutation } from 'react-query';
import styled, { css } from 'styled-components';
import { TextField } from '@material-ui/core';

import { refundApproval } from '../../../api/ktp';
import { comma, getRefundAmount, removeComma } from '../../../utils/ktp';
import ActionBtn from '@/components/common/ActionBtn';
import FlexWrap from '@/components/common/FlexWrap';
import Typography from '@/components/common/Typography';
import debounce from '@/utils/debounce.util';

type RefundSectionProps = {
  franchiseeIndex: number;
  franchiseeStatus: string;
};
function RefundSection({
  franchiseeIndex,
  franchiseeStatus,
}: RefundSectionProps) {
  const initialRefundData = {
    franchiseeIndex,
    price: '',
    refund: '',
    name: '',
    nationality: '',
    passportNumber: '',
    createdDate: '',
    forceInsert: false,
  };
  const [refundData, setRefundData] = useState(initialRefundData);
  const {
    price,
    refund,
    name,
    nationality,
    passportNumber,
    createdDate,
    forceInsert,
  } = refundData;
  const refundMutation = useMutation<any, any, any>(
    (data) => refundApproval(data),
    {
      onSuccess: () => {
        alert('환급이 완료되었습니다.');
        setRefundData(initialRefundData);
      },
      onError: (err) => {
        alert(`환급에 실패하였습니다.\n${err?.response?.data?.message}`);
      },
      retry: false,
    },
  );
  const onChanceRefundInput = (e: any) => {
    const { name, value } = e.target;
    if (name === 'price') {
      setRefundData((prev) => {
        return {
          ...prev,
          [name]: comma(removeComma(value)),
          refund: comma(getRefundAmount(+removeComma(value))),
        };
      });
    } else if (name === 'forceInsert') {
      setRefundData((prev) => {
        return { ...prev, forceInsert: !forceInsert };
      });
    } else {
      setRefundData((prev) => {
        return { ...prev, [name]: value.toUpperCase() };
      });
    }
  };
  const onRefund = (type: 'IMMEDIATE' | 'AFTER') => {
    if (franchiseeStatus !== 'ACCEPTED') {
      alert('가맹점 승인 후 사용해주세요.');
      return;
    }
    if (window.confirm('환급하시겠습니까?')) {
      refundMutation.mutate({
        type,
        body: {
          ...refundData,
          createdDate: createdDate || add(new Date(), { hours: 9 }),
          price: removeComma(price),
          refund: removeComma(refund),
        },
      });
    }
  };
  const createRefund = (type: 'IMMEDIATE' | 'AFTER') => {
    return debounce(() => onRefund(type), 200);
  };

  return (
    <>
      <Title>환급하기</Title>
      <StyledTable width="100%">
        <StyledTh>국적</StyledTh>
        <StyledTh>여권번호</StyledTh>
        <StyledTh>이름</StyledTh>
        <StyledTh>판매금액</StyledTh>
        <StyledTh>환급액</StyledTh>
        <StyledTh>생성일자</StyledTh>
        <StyledTr>
          <StyledTd isEditMode>
            <StyledInput
              disabled={false}
              name="nationality"
              value={nationality}
              onChange={onChanceRefundInput}
            />
          </StyledTd>
          <StyledTd isEditMode>
            <StyledInput
              disabled={false}
              name="passportNumber"
              value={passportNumber}
              onChange={onChanceRefundInput}
            />
          </StyledTd>
          <StyledTd isEditMode>
            <StyledInput
              disabled={false}
              name="name"
              value={name}
              onChange={onChanceRefundInput}
            />
          </StyledTd>
          <StyledTd isEditMode>
            <StyledInput
              disabled={false}
              name="price"
              value={price}
              onChange={onChanceRefundInput}
            />
          </StyledTd>
          <StyledTd>{refund}</StyledTd>
          <StyledTd isEditMode>
            <TextField
              id="datetime-local"
              onChange={onChanceRefundInput}
              name="createdDate"
              type="datetime-local"
              defaultValue={new Date()}
              value={createdDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </StyledTd>
        </StyledTr>
      </StyledTable>
      <FlexWrap justifyContent="flex-end" alignItems="center" gap="10px">
        <CheckBoxContainer>
          <CheckBox
            name="forceInsert"
            type="checkbox"
            checked={forceInsert}
            onChange={onChanceRefundInput}
          />
          <Typography>강제환급</Typography>
        </CheckBoxContainer>
        <ActionBtn
          title="즉시 환급"
          bgColor="#354eff"
          onClick={createRefund('IMMEDIATE')}
          active={Boolean(
            nationality !== 'KOR' &&
              nationality &&
              passportNumber &&
              name &&
              price &&
              !forceInsert,
          )}
        />
        <ActionBtn
          title="사후 환급"
          bgColor="#333333"
          onClick={createRefund('AFTER')}
          active={Boolean(nationality && passportNumber && name && price)}
        />
      </FlexWrap>
    </>
  );
}
const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 16px;
`;
const StyledInput = styled.input`
  color: black;
  border: none;
  outline: none;
  width: 100%;
  padding: 8px 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f2f2f2;
    `}
`;
const borderStyles = css`
  border-collapse: collapse;
  border: 1px solid black;
`;
const StyledTable = styled.table`
  ${borderStyles}
  margin: 0 0 40px;
`;
const StyledTd = styled.td<{ isEditMode?: boolean; disabled?: boolean }>`
  ${borderStyles}
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  background-color: ${(props) =>
    props.isEditMode ? (props.disabled ? '#f2f2f2' : 'white') : '#f2f2f2'};
  width: 350px;
`;
const StyledTh = styled.td`
  ${borderStyles}
  padding-left: 16px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  background-color: #d3d3d3;
  width: 190px;
  color: black;
`;

const StyledTr = styled.tr`
  ${borderStyles}
  padding: 8px 15px;
  font-size: 14px;
`;
const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CheckBox = styled.input`
  width: 18px;
  height: 18px;
`;
export default RefundSection;
