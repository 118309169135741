import DatePicker from './DatePicker';
import Dropdown from './Dropdown';
import Field from './Field';
import TextInput from './TextInput';

export const FormField = Object.assign(Field, {
  TextInput,
  Dropdown,
  DatePicker,
});
