import { useReducer } from 'react';

const initialState = {
  isOpenDetail: false,
  isOpenRefund: false,
  isOpenCreate: false,
  toggle: null,
  filter: { hospitalStatus: 'ALL' },
  selected: null,
};

function reducer(state: any, action: any): any {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        selected: action.payload.selected,
        ...(action.payload.type === 'DETAIL' && { isOpenDetail: true }),
        ...(action.payload.type === 'REFUND' && { isOpenRefund: true }),
        ...(action.payload.type === 'CREATE' && { isOpenCreate: true }),
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        selected: null,
        ...(action.payload.type === 'DETAIL' && { isOpenDetail: false }),
        ...(action.payload.type === 'REFUND' && { isOpenRefund: false }),
        ...(action.payload.type === 'CREATE' && { isOpenCreate: false }),
      };
    case 'SET_TOGGLE':
      return {
        ...state,
        toggle: action.payload,
      };
    case 'SET_FILTER':
      return {
        ...state,
        filter: action.payload,
      };
    default:
      throw Error(`Unhandled Type Error: ${action.type}`);
  }
}
const useKioskReducer = (): any => {
  return useReducer(reducer, initialState);
};
export default useKioskReducer;
