import React, { useCallback, useState } from 'react';

import SignUpContent from './SignUpContent';
import ApplicationContent from './ApplicationContent';
import Modal from '@/components/common/Modal';

function SignUpModal({ onClose }) {
  const [franchiseeIndex, setFranchiseeIndex] = useState();
  const onModalClose = useCallback(() => {
    onClose('CREATE');
  }, [onClose]);

  return (
    <Modal backDropClickNot onClose={onModalClose}>
      <SignUpContent
        setFranchiseeIndex={setFranchiseeIndex}
        franchiseeIndex={franchiseeIndex}
      />
      <ApplicationContent
        newFranchiseeIndex={franchiseeIndex}
        onClose={onModalClose}
      />
    </Modal>
  );
}

export default React.memo(SignUpModal);
