import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

const onFullfilled = (response: AxiosResponse) => {
  return response;
};
const onRejected = async (error: AxiosError) => {
  if (error.response?.status === 401) {
    localStorage.removeItem('loginToken');
    localStorage.removeItem('autoLogin');
    window.location.href = '/';
  }
  return Promise.reject(error);
};

const initialization = (config: AxiosRequestConfig): AxiosInstance => {
  const axiosInstance = axios.create(config);

  axiosInstance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('loginToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  axiosInstance.interceptors.response.use(onFullfilled, onRejected);

  return axiosInstance;
};

export default initialization;
