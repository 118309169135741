import { useState } from 'react';
import styled from 'styled-components';

import { bdOptions, hp1Options } from '@/constants/kiosk';
import FlexWrap from '@/components/common/FlexWrap';
import Typography from '@/components/common/Typography';
import LoadingView from '@/components/common/LoadingView';
import useUpdateKioskBillType from '@/hooks/kiosk/useUpdateKioskBillType';
import { formatBillType } from '@/utils/kiosk';
import { BillType, KioskLackCriteriaType } from '@/types/api/kiosk';
import CashBillSetting from './CashBillSetting';
import CashBillalarmSetting from './CashBillalarmSetting';

export type BillTypeInfo = {
  bd1: { value: null | number; label: string };
  bd2: { value: null | number; label: string };
  bd3: { value: null | number; label: string };
  hp1: { value: null | number; label: string };
};
type Props = {
  kioskIndex: number;
  prevBillType: BillType;
  kioskLackCriteria?: KioskLackCriteriaType;
};
function CashSetting({
  kioskIndex,
  prevBillType,
  kioskLackCriteria = { bd1: 0, bd2: 0, bd3: 0, hp1: 0 },
}: Props) {
  const initialBillType = ['bd1', 'bd2', 'bd3', 'hp1'].reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: {
        value: prevBillType[curr as keyof BillType],
        label: formatBillType(prevBillType[curr as keyof BillType]),
      },
    }),
    {},
  ) as BillTypeInfo;
  const [billTypeInfo, setBillTypeInfo] =
    useState<BillTypeInfo>(initialBillType);
  const [kioskLackCriteriaInfo, setKioskLackCriteriaInfo] =
    useState<KioskLackCriteriaType>(kioskLackCriteria);

  const alreadySelectedBillTypes = ['bd1', 'bd2', 'bd3', 'hp1'].filter(
    //@ts-ignore
    (item) => billTypeInfo[item].value !== null,
  );
  const { mutate, isLoading } = useUpdateKioskBillType();

  const onChange = (selectedBillType: any, id: string) => {
    setBillTypeInfo((prev) => ({ ...prev, [id]: selectedBillType }));
  };

  const onKioskLackCriteriaInfoChange = (selectedCriteria: any, id: string) => {
    setKioskLackCriteriaInfo((prev) => ({
      ...prev,
      [id]: Number(selectedCriteria),
    }));
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (alreadySelectedBillTypes.length < 2) {
      alert('최소 두가지 이상의 권종을 사용해야합니다.');
      return;
    }
    const formattedBillTypeInfo = Object.keys(billTypeInfo).reduce(
      // @ts-ignore
      (prev, curr) => ({ ...prev, [curr]: billTypeInfo[curr as any].value }),
      {},
    );

    mutate({
      kioskIndex,
      //@ts-ignore
      billType: formattedBillTypeInfo,
      kioskLackCriteria: kioskLackCriteriaInfo,
    });
  };

  return (
    <FlexWrap dir='column'>
      <Form>
        {isLoading ? (
          <LoadingView />
        ) : (
          <>
            <SelectContainer flexDirection='row'>
              <SelectContainer padding='34px 0px 0px 0px'>
                <Typography fontSize='16px' fontWeight='600' lineHeight='36px'>
                  권종 설정
                </Typography>
                <Typography fontSize='16px' fontWeight='600' lineHeight='36px'>
                  잔량 알림
                </Typography>
              </SelectContainer>
              <SelectContainer flexDirection='row' gap='48px'>
                <SelectContainer flexDirection='column' gap='12px'>
                  <CashBillSetting
                    id='bd1'
                    label='BD1'
                    options={bdOptions}
                    value={billTypeInfo['bd1']}
                    onChange={onChange}
                  />
                  <CashBillalarmSetting
                    id='bd1'
                    defaultValue={kioskLackCriteria.bd1}
                    onChange={onKioskLackCriteriaInfoChange}
                  />
                </SelectContainer>
                <SelectContainer flexDirection='column' gap='12px'>
                  <CashBillSetting
                    id='bd2'
                    label='BD2'
                    options={bdOptions}
                    value={billTypeInfo['bd2']}
                    onChange={onChange}
                  />
                  <CashBillalarmSetting
                    id='bd2'
                    defaultValue={kioskLackCriteria.bd2}
                    onChange={onKioskLackCriteriaInfoChange}
                  />
                </SelectContainer>
                <SelectContainer flexDirection='column' gap='12px'>
                  <CashBillSetting
                    id='bd3'
                    label='BD3'
                    options={bdOptions}
                    value={billTypeInfo['bd3']}
                    onChange={onChange}
                  />
                  <CashBillalarmSetting
                    id='bd3'
                    defaultValue={kioskLackCriteria.bd3}
                    onChange={onKioskLackCriteriaInfoChange}
                  />
                </SelectContainer>
                <SelectContainer flexDirection='column' gap='12px'>
                  <CashBillSetting
                    id='hp1'
                    label='HP1'
                    options={hp1Options}
                    value={billTypeInfo['hp1']}
                    onChange={onChange}
                  />
                  <CashBillalarmSetting
                    id='hp1'
                    defaultValue={kioskLackCriteria.hp1}
                    onChange={onKioskLackCriteriaInfoChange}
                  />
                </SelectContainer>
              </SelectContainer>
              <SelectContainer padding='32px 0px 0px 0px'>
                <Button onClick={onSubmit}>저장</Button>
              </SelectContainer>
            </SelectContainer>
          </>
        )}
      </Form>
    </FlexWrap>
  );
}
const Form = styled.form`
  display: flex;
  gap: 28px;
  padding: 20px;
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
`;
const SelectContainer = styled(FlexWrap)<{
  padding?: string;
  flexDirection?: string;
  gap?: string;
}>`
  align-items: center;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  gap: ${({ gap }) => gap || '12px'};
  padding: ${({ padding }) => padding || '0'};
`;
const Label = styled.label`
  margin-right: 16px;
`;
const Button = styled.button`
  padding: 8px 24px;
  border-radius: 8px;
  background-color: #354eff;
  color: #ffffff;
`;
export default CashSetting;
