import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import Logo from '@assets/common/logo_full.png';
import ErrorMessage from '@/components/common/ErrorMessage';
import FlexWrap from '@/components/common/FlexWrap';
import LoadingView from '@/components/common/LoadingView';
import { AxiosError } from 'axios';
import { ISignInPayload, ISignInResponse } from '@/types/api/ktp';
import { signIn } from '@/api/ktp';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';

interface IForm {
  username: string;
  password: string;
}

const SignIn = () => {
  const [error, setError] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAutoLogin, setIsAutoLogin] = useState(false);
  const history = useHistory();

  const { register, handleSubmit, watch, getValues } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const watchAllFields = watch();
  useEffect(() => {
    const { username, password } = getValues();
    username && password ? setIsActive(true) : setIsActive(false);
  }, [watchAllFields]);

  const loginMutation = useMutation<
    ISignInResponse,
    AxiosError,
    ISignInPayload
  >((payload) => signIn(payload), {
    retry: false,
    onSuccess: (res) => {
      if (isAutoLogin) {
        localStorage.setItem('autoLogin', 'true');
      }
      localStorage.setItem('loginToken', res.token);
      history.push('/ktp/prod/home');
    },
    onError: () => {
      setError('아이디 & 비밀번호가 틀립니다.');
    },
  });

  const onSubmit = ({ username, password }: IForm) => {
    const payload = {
      username,
      password,
    };
    loginMutation.mutate(payload);
  };
  useEffect(() => {
    setIsLoading(true);
    if (localStorage.getItem('autoLogin')) {
      history.push('/ktp/prod/home');
    }
    setIsLoading(false);
  }, []);
  return (
    <LoginContainer>
      {isLoading ? (
        <LoadingView />
      ) : (
        <LoginForm onSubmit={handleSubmit(onSubmit)}>
          <Image src={Logo} />
          <InputContainer>
            <StyledInput
              placeholder="아이디"
              autoFocus
              {...register('username', { required: true })}
            ></StyledInput>
          </InputContainer>
          <InputContainer>
            <StyledInput
              placeholder="비밀번호"
              type="password"
              {...register('password', { required: true })}
            ></StyledInput>
            {error && <ErrorMessage message={error} />}
          </InputContainer>
          <FlexWrap alignItems="center" gap="8px">
            <Checkbox
              type="checkbox"
              checked={isAutoLogin}
              onChange={() => setIsAutoLogin(!isAutoLogin)}
            />
            <Label>자동 로그인</Label>
          </FlexWrap>

          <Button active={isActive} disabled={!isActive}>
            로그인
          </Button>
        </LoginForm>
      )}
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img`
  width: 90%;
  margin-bottom: 30px;
`;
const LoginForm = styled.form`
  width: 355px;
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled(FlexWrap)`
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 55px;
  justify-content: flex-start;
`;
const StyledInput = styled.input`
  width: 100%;
  border: 1px solid #cbccce;
  ::placeholder {
    font-weight: 300;
  }
  border-radius: 4px;
  padding: 12px 15px;
  outline: none;
  font-size: 15px;
`;
const Checkbox = styled.input`
  width: 18px;
  height: 18px;
`;
const Label = styled.label`
  font-size: 14px;
  color: #757575;
`;
const Button = styled.button<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#354eff' : '#EDEEEF')};
  color: ${(props) => (props.active ? '#ffffff' : '#9FA1A7')};
  padding: 13px;
  font-size: 18px;
  border-radius: 4px;
  margin-top: 12px;
  :disabled {
    cursor: default;
  }
`;
export default SignIn;
