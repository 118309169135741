import { FilterType } from '@/types/sales.types';
import React, { KeyboardEvent } from 'react';
import styled from 'styled-components';

type Props<T extends FilterType> = {
  id: string;
  name: string;
  value: T;
  checked: boolean;
  onChange: (clickedValues: T) => void;
  label: string;
  isAllButton?: boolean;
  selectedAll?: boolean;
};

function MultiSelectionItem<T extends FilterType>({
  id,
  name,
  value,
  label,
  checked,
  onChange,
  isAllButton = false,
  selectedAll = false,
}: Props<T>) {
  const onEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onChange(value);
    }
  };

  return (
    <Button
      checked={checked}
      isAllButton={isAllButton}
      selectedAll={selectedAll}
    >
      <Input
        id={id}
        type='checkbox'
        name={name}
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
        onKeyDown={onEnter}
      />
      <Label htmlFor={id}>{label}</Label>
    </Button>
  );
}

const Button = styled.div<{
  checked: boolean;
  isAllButton: boolean;
  selectedAll: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isAllButton }) => (isAllButton ? '60px' : '100px')};
  height: 33px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  flex-shrink: 0;

  &:focus-within {
    outline: 2px solid #4d90fe;
    outline-offset: 2px;
  }

  ${({ checked, selectedAll = false }) =>
    checked && !selectedAll
      ? `
      border-color:#3A3B3E;
      color:#FFFFFF;
      background-color:#3A3B3E;
  `
      : `
    border-color:#E5E6E8;
      color:#A7A7A7;
      background-color:#FFFFFF;
  `}

  ${({ isAllButton }) =>
    isAllButton &&
    `
      margin-right: 24px;
      &:after {
        position: absolute;
        top: 50%;
        right: -13px;
        transform: translateY(-50%);
        content: '';
        width: 1px;
        height: 29px;
        background-color: #e5e6e8;
      }
  `}
`;

const Input = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`;

export default MultiSelectionItem;
