import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import Logo from '@assets/common/logo.png';
import { mainMenuArr, subMenuArr } from '@constants/ktp';
import FlexWrap from '@components/common/FlexWrap';
import store from '@/store';

function Header() {
  const history = useHistory();
  const { pathname } = useLocation();
  const [isActiveSubHeader, setIsActiveSubHeader] = useState<boolean>(false);
  const onNavigate = (path: string) => {
    history.push(path);
  };
  const queryClient = useQueryClient();

  const onClickSubMenu = (path: string) => {
    queryClient.clear();
    if (path.includes('prod')) {
      store.dispatch({ type: 'SET_SERVER_PROD' });
    } else if (path.includes('test')) {
      store.dispatch({ type: 'SET_SERVER_TEST' });
    }
    onNavigate(path);
    setIsActiveSubHeader(false);
  };
  const onHoverMainHeader = () => {
    setIsActiveSubHeader(true);
  };
  const onLeaveSubHeader = (e: any) => {
    if (e.target !== e.currentTarget) {
      return;
    }
    setIsActiveSubHeader(false);
  };

  return (
    <>
      <Container onMouseOver={onLeaveSubHeader}>
        <LogoWrapper
          gap="10px"
          alignItems="center"
          onClick={() => onNavigate('/ktp/prod/home')}
        >
          <LogoIcon src={Logo} />
          <LogoText>ADMIN</LogoText>
        </LogoWrapper>
        <MenuList>
          {mainMenuArr.map((item, idx) => (
            <MenuItem
              onMouseEnter={onHoverMainHeader}
              key={idx}
              onClick={onHoverMainHeader}
            >
              {item}
            </MenuItem>
          ))}
        </MenuList>
      </Container>
      <SubMenuContainer active={isActiveSubHeader} onClick={onLeaveSubHeader}>
        <SubMenu
          onMouseOut={onLeaveSubHeader}
          onMouseMove={onHoverMainHeader}
          active={isActiveSubHeader}
        >
          {subMenuArr.map((item, idx) => (
            <SubMenuList key={idx} isActiveSubHeader={isActiveSubHeader}>
              {item.map((item, index) => (
                <SubMenuItem
                  key={index}
                  onClick={() => onClickSubMenu(item.path)}
                  active={pathname === item.path}
                >
                  {item.title}
                </SubMenuItem>
              ))}
            </SubMenuList>
          ))}
        </SubMenu>
      </SubMenuContainer>
    </>
  );
}

const Container = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 80px;
  border-bottom: 1px solid lightgray;
  padding: 20px 40px;
  position: relative;
  background-color: #ffffff;
  z-index: 30;
  @media (max-width: 1700px) {
    height: 60px;
    padding: 0px 40px;
  }
`;
const LogoWrapper = styled(FlexWrap)`
  position: absolute;
  left: 40px;
  top: 16px;
  cursor: pointer;
  @media (max-width: 1700px) {
    top: 12px;
  }
`;
const LogoIcon = styled.img`
  width: 45px;
  height: 42px;
  @media (max-width: 1700px) {
    width: 38px;
    height: 34px;
  }
`;
const LogoText = styled.h1`
  font-size: 30px;
  line-height: 38px;
  color: #2f2f2f;
  @media (max-width: 1700px) {
    font-size: 24px;
    line-height: 28px;
  }
`;
const MenuList = styled.ul`
  display: flex;
  align-items: center;
`;
const MenuItem = styled.li`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2f2f2f;
  width: 200px;
  padding-left: 40px;
  padding-bottom: 30px;
  transform: translateY(15px);
`;
const SubMenuContainer = styled.div<{ active: boolean }>`
  background-color: ${(props) =>
    props.active ? 'rgba(0, 0, 0, 0.2)' : 'transparent'};
  z-index: ${(props) => (props.active ? 20 : -10)};
  position: absolute;
  top: 80px;
  width: 100%;
  height: 100%;
  @media (max-width: 1700px) {
    top: 60px;
  }
`;
const SubMenu = styled(FlexWrap)<{
  active: boolean;
  onMouseOut: (e: any) => void;
  onMouseMove: (e: any) => void;
}>`
  background-color: #ffffff;
  height: 325px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  transform: ${(props) =>
    props.active ? 'translateY(0)' : 'translateY(-325px)'};
  transition: all 200ms ease-in;
`;

const SubMenuList = styled.ul<{ isActiveSubHeader: boolean }>`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 40px;
  width: 200px;
  gap: 30px;
  transition: all 450ms ease-in;
  opacity: ${(props) => (props.isActiveSubHeader ? 1 : 0)};
`;
const SubMenuItem = styled.li<{ active: boolean }>`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: ${(props) => (props.active ? '#354eff' : '#2f2f2f')};
  cursor: pointer;
  :hover {
    color: #354eff;
  }
`;
export default Header;
