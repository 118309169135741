import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { TableRow } from '@material-ui/core';

import checkIcon from '@/assets/common/check.png';
import disableCheckIcon from '@/assets/common/disableCheckIcon.png';
import checkedIcon from '@/assets/common/checked.png';
import CustomTableCell from '@/components/common/CustomTableCell';
import Icon from '@/components/common/Icon';
import {
  comma,
  formattedDepartureStatus,
  formattedPaymentStatus,
} from '@/utils/ktp';
import { isFormattedKOR, isValidMailText } from '@/utils/medical';

function RemittanceStatusItem({
  data,
  datas,
  idx,
  setRemittanceDatas,
  onOpenModal,
}) {
  const {
    refundId,
    refundDate,
    storeName,
    nation,
    customerName,
    refundAmount,
    infoTypedDate,
    departureDate,
    departureStatus,
    paymentStatus,
    isChecked,
    inputMail,
    mailSentCount,
    expireDateLookupFailed,
    mailSavedDate,
    accountInfo,
    modifyNeededStatus,
    departureConfirmDate,
  } = data;

  const [isHkgNation, setIsHkgNation] = useState(false);

  useEffect(() => {
    let isHkg = false;
    try {
      const accountData = accountInfo ? JSON.parse(accountInfo) : null;
      isHkg =
        accountData && accountData?.isHkgNation
          ? accountData?.isHkgNation
          : false;
    } catch (error) {
      console.error('Error parsing accountInfo:', error);
    }
    setIsHkgNation(isHkg);
  }, []);

  const onClickChangeCheck = () => {
    if (paymentStatus === 'PAYMENT_COMPLETE') return;
    const newArray = datas.map((item, index) => {
      return idx === index ? { ...item, isChecked: !item.isChecked } : item;
    });
    setRemittanceDatas(newArray);
  };

  return (
    <StyledTableRow
      onClick={(e) => onOpenModal(e, refundId, modifyNeededStatus)}
    >
      <CustomTableCell>
        {modifyNeededStatus === 'MODIFIED' && <Dot />}
      </CustomTableCell>
      <CustomTableCell>
        <CheckBox
          id={`checkBox-${refundId}`}
          onClick={onClickChangeCheck}
          isDisable={paymentStatus === 'PAYMENT_COMPLETE'}
        >
          <Icon
            imgUrl={
              paymentStatus === 'PAYMENT_COMPLETE'
                ? disableCheckIcon
                : isChecked
                ? checkedIcon
                : checkIcon
            }
            width='20px'
            height='20px'
          />
        </CheckBox>
      </CustomTableCell>
      <CustomTableCell>{refundId}</CustomTableCell>
      <CustomTableCell>{refundDate.slice(0, 10)}</CustomTableCell>
      <CustomTableCell>{storeName}</CustomTableCell>
      <CustomTableCell
        color={(nation === 'KOR' || expireDateLookupFailed) && '#246CF6'}
      >
        {isHkgNation ? 'HKG' : nation}
      </CustomTableCell>
      <CustomTableCell>{customerName}</CustomTableCell>
      <CustomTableCell color={refundAmount > 430000 && '#246CF6'}>
        {comma(refundAmount)} 원
      </CustomTableCell>
      <CustomTableCell
        color={
          modifyNeededStatus === 'MODIFIED'
            ? '#246cf6'
            : !mailSavedDate && !infoTypedDate && '#A7A7A7'
        }
      >
        {(inputMail
          ? isValidMailText(nation, departureDate, mailSentCount)
          : '') + (infoTypedDate || mailSavedDate || '미입력')}
      </CustomTableCell>
      <CustomTableCell
        color={
          ((isFormattedKOR(nation, departureDate) || !departureDate) &&
            '#A7A7A7') ||
          (expireDateLookupFailed && '#246CF6')
        }
      >
        {departureDate || '미입력'}
        {expireDateLookupFailed && '*'}
      </CustomTableCell>
      <CustomTableCell
        color={departureStatus === 'DEPARTURE_WAIT' && '#A7A7A7'}
      >
        {departureConfirmDate ?? '출국 미확인'}
      </CustomTableCell>
      <CustomTableCell>{formattedPaymentStatus(paymentStatus)}</CustomTableCell>
    </StyledTableRow>
  );
}

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  background-color: #ffffff;
  :hover {
    background-color: #f7f9fc;
  }
`;
const CheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.isDisable &&
    css`
      cursor: default;
    `}
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  margin: auto;
  background-color: #246cf6;
  border-radius: 100%;
`;
export default RemittanceStatusItem;
