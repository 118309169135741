import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Container } from '@material-ui/core';
import Title from '@/components/common/Title';
import DataTable from '@/components/common/DataTable/DataTable';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import Search from '@/components/common/Search';
import Pagination from '@/components/common/Pagination';
import ViewDataSelection from '@/components/common/ViewDataSelection';
import {
  DEFAULT_FILTER,
  INQUIRY_HISTORY_TABLE_DATA,
  SalesOrderByType,
} from '@/constants/sales/data';
import {
  SALES_STATUS_LABEL_LIST,
  SERVICE_TYPE_LABEL_LIST,
  TAX_ENROLLMENT_LABEL_LIST,
  TODO_STATUS_LABEL_LIST,
} from '@/constants/sales/label';
import { History, Sort } from '@/types/sales.types';
import StatusLabel from '@/components/sales/StatusLabel';
import SalesModal from '@/components/sales/salesModal/SalesModal';
import {
  getExceedHistories,
  getKeywords,
  getTotalHistories,
} from '@/api/sales';
import SalesFilter from '@/components/sales/SalesFilter';
import useLockBodyScroll from '@/hooks/sales/useLockBodyScroll';
import useModal from '@/hooks/sales/useModal';

function FranchiseeInquiryHistory() {
  const { columns } = INQUIRY_HISTORY_TABLE_DATA;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialSearch = searchParams.get('search') || '';
  const initialPage = searchParams.get('page') || '0';
  const initialSize = searchParams.get('size') || '100';
  const initialSortedColumn = searchParams.get('sortedColumn') || '';
  const initialSortType = searchParams.get('sortType') || 'none';

  const [totalPage, setTotalPage] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [openedItem, setOpenedItem] = useState<number | undefined>();
  const [serviceTypeFilter, setServiceTypeFilter] = useState(
    DEFAULT_FILTER['serviceType'],
  );
  const [salesStatusFilter, setSalesStatusFilter] = useState(
    DEFAULT_FILTER['salesStatus'],
  );
  const [todoStatusFilter, setTodoStatusFilter] = useState(
    DEFAULT_FILTER['todoStatus'],
  );

  const { isOpenModal, openModal, closeModal } = useModal();
  useLockBodyScroll(isOpenModal);

  const { data: keywords } = useQuery(['keyword'], getKeywords, {
    retry: false,
  });

  const [historyData, historyQueryKey] = useMemo(() => {
    const data = {
      size: Number(initialSize),
      page: Number(initialPage),
      serviceType:
        serviceTypeFilter?.length === 3 ? undefined : serviceTypeFilter,
      salesStatus:
        salesStatusFilter?.length === 8 ? undefined : salesStatusFilter,
      todoStatus: todoStatusFilter?.length === 3 ? undefined : todoStatusFilter,
      keyword: initialSearch,
      salesOrderBy: initialSortedColumn
        ? SalesOrderByType[initialSortedColumn][initialSortType]
        : null,
    };
    const queryKey = [
      initialPage,
      initialSearch,
      initialSize,
      serviceTypeFilter,
      salesStatusFilter,
      todoStatusFilter,
      initialSortedColumn,
      initialSortType,
    ];
    return [data, queryKey];
  }, [
    initialSize,
    initialPage,
    serviceTypeFilter,
    salesStatusFilter,
    todoStatusFilter,
    initialSearch,
    initialSortType,
    initialSortedColumn,
  ]);

  const { data: totalHistories, refetch } = useQuery(
    ['totalHistories', ...historyQueryKey],
    () => getTotalHistories(historyData),
    {
      onSuccess: ({ totalPages, first, last }) => {
        setTotalPage(totalPages);
        setIsFirstPage(first);
        setIsLastPage(last);
      },
      retry: false,
      keepPreviousData: true,
    },
  );

  const { data: exceedHistories } = useQuery(
    ['exceedHistories', ...historyQueryKey],
    () => getExceedHistories(historyData),
    {
      retry: false,
      keepPreviousData: true,
    },
  );

  const mapHistoriesToTableData = useCallback(
    (histories: History[]) =>
      histories.map((history) => {
        const serviceType = SERVICE_TYPE_LABEL_LIST.find(
          (option) => option.value === history.serviceType,
        );
        const taxEnrollment = TAX_ENROLLMENT_LABEL_LIST.find(
          (option) =>
            option.value ===
            (history.taxEnrollment ? 'ENROLL_YES' : 'ENROLL_NO'),
        );
        const todoStatus = TODO_STATUS_LABEL_LIST.find(
          (option) => option.value === history.todoStatus,
        );
        const salesStatus = SALES_STATUS_LABEL_LIST.find(
          (option) => option.value === history.salesStatus,
        );

        return {
          id: history.id,
          serviceType: (
            <StatusLabel {...serviceType}>{serviceType?.label}</StatusLabel>
          ),
          storeName: history.storeName,
          taxEnrollment: (
            <StatusLabel {...taxEnrollment}>{taxEnrollment?.label}</StatusLabel>
          ),
          firstInquiryDate: history.firstInquiryDate,
          salesStatus: (
            <StatusLabel {...salesStatus}>{salesStatus?.label}</StatusLabel>
          ),
          todoStatus: (
            <StatusLabel {...todoStatus}>{todoStatus?.label}</StatusLabel>
          ),
          todoDueDate: history.todoDueDate,
          processDays:
            history.processDays === 0
              ? '- '
              : `${history.processDays.toString()}일`,
        };
      }),
    [],
  );

  const rows = useMemo(() => {
    const total = totalHistories
      ? mapHistoriesToTableData(totalHistories.content)
      : [];
    const exceed = exceedHistories
      ? mapHistoriesToTableData(exceedHistories.contents)
      : [];
    return [exceed, total];
  }, [totalHistories, exceedHistories, mapHistoriesToTableData]);

  const isEmpty = useMemo(
    () => rows[0].length === 0 && rows[1].length === 0,
    [rows],
  );

  const onOpenEditModal = (index: number) => {
    openModal();
    setOpenedItem(index);
  };

  const onCloseEditModal = () => {
    closeModal();
    setOpenedItem(undefined);
    refetch();
  };

  return (
    <StyledContainer maxWidth='xl'>
      <Title>가맹 문의 히스토리</Title>
      <Search
        data={keywords}
        placeholder='상호명, 이메일, 전화번호를 입력해주세요.'
      />
      <Navigation>
        <DataControls>
          <SalesFilter
            serviceType={serviceTypeFilter}
            setServiceType={setServiceTypeFilter}
            salesStatus={salesStatusFilter}
            setSalesStatus={setSalesStatusFilter}
            todoStatus={todoStatusFilter}
            setTodoStatus={setTodoStatusFilter}
          />
          <ViewDataSelection initialSize={initialSize} />
        </DataControls>
        <AddButton onClick={openModal}>추가</AddButton>
      </Navigation>
      <DataTable
        columns={columns}
        rows={rows}
        onClickRow={onOpenEditModal}
        initialSortedColumn={initialSortedColumn}
        initialSortType={initialSortType as Sort}
      />
      {isEmpty && (
        <ContentContainer>결과가 존재하지 않습니다.</ContentContainer>
      )}
      <Footer>
        {totalPage > 0 && (
          <Pagination
            initialPage={initialPage}
            totalPage={totalPage}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            displaySize={Number(initialSize)}
          />
        )}
      </Footer>
      {isOpenModal && (
        <SalesModal index={openedItem} onClose={onCloseEditModal} />
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  color: gray;
  font-size: 16px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 28px;
`;

const AddButton = styled.button`
  width: 140px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #246cf6;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
`;

const DataControls = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export default FranchiseeInquiryHistory;
