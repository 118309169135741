import styled from 'styled-components';
import ICON_SORT_ALT from '@/assets/medical/icon_sort_alt.png';
import ICON_RADIO from '@/assets/medical/icon_radio.png';
import ICON_RADIO_OK from '@/assets/medical/icon_radio_ok.png';

type Props = {
  onChange?: (v: any) => void;
  value?: string;
};

function DateSortingFilter({ onChange, value }: Props) {
  const changeSorting = (value: string) => {
    onChange && onChange(value);
  };

  const switchSorting = () => {
    if (value === 'EXPIRY_DATE') changeSorting('CREATED_DATE');
    if (value === 'CREATED_DATE') changeSorting('EXPIRY_DATE');
  };

  return (
    <Wrapper>
      <FilterButton type="button" onClick={switchSorting}>
        <IconSort src={ICON_SORT_ALT} alt="" width={24} height={24} />
      </FilterButton>
      <RadioGroup>
        <RadioWrapper isOk={value === 'CREATED_DATE'}>
          {value === 'CREATED_DATE' ? (
            <img src={ICON_RADIO_OK} alt="" width={24} height={24} />
          ) : (
            <img src={ICON_RADIO} alt="" width={24} height={24} />
          )}
          <RadioNone
            type="radio"
            name="date_sorting"
            onChange={(e) => changeSorting(e.target.value)}
            value="CREATED_DATE"
            checked={value === 'CREATED_DATE'}
          />
          등록순
        </RadioWrapper>
        <RadioWrapper isOk={value === 'EXPIRY_DATE'}>
          {value === 'EXPIRY_DATE' ? (
            <img src={ICON_RADIO_OK} alt="" width={24} height={24} />
          ) : (
            <img src={ICON_RADIO} alt="" width={24} height={24} />
          )}
          <RadioNone
            type="radio"
            name="date_sorting"
            value="EXPIRY_DATE"
            onChange={(e) => changeSorting(e.target.value)}
            checked={value === 'EXPIRY_DATE'}
          />
          유치기관 만료일순
        </RadioWrapper>
      </RadioGroup>
    </Wrapper>
  );
}

export default DateSortingFilter;

type RadioStyle = {
  isOk: boolean;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cbccce;
  border-radius: 4px;
  padding: 14px 16px;
`;

const FilterButton = styled.button`
  display: block;
  margin-right: 16px;
  padding: 0;
`;

const IconSort = styled.img`
  display: block;
`;

const RadioNone = styled.input`
  display: none;
`;

const RadioWrapper = styled.label<RadioStyle>`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;
  color: ${({ isOk }) => (isOk ? '#246CF6' : '#80848a')};
  cursor: pointer;
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 24px;
`;
