import React, { useState, useEffect, FormEventHandler } from 'react';
import styled from 'styled-components';
import { Container, TableBody } from '@material-ui/core';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { getHospitalStatus, getKeywords } from '@/api/medical';
import RefundListModal from '@components/medical/hospitalStatus/RefundListModal';
import DetailModal from '@components/medical/hospitalStatus/DetailModal';
import SignUpModal from '@components/medical/hospitalStatus/SignUpModal';
import CheckboxForm from '@components/common/CheckboxForm';
import PageIndicator from '@components/common/PageIndicator';
import SearchBox from '@components/common/SearchBox';
import DataTable from '@components/common/DataTable';
import useHospitalReducer from '@/hooks/medical/useHospitalReducer';
import Title from '@components/common/Title';
import ActionBtn from '@components/common/ActionBtn';
import LoadingView from '@/components/common/LoadingView';
import HospitalSatusListItem from '@/components/medical/hospitalStatus/HospitalSatusListItem';
import { hospitalStatusData } from '@/constants/medical';
import { IGetHospitalStatusResponse } from '@/types/api/medical';
import DateSortingFilter from '@/components/medical/hospitalStatus/DateSortingFilter';

function HospitalStatus() {
  const [state, dispatch] = useHospitalReducer();
  const { isOpenRefund, isOpenDetail, isOpenCreate, selected, toggle, filter } =
    state;
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageList, setPageList] = useState<Array<number>>([0]);
  const [keyword, setKeyword] = useState<string>('');
  const [orderBy, setOrderBy] = useState<'CREATED_DATE' | 'EXPIRY_DATE'>(
    'CREATED_DATE',
  );
  const { columns, hospitalStatusRadio } = hospitalStatusData;

  useEffect(() => {
    setTotalPage(0);
  }, [keyword]);

  const {
    isLoading,
    data: hospitalData,
    isSuccess,
  } = useQuery<IGetHospitalStatusResponse, AxiosError>(
    ['hospitalStatus', filter, currentPage, keyword, orderBy],
    () =>
      getHospitalStatus({
        hospitalStatus: filter.hospitalStatus,
        page: currentPage,
        keyword,
        orderBy,
      }),
    {
      enabled: !isOpenRefund && !isOpenDetail,
      onSuccess: (data) => {
        if (currentPage === 0) {
          setTotalPage(data.totalPages);
          setPageList(
            data.totalPages < 4
              ? Array.from({ length: data.totalPages + 1 }, (v, i) => i)
              : [0, 1, 2, 3, 4],
          );
        }
      },
      retry: false,
    },
  );
  useEffect(() => {
    setCurrentPage(0);
  }, [totalPage]);

  const { data: keywords } = useQuery('keywords', getKeywords, {
    retry: false,
  });
  const onOpen = (type: 'DETAIL' | 'REFUND' | 'CREATE', selected: any) => {
    dispatch({
      type: 'OPEN_MODAL',
      payload: { type, selected },
    });
  };

  const onClose = (type: 'DETAIL' | 'REFUND' | 'CREATE', selected: any) => {
    dispatch({
      type: 'CLOSE_MODAL',
      payload: { type, selected },
    });
  };

  const onFilter: FormEventHandler<HTMLFieldSetElement> | undefined = (e) => {
    const { name, value } = e.target as any;
    dispatch({ type: 'SET_TOGGLE', payload: null });
    dispatch({
      type: 'SET_FILTER',
      payload: { [name]: value },
    });
    setCurrentPage(0);
    setPageList([0, 1, 2, 3, 4]);
  };
  const onCreate = () => {
    onOpen('CREATE', null);
  };

  if (isLoading) {
    return <LoadingView />;
  }

  if (!hospitalData) {
    return <></>;
  }

  return (
    <StyledContainer maxWidth="xl">
      <Title>가맹점 현황</Title>
      {keywords && (
        <SearchBox
          searchKeyword={keyword}
          data={keywords}
          setSearchKeyword={setKeyword}
          setCurrentPage={setCurrentPage}
        />
      )}
      <FilterWrapper>
        <DateSortingFilter value={orderBy} onChange={(v) => setOrderBy(v)} />
      </FilterWrapper>
      <DataTable
        columns={columns}
        dispatch={dispatch}
        toggle={toggle}
        contentComponent={
          <TableBody>
            {hospitalData?.content.map((item, idx) => (
              <HospitalSatusListItem
                key={idx}
                idx={idx}
                data={item}
                onOpen={onOpen}
              />
            ))}
          </TableBody>
        }
      />
      {isSuccess && hospitalData.content.length === 0 && (
        <ContentContainer>가맹점 목록이 존재하지 않습니다.</ContentContainer>
      )}
      {selected && isOpenRefund && (
        <RefundListModal onClose={onClose} selected={selected} />
      )}
      {selected && isOpenDetail && (
        <DetailModal onClose={onClose} selected={selected} />
      )}
      {isOpenCreate && <SignUpModal onClose={onClose} />}
      {toggle && (
        <CheckboxForm
          valueArray={hospitalStatusRadio}
          defaultValue={filter[toggle]}
          type={toggle}
          onFilter={onFilter}
          style={{ left: '500px', top: '290px' }}
        />
      )}
      <ActionBtn
        title="회원 계정 생성"
        onClick={onCreate}
        size="big"
        margin="20px 0 0"
        right="70px"
      />
      {pageList && (
        <PageIndicator
          totalPage={totalPage}
          pageList={pageList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setPageList={setPageList}
        />
      )}
    </StyledContainer>
  );
}
export default HospitalStatus;

const StyledContainer = styled(Container)`
  padding-top: 16px;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  color: gray;
  font-size: 16px;
`;

const FilterWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
