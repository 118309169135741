import { deleteInquiryHistory, saveInquiryHistory } from '@/api/sales';
import { useMutation } from 'react-query';

export const useInquiryAdd = () =>
  useMutation({
    mutationKey: ['salesHistory'],
    mutationFn: saveInquiryHistory,
  });

export const useInquiryDelete = () =>
  useMutation(deleteInquiryHistory, {
    onSuccess: () => alert('문의 히스토리를 삭제하였습니다.'),
    onError: () => alert('문의 히스토리를 삭제하는데 실패하였습니다.'),
  });
