import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import styled, { css } from 'styled-components';

import Typography from '@/components/common/Typography';

type MedicalEquirementProps = {
  item: { label: string; value: string };
  isEditMode: boolean;
  equipmentTypes: string[];
  setEquipmentTypes: Dispatch<SetStateAction<string[]>>;
};

function MedicalEquirement({
  item,
  isEditMode,
  equipmentTypes,
  setEquipmentTypes,
}: MedicalEquirementProps) {
  let checkboxRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    equipmentTypes.forEach((equire) => {
      if (equire === item.value) {
        checkboxRef.current!.checked = true;
      }
    });
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    checked
      ? setEquipmentTypes([...equipmentTypes, item.value])
      : setEquipmentTypes(
          equipmentTypes.filter((equire) => equire !== item.value),
        );
  };

  return (
    <Equirement>
      <StyledInput
        ref={checkboxRef}
        disabled={!isEditMode}
        onChange={onChange}
        type="checkbox"
      />
      <Typography>{item.label}</Typography>
    </Equirement>
  );
}

const Equirement = styled.li`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const StyledInput = styled.input`
  color: black;
  border: none;
  outline: none;
  padding: 8px 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f2f2f2;
    `}
`;

export default MedicalEquirement;
