import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { TableBody } from '@material-ui/core';

import { getKioskErrorLog } from '@/api/kiosk';
import useRefundReducer from '@/hooks/medical/useRefundReducer';
import { GetKioskErrorLogResponse } from '@/types/api/kiosk';
import { getKioskErrorLogInfo } from '@/utils/kiosk';

import LoadingView from '@/components/common/LoadingView';
import Paging from '@/components/common/Paging';
import Typography from '@/components/common/Typography';
import FlexWrap from '@/components/common/FlexWrap';
import ViewDateSelector from '@/components/common/ViewDateSelector';
import KioskDataTable from './kioskDetail/KioskDataTable';
import { StateType } from './KioskStatusDetail';
import KioskTitle from './KioskTitle';
import DateSelector from './DateSelector';
import ErrorLogInfoItem from './kioskDetail/ErrorLogInfoItem';

function KioskErrorLogDetail() {
  const [state, dispatch] = useRefundReducer();
  const { filter, dateFilter } = state;
  const { columns } = getKioskErrorLogInfo();

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [size, setSize] = useState(10);

  const { state: locationState } = useLocation<StateType>();
  const { title, preTitle, kioskId, detailTitle, billType } = locationState;

  const payload = {
    queryString: {
      page: pageNumber,
      size,
      startDate: filter.startDate,
      endDate: filter.endDate,
    },
    kioskIndex: kioskId,
  };

  const { data: kioskErrorLogData } = useQuery<
    GetKioskErrorLogResponse,
    number
  >(['kioskErrorLogDetail', payload], () => getKioskErrorLog(payload), {
    onSuccess: (data) => {
      setTotalPage(data.totalPages);
    },
    retry: false,
  });

  useEffect(() => {
    setPageNumber(0);
  }, [dateFilter]);

  return (
    <>
      <FlexWrap alignItems="center" justifyContent="space-between">
        <KioskTitle
          kioskId={kioskId}
          title={title}
          preTitle={preTitle}
          detailTitle={detailTitle}
          billType={billType}
        />
        <FlexWrap alignItems="center" gap="25px">
          <ViewDateSelector
            isMedical
            size={size}
            onChangeView={(value) => setSize(value)}
          />
          <DateSelector
            top="120px"
            right="50px"
            dateFilter={dateFilter}
            dispatch={dispatch}
            position="static"
          />
        </FlexWrap>
      </FlexWrap>
      {kioskErrorLogData?.content ? (
        <>
          <KioskDataTable
            columns={columns}
            contentComponent={
              kioskErrorLogData?.content?.length < 1 ? (
                <Typography>데이터가 없습니다.</Typography>
              ) : (
                <TableBody>
                  {kioskErrorLogData?.content?.map((item, idx) => {
                    return <ErrorLogInfoItem key={idx} idx={idx} data={item} />;
                  })}
                </TableBody>
              )
            }
          />
          <Paging
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalPage={totalPage}
          />
        </>
      ) : (
        <LoadingView />
      )}
    </>
  );
}

export default KioskErrorLogDetail;
