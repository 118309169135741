import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { signUpTableData } from '../../../constants/ktp';
import { useMutation } from 'react-query';
import { signUp } from '../../../api/ktp';
import ActionBtn from '@/components/common/ActionBtn';
import debounce from '@/utils/debounce.util';

function SignUpContent({ setFranchiseeIndex, franchiseeIndex }) {
  const [formData, setFormData] = useState({
    sellerName: null,
    storeTel: null,
    businessNumber: null,
    email: null,
    password: null,
    storeName: null,
    signboard: null,
    storeAddressNumber: '',
    storeAddressBasic: null,
    storeAddressDetail: null,
    productCategory: null,
    storeNumber: null,
    isTaxRefundShop: null,
    pushToken: '',
    businessType: null,
    isOwner: true,
    recommender: '',
  });
  const {
    sellerName,
    storeTel,
    businessNumber,
    email,
    password,
    storeName,
    storeAddressBasic,
    storeAddressDetail,
    productCategory,
    storeNumber,
    isTaxRefundShop,
    businessType,
  } = formData;
  const mutation = useMutation((payload) => signUp(payload), {
    onSuccess: (data) => {
      alert('계정 생성이 완료되었습니다.');
      setFranchiseeIndex(data);
    },
    onError: (err) => {
      const code = err?.response?.data?.code;
      let message;
      switch (code) {
        case 'P0002':
          message = '비밀번호 형식에 맞지 않습니다.';
          break;
        case 'B0001':
          message = '사업자번호 형식에 맞지 않습니다.';
          break;
        case 'R0001':
          message = '이미 가입된 사업자등록번호입니다.';
          break;
        default:
          message = '계정 생성에 실패하였습니다. ';
      }
      alert(message);
    },
    retry: false,
  });

  const getProcessedValue = (name, value) => {
    const numericFields = ['businessNumber', 'storeTel', 'storeNumber'];
    if (numericFields.includes(name)) {
      return value.replace(/[^\d]/g, '');
    }
    if (name === 'isTaxRefundShop') {
      return /^[yYnN]$/.test(value) ? value : '';
    }
    return value;
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    const processedValue = getProcessedValue(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: processedValue,
    }));
  };
  const onSubmit = () => {
    const isBtnActive = Boolean(
      sellerName.trim() &&
        storeTel.trim() &&
        businessNumber.trim() &&
        email.trim() &&
        password.trim() &&
        storeName.trim() &&
        storeAddressBasic.trim() &&
        storeAddressDetail.trim() &&
        storeNumber.trim() &&
        isTaxRefundShop.trim() &&
        productCategory.trim() &&
        businessType.trim(),
    );
    if (!isBtnActive) {
      alert('모든 정보를 입력해주세요.');
    } else if (businessNumber.length !== 10) {
      alert('사업자등록번호를 10자리로 입력해주세요.');
    } else if (
      !email.match(/[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i)
    ) {
      alert('이메일 형식이 옳지 않습니다.');
    } else if (productCategory === '판매상품 종목 선택' || !productCategory) {
      alert('판매상품 종목을 선택해주세요.');
    } else if (!/^[YyNn]$/.test(isTaxRefundShop)) {
      alert('사후면세판매장 가입여부를 올바르게 입력해주세요.');
    } else {
      const trimmedFormData = Object.keys(formData).reduce((acc, key) => {
        const value = formData[key];
        acc[key] = typeof value === 'string' ? value.trim() : value;
        return acc;
      }, {});

      mutation.mutate({
        ...trimmedFormData,
        businessNumber: businessNumber
          .replace(/[^0-9]/g, '')
          .replace(/^([/0-9]{3})([/0-9]{2})([/0-9]{5})$/, '$1-$2-$3'),
      });
    }
  };
  const createAccount = debounce(onSubmit, 200);

  return (
    <TableContainer>
      <Title>회원 계정 생성</Title>
      <StyledTable width='100%' align='center'>
        {signUpTableData.map((items) => (
          <StyledTr>
            {items.map((item) => (
              <>
                <StyledTh required={item.required || false}>
                  {item.label}
                </StyledTh>
                <StyledTd isActive={!franchiseeIndex}>
                  {item?.selectName ? (
                    <StyledSelect
                      isActive={!franchiseeIndex}
                      name={item.selectName}
                      onChange={onChange}
                      required={item.required || false}
                    >
                      {item.optionList?.map((item) => (
                        <option
                          value={typeof item === 'string' ? item : item.value}
                        >
                          {typeof item === 'string' ? item : item.label}
                        </option>
                      ))}
                    </StyledSelect>
                  ) : (
                    <StyledInput
                      {...item.option}
                      value={formData[item.option.name]}
                      onChange={onChange}
                      disabled={franchiseeIndex}
                      required={item.required || false}
                    />
                  )}
                </StyledTd>
              </>
            ))}
          </StyledTr>
        ))}
      </StyledTable>
      <ActionBtn
        title='생성하기'
        onClick={createAccount}
        right='0'
        bottom='-8px'
      />
    </TableContainer>
  );
}
const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 16px;
`;
const TableContainer = styled.div`
  width: 100%;
  position: relative;
`;
const borderStyles = css`
  border-collapse: collapse;
  border: 1px solid black;
`;
const StyledTable = styled.table`
  ${borderStyles}
  margin: 0 0 40px;
`;
const StyledTd = styled.td`
  ${borderStyles}
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  min-width: 120px;
  width: ${(props) => props.width || 'auto'};
  ${(props) =>
    !props.isActive &&
    css`
      background-color: #f2f2f2;
    `}
`;
const StyledTh = styled.td`
  ${borderStyles}
  padding-left: 16px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  background-color: #d3d3d3;
  width: 170px;
  ${({ required }) =>
    required &&
    `
    &:after {
      content: '*';
      color: red;
    }
  `}
`;

const StyledTr = styled.tr`
  ${borderStyles}
  padding: 8px 15px;
  font-size: 14px;
`;
const StyledInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  padding: 8px 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const StyledSelect = styled.select`
  padding: 8px;
  border: none;
  outline: none;
  ${(props) =>
    !props.isActive &&
    css`
      background-color: #f2f2f2;
    `}
`;
export default SignUpContent;
