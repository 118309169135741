import styled from 'styled-components';
import { TableRow } from '@material-ui/core';

import { KioskDetailErrorInfo } from '@/types/api/kiosk';
import {
  formatKioskErrorCode,
  formatKioskErrorMessage,
  formatKioskErrorStatus,
} from '@/utils/kiosk';

import CustomTableCell from '@/components/common/CustomTableCell';

type WithdrawalInfoItemProps = {
  data: KioskDetailErrorInfo;
  idx: number;
  isDetail?: boolean;
};

function ErrorLogInfoItem({
  data,
  idx,
  isDetail = false,
}: WithdrawalInfoItemProps) {
  const {
    bd1ErrorCode,
    bd2ErrorCode,
    bd3ErrorCode,
    createdDate,
    errorStatus,
    hp1Status,
    kioskErrorId,
  } = data;
  return (
    <>
      <StyledTableRow idx={idx} isDetail={isDetail}>
        <CustomTableCell idx={0} width="60" fontSize="14px" fontWeight={500}>
          {createdDate}
        </CustomTableCell>
        <CustomTableCell width="120" fontSize="14px" fontWeight={500}>
          {formatKioskErrorCode([
            bd1ErrorCode,
            bd2ErrorCode,
            bd3ErrorCode,
            hp1Status,
          ])}
        </CustomTableCell>
        <CustomTableCell width="100" fontSize="14px" fontWeight={500}>
          {formatKioskErrorMessage([
            bd1ErrorCode,
            bd2ErrorCode,
            bd3ErrorCode,
            hp1Status,
          ])}
        </CustomTableCell>
        <CustomTableCell width="100" fontSize="14px" fontWeight={500}>
          {formatKioskErrorStatus(errorStatus)}
        </CustomTableCell>
      </StyledTableRow>
    </>
  );
}

const StyledTableRow = styled(TableRow)<{ idx: number; isDetail: boolean }>`
  background-color: #ffffff;
  border-bottom: ${(props) =>
    (props.idx + 1) % 5 === 0
      ? '2px solid rgb(220, 220, 220)'
      : '1px solid rgb(220, 220, 220)'};
  :hover {
    background-color: #f7f9fc;
  }
  cursor: ${(props) => props.isDetail && 'pointer'};
  span {
    font-weight: 500;
  }
`;

export default ErrorLogInfoItem;
