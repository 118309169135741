import React from 'react';
import styled from 'styled-components';

type IconProps = {
  width: string;
  height: string;
  imgUrl: string;
  margin?: string;
};

function Icon({ width, height, imgUrl, margin }: IconProps) {
  return <Image src={imgUrl} width={width} height={height} margin={margin} />;
}

const Image = styled.img<{ margin?: string }>`
  object-fit: contain;
  margin: ${(props) => props.margin || '0px'};
`;

export default Icon;
