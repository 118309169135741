import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import styled from 'styled-components';
import {
  deleteNotice,
  getNoticeDetailKtp,
  hideOrDisplayNotice,
  updateNotice,
} from '../../../api/ktp';
import Preview from './Preview';
import Form from './Form';
import ActionBtn from '@/components/common/ActionBtn';
import Modal from '@/components/common/Modal';
import FlexWrap from '@/components/common/FlexWrap';

function DetailModal({ onCloseModal, selectedData: { noticeIndex } }) {
  const [state, setState] = useState({
    isFixed: false,
    isImmediate: true,
    scheduledDate: '',
    title: '',
    subTitle1: '',
    subTitle2: '',
    subTitle3: '',
    content1: '',
    content2: '',
    content3: '',
    link: 'NONE',
    noticeType: 'NONE',
    isInvisible: false,
  });
  const [imgList, setImgList] = useState({
    mainImg: null,
    subImg1: null,
    subImg2: null,
    subImg3: null,
  });

  const queryClient = useQueryClient();
  const { isLoading } = useQuery(
    ['notificationDetail', noticeIndex],
    () => getNoticeDetailKtp(noticeIndex),
    {
      retry: false,
      onSuccess: ({
        isFixed,
        isImmediate,
        scheduledDate,
        title,
        subTitle1,
        subTitle2,
        subTitle3,
        content1,
        content2,
        content3,
        link,
        noticeType,
        isInvisible,
        mainImg,
        subImg1,
        subImg2,
        subImg3,
      }) => {
        setState({
          isFixed,
          isImmediate,
          scheduledDate,
          title,
          subTitle1,
          subTitle2,
          subTitle3,
          content1,
          content2,
          content3,
          link,
          noticeType,
          isInvisible,
        });
        setImgList({ mainImg, subImg1, subImg2, subImg3 });
      },
      onError: () => alert('서버 요청에 실패하였습니다.'),
    },
  );
  const updateMutation = useMutation(
    (payload) => updateNotice(noticeIndex, payload),
    {
      onError: () =>
        alert('공지사항 수정에 실패하였습니다. 관리자에게 문의해주세요.'),
      onSuccess: (statusCode) => {
        if (statusCode === 200) {
          alert('공지사항이 정상적으로 수정되었습니다.');
          onCloseModal();
          // window.location.reload();
        }
      },
    },
  );
  const hideOrDisplayMutation = useMutation(
    () => hideOrDisplayNotice(noticeIndex, state.isInvisible),
    {
      retry: false,
      onSuccess: () => {
        alert('정상 처리되었습니다.');
        onCloseModal();
      },
      onError: () =>
        alert('서버 요청에 실패하였습니다. 관리자에게 문의주세요.'),
    },
  );
  const deleteMutation = useMutation(() => deleteNotice(noticeIndex), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries('noticeLists');
      onCloseModal();
    },
    onError: () => alert('서버 요청에 실패하였습니다. 관리자에게 문의주세요.'),
  });
  const onHideOrDisplay = () => {
    const isOK = window.confirm(
      state.isInvisible
        ? '해당 공지사항을 다시 올리시겠습니까?'
        : '해당 공지사항을 정말로 내리시겠습니까?',
    );
    if (!isOK) {
      return;
    }
    hideOrDisplayMutation.mutate();
  };
  const onDelete = () => {
    const isOK = window.confirm(
      '해당 공지사항을 정말로 삭제하시겠습니까? 삭제된 공지사항은 복구할 수 없습니다.',
    );
    if (!isOK) {
      return;
    }
    deleteMutation.mutate();
  };

  const onUpdate = (e) => {
    e.preventDefault();
    if (!state.isImmediate && !state.scheduledDate) {
      alert('예약 발송 날짜를 입력해주세요.');
      return;
    }
    if (!state.title || !state.content1 || state.noticeType === 'NONE') {
      alert('필수 입력 항목을 채워주세요.');
      return;
    }
    const isOK = window.confirm('해당 내용으로 공지사항을 수정하시겠습니까?');
    if (!isOK) {
      return;
    }
    updateMutation.mutate(state);
  };
  const onChangeInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const onChangeImage = (e) => {
    setImgList({ ...imgList, [e.target.name]: e.target.files[0] });
  };
  const onChangeChecked = (e) => {
    setState({ ...state, [e.target.name]: e.target.checked });
  };
  const onChangeRadio = (e) => {
    setState({
      ...state,
      isImmediate: e.target.name === 'isImmediate' ? true : false,
      ...(e.target.name === 'isImmediate' && { scheduledDate: '' }),
    });
  };
  const onChangeSelect = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  return (
    <Modal onClose={onCloseModal} isLoading={isLoading}>
      <Wrapper>
        <FormAndPreview>
          <Form
            state={state}
            onChangeChecked={onChangeChecked}
            onChangeImage={onChangeImage}
            onChangeInput={onChangeInput}
            onChangeRadio={onChangeRadio}
            onChangeSelect={onChangeSelect}
            isEditMode
          />
          <Preview
            state={state}
            mainImg={imgList.mainImg}
            subImg1={imgList.subImg1}
            subImg2={imgList.subImg2}
            subImg3={imgList.subImg3}
            isEditMode
          />
        </FormAndPreview>
        <BtnContainer gap="10px" justifyContent="flex-end" margin="20px 0 0">
          <ActionBtn
            title={`공지사항 ${state.isInvisible ? '올리기' : '내리기'}`}
            onClick={onHideOrDisplay}
            bgColor="#757575"
            size="big"
          />
          <ActionBtn
            title="삭제하기"
            onClick={onDelete}
            color="#E42939"
            outlineColor="#E42939"
            bgColor="#ffffff"
            size="big"
          />
          <ActionBtn title="수정완료" onClick={onUpdate} size="big" />
        </BtnContainer>
      </Wrapper>
    </Modal>
  );
}
const Wrapper = styled.div`
  width: 100%;
`;
const FormAndPreview = styled.div`
  display: flex;
`;

const BtnContainer = styled(FlexWrap)`
  margin-left: auto;
`;
export default DetailModal;
