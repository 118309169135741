import { useState } from 'react';
import { getSearchPushKeywords } from '@/api/ktp';
import { useQuery } from 'react-query';

export type StoreListType = {
  keyword: string;
  businessNumber: string;
  franchiseeIndex: string;
};

export type GetSearchKeywordsResponse = {
  keyword: string;
};

export const usePushNotification = () => {
  const [serachStoreList, setSerachStoreList] = useState<StoreListType[]>([]);
  const [selectedStoreList, setSelectedStoreList] = useState<StoreListType[]>(
    [],
  );
  const [filteredStoreList, setFilterdStoreList] = useState<StoreListType[]>(
    [],
  );
  useQuery(['keywords'], getSearchPushKeywords, {
    retry: false,
    onSuccess: (res) => objectTypeChangeData(res),
  });

  const objectTypeChangeData = (data: GetSearchKeywordsResponse[]) => {
    const newData: StoreListType[] = [];
    data &&
      data.forEach((item, idx) => {
        if (idx < data.length / 3) {
          newData[idx] = { ...newData[idx], businessNumber: item.keyword };
        } else if (idx < (data.length / 3) * 2) {
          const index = idx - data.length / 3;
          newData[index] = { ...newData[index], keyword: item.keyword };
        } else {
          const index = idx - (data.length / 3) * 2;
          newData[index] = { ...newData[index], franchiseeIndex: item.keyword };
        }
      });
    setSerachStoreList(newData);
  };

  const pushSearchFilter = (inputValue: string) => {
    const filterArray = serachStoreList.filter(
      (store) =>
        store.keyword.includes(inputValue) ||
        store.businessNumber.includes(inputValue),
    );
    setFilterdStoreList(filterArray);
  };

  return {
    selectedStoreList,
    setSelectedStoreList,
    filteredStoreList,
    pushSearchFilter,
  };
};
