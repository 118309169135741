import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';

type GlobalPayContentProps = {
  isEditMode: boolean;
  active: boolean;
  newGlobalPayInfo: {
    mid: string | null;
    globalPayMethods: Array<string>;
  };
  setNewGlobalPayInfo: Dispatch<
    SetStateAction<{
      mid: string | null;
      globalPayMethods: Array<string>;
    }>
  >;
};
function GlobalPayContent({
  isEditMode,
  active,
  newGlobalPayInfo: { mid, globalPayMethods },
  setNewGlobalPayInfo,
}: GlobalPayContentProps) {
  const onClickPayBtn = (e: any) => {
    const name = e.target.name as string;
    if (globalPayMethods.length === 0 || !globalPayMethods.includes(name)) {
      setNewGlobalPayInfo((prev) => ({
        ...prev,
        globalPayMethods: [...globalPayMethods, name],
      }));
      return;
    }
    setNewGlobalPayInfo((prev) => ({
      ...prev,
      globalPayMethods: globalPayMethods.filter((v) => v !== name),
    }));
  };
  const onChangeMid = (e: any) => {
    const mid = e.target.value.replace(/\s/g, '');
    setNewGlobalPayInfo((prev) => ({ ...prev, mid }));
  };
  return (
    <>
      <FlexWrap alignItems='center' margin='0 0 20px'>
        <Title>해외 간편 결제 </Title>
        <StatusText>{active ? '활성화' : '비활성화'}</StatusText>
      </FlexWrap>
      <FlexWrap alignItems='center' margin='12px 0'>
        <Label>MID</Label>
        <StyledInput
          type='text'
          placeholder='ex) M10000024731'
          onChange={onChangeMid}
          value={mid || ''}
          disabled={!isEditMode}
        />
      </FlexWrap>
      <FlexWrap gap='8px' margin='0 0 36px' alignItems='center'>
        <Label>결제수단</Label>
        <Button
          name='ALIPAY'
          onClick={onClickPayBtn}
          active={globalPayMethods.includes('ALIPAY')}
          disabled={!isEditMode}
        >
          알리페이
        </Button>
        <Button
          name='ALIPAY_PLUS'
          onClick={onClickPayBtn}
          active={globalPayMethods.includes('ALIPAY_PLUS')}
          disabled={!isEditMode}
        >
          알리페이플러스
        </Button>
        <Button
          name='WECHATPAY'
          onClick={onClickPayBtn}
          active={globalPayMethods.includes('WECHATPAY')}
          disabled={!isEditMode}
        >
          위챗페이
        </Button>
        <Button
          name='PAYNOW'
          onClick={onClickPayBtn}
          active={globalPayMethods.includes('PAYNOW')}
          disabled={!isEditMode}
        >
          페이나우
        </Button>
        <Button
          name='XNAP'
          onClick={onClickPayBtn}
          active={globalPayMethods.includes('XNAP')}
          disabled={!isEditMode}
        >
          엑스넵
        </Button>
        <Button
          name='THAIQR'
          onClick={onClickPayBtn}
          active={globalPayMethods.includes('THAIQR')}
          disabled={!isEditMode}
        >
          타이큐알
        </Button>
      </FlexWrap>
    </>
  );
}
const Title = styled.h3`
  font-size: 18px;
`;
const StatusText = styled.span`
  color: gray;
  font-size: 10px;
  margin-left: 8px;
`;
const Label = styled.span`
  width: 120px;
  color: #3a3b3e;
`;
const StyledInput = styled.input`
  text-transform: uppercase;
  margin-left: 8px;
  border-radius: 4px;
  border: 1px solid lightgray;
  padding: 8px 16px;
  ::placeholder {
    color: lightgray;
  }
`;
const Button = styled.button<{ active: boolean; disabled: boolean }>`
  border: 1px solid ${(props) => (props.active ? '#354eff' : 'gray')};
  color: ${(props) => (props.active ? '#354eff' : 'gray')};
  padding: 4px 12px;
  border-radius: 15px;
  font-size: 15px;
  :disabled {
    opacity: 0.6;
    background-color: #f5f6f7;
    cursor: not-allowed;
  }
`;
export default GlobalPayContent;
