import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';

import {
  getNoticeIndexList,
  getPushDetail,
  getSearchKeywords,
  requestPush,
} from '../../../api/ktp';
import { getByteLength } from '../../../utils/ktp';
import SelectBox from '@/components/common/SelectBox';
import ActionBtn from '@/components/common/ActionBtn';
import Modal from '@/components/common/Modal';
import FlexWrap from '@/components/common/FlexWrap';
import SearchBox from '@/components/common/SearchBox';
import { usePushNotification } from '@/hooks/ktp/usePushNotification';

const accountList = [
  { title: '전체', accountType: 'ALL' },
  { title: '마스터', accountType: 'FRANCHISEE' },
  { title: '직원', accountType: 'EMPLOYEE' },
];

function AddOrDetailModal({ onCloseModal, selectedData: { pushIndex, type } }) {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [accountType, setAccountType] = useState(0);
  const [noticeIndex, setNoticeIndex] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState([]);
  const {
    selectedStoreList,
    setSelectedStoreList,
    filteredStoreList,
    pushSearchFilter,
  } = usePushNotification();

  const mutation = useMutation((payload) => requestPush(payload), {
    onError: () =>
      alert('푸시 알림 전송에 실패하였습니다. 관리자에게 문의해주세요.'),
    onSuccess: (statusCode) => {
      if (statusCode === 200) {
        alert('알림 전송에 성공하였습니다.');
        onCloseModal();
      }
    },
  });

  const { data: keywords } = useQuery('keywords', getSearchKeywords, {
    retry: false,
  });

  const { data: noticeIndexList } = useQuery(
    'noticeIndexList',
    () => getNoticeIndexList(),
    {
      onError: () => alert('공지사항 인덱스 리스트 조회에 실패하였습니다.'),
      retry: false,
    },
  );
  const { data: pushDetail, isLoading } = useQuery(
    'pushDetail',
    () => getPushDetail(pushIndex),
    {
      enabled: type === 'DETAIL',
      onError: () =>
        alert('알림 상세 조회에 실패하였습니다. 관리자에게 문의해주세요.'),
    },
  );

  const onChangeTitle = (e) => {
    const value = e.target.value;
    if (getByteLength(value) <= 60) {
      setTitle(value.replace(/^\s/, ''));
    }
  };
  const onChangeBody = (e) => {
    const value = e.target.value;
    if (getByteLength(value) <= 140) {
      setBody(value.replace(/^\s/, ''));
    }
  };
  const onChangeNoticeSelect = (e) => {
    setNoticeIndex(e.target.value);
  };

  const onChangeAccountSelect = (e) => {
    setAccountType(e.target.value);
  };

  const onSubmit = () => {
    const isOK = window.confirm(
      `${
        selectedStoreList.length === 0
          ? '모든 매장에'
          : selectedStoreList.length + '개의 매장에'
      } 해당 내용으로 푸시 알림을 보내시겠습니까?`,
    );
    if (!isOK) {
      return;
    }
    const franchiseeIndexList = selectedStoreList.map(
      (item) => item.franchiseeIndex,
    );
    const payload = {
      title,
      body,
      noticeIndex,
      accountType: accountType,
      franchiseeIndexList,
    };
    mutation.mutate(payload);
  };
  const closeModalIfConfirm = () => {
    if (type === 'ADD') {
      if (window.confirm('정말 나가시겠습니까?')) {
        onCloseModal();
      }
    } else {
      onCloseModal();
    }
  };
  return (
    <Modal onClose={closeModalIfConfirm} isLoading={isLoading}>
      <Wrapper>
        <FlexWrapColumn>
          <FlexWrapRow>
            <StyledTitle>
              <span>푸시 메세지 제목</span>
              <StarText> *</StarText>
            </StyledTitle>
            <StyledInput
              onChange={onChangeTitle}
              value={pushDetail?.title || title}
              disabled={type === 'DETAIL'}
              placeholder="푸시 메세지 제목을 입력해주세요."
            />
          </FlexWrapRow>
          <FlexWrapRow reverse style={{ marginBottom: 50 }}>
            <GrayText style={{ paddingLeft: 40, fontSize: 14 }}>
              60byte이내 (공백포함)
            </GrayText>
            <GrayText>
              [<BlueText> {getByteLength(pushDetail?.title || title)}</BlueText>{' '}
              / 60 ]
            </GrayText>
          </FlexWrapRow>
        </FlexWrapColumn>
        <FlexWrapColumn>
          <FlexWrapRow>
            <StyledTitle>
              <span>푸시 메세지 내용</span>
              <StarText> *</StarText>
            </StyledTitle>
            <StyledTextArea
              disabled={type === 'DETAIL'}
              value={pushDetail?.body || body}
              onChange={onChangeBody}
              maxLength={140}
              placeholder="푸시 메세지 내용을 입력해주세요."
            />
          </FlexWrapRow>
          <FlexWrapRow reverse style={{ marginBottom: 50 }}>
            <GrayText style={{ fontSize: 14 }}>
              최대 140byte까지 작성할 수 있으며, 알림 상태바에서는 80byte까지만
              표시됩니다.
            </GrayText>
            <GrayText>
              [<BlueText> {getByteLength(pushDetail?.body || body)}</BlueText> /
              140 ]
            </GrayText>
          </FlexWrapRow>
        </FlexWrapColumn>
        <FlexWrapRow>
          <FlexWrapRow>
            <StyledTitle>연결할 공지사항</StyledTitle>
            {noticeIndexList && (
              <SelectBox
                disabled={type === 'DETAIL'}
                defaultTitle="연결할 공지사항 선택"
                defaultValue={0}
                selectedValue={pushDetail?.noticeIndex || noticeIndex}
                menuList={noticeIndexList.map((item) => ({
                  title: `공지사항 ${item.noticeIndex} (${item.title})`,
                  value: item.noticeIndex,
                }))}
                onChange={onChangeNoticeSelect}
              />
            )}
          </FlexWrapRow>
          <FlexWrapRow>
            <StyledTitle>
              <span>계정 선택</span>
              <StarText> *</StarText>
            </StyledTitle>
            {accountList && (
              <SelectBox
                disabled={type === 'DETAIL'}
                defaultTitle="전송할 계정 선택"
                defaultValue={0}
                selectedValue={pushDetail?.accountType || accountType}
                menuList={accountList.map((item) => ({
                  title: item.title,
                  value: item.accountType,
                }))}
                onChange={onChangeAccountSelect}
              />
            )}
          </FlexWrapRow>
        </FlexWrapRow>
        <FlexWrapRow>
          <StyledTitle>특정 가맹점 선택</StyledTitle>
          <FlexWrap dir="column" flex="1">
            {keywords && (
              <SearchBox
                disabled={type === 'DETAIL'}
                data={keywords}
                isPushNotice
                pushSearchFilter={pushSearchFilter}
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
                filteredStoreList={filteredStoreList}
                selectedStoreList={selectedStoreList}
                setSelectedStoreList={setSelectedStoreList}
              />
            )}
            {accountType !== 'ALL' && (
              <TagWrapper>
                {pushDetail?.sendList?.map((v, i) => (
                  <Tag>{v.storeName}</Tag>
                ))}
              </TagWrapper>
            )}
          </FlexWrap>
        </FlexWrapRow>
        <BtnContainer>
          <ActionBtn
            title="발송하기"
            size="big"
            onClick={onSubmit}
            active={
              Boolean(title && body && accountType !== 0) && type !== 'DETAIL'
            }
          />
        </BtnContainer>
      </Wrapper>
    </Modal>
  );
}
const Wrapper = styled.div`
  width: 100%;
`;
const StyledTitle = styled.div`
  margin-top: 12px;
  min-width: 200px;
`;
const StarText = styled.span`
  color: orange;
`;
const GrayText = styled.span`
  color: gray;
  margin-left: 20px;
  font-size: 14px;
`;
const BlueText = styled.span`
  color: #2196f3;
`;
const StyledInput = styled.input`
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 14px;
  width: 100%;
`;
const StyledTextArea = styled.textarea`
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 14px;
  width: 100%;
  resize: none;
  line-height: 160%;
  min-height: 150px;
  outline: none;
`;
const FlexWrapRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: ${(props) => (props.reverse ? 'flex-end' : 'flex-start')};
  margin-bottom: 12px;
  width: 100%;
`;
const FlexWrapColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const BtnContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;
const Tag = styled.div`
  background-color: gray;
  border-radius: 20px;
  padding: 4px 12px;
  color: white;
`;
const TagWrapper = styled(FlexWrap)`
  flex-wrap: wrap;
  gap: 12px;
`;
export default React.memo(AddOrDetailModal);
