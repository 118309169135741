import {
  DataTableColumn,
  FilterConfig,
  SalesStatus,
  ServiceType,
  TodoStatus,
} from '@/types/sales.types';

export const INQUIRY_HISTORY_TABLE_DATA: { columns: DataTableColumn[] } = {
  columns: [
    { type: 'id', title: 'NO', width: 77 },
    { type: 'serviceType', title: '구분', width: 77 },
    { type: 'storeName', title: '상호명', width: 200, align: 'left' },
    {
      type: 'taxEnrollment',
      title: '유치기관 등록',
      width: 150,
      sortable: true,
    },
    { type: 'firstInquiryDate', title: '첫 문의', width: 150, sortable: true },
    { type: 'salesStatus', title: '상태', width: 150 },
    { type: 'todoStatus', title: 'Do List', width: 150 },
    {
      type: 'todoDueDate',
      title: 'Do List 기한',
      width: 150,
      sortable: true,
      highlight: true,
    },
    { type: 'processDays', title: '경과일', width: 150, sortable: true },
  ],
};

export const DEFAULT_FILTER: FilterConfig = {
  serviceType: ['MERCHANT', 'MEDICAL', 'ETC'],
  salesStatus: [
    'INQUIRY',
    'MANUAL_SEND',
    'PACKAGE_SEND',
    'BEFORE_MEETING',
    'SCHEDULED_MEETING',
    'AFTER_MEETING',
  ],
  todoStatus: ['CONTACT', 'VISIT', 'PACKAGE'],
};

export const FILTER_OPTIONS: Record<
  string,
  ServiceType[] | SalesStatus[] | TodoStatus[]
> = {
  serviceTypes: ['MERCHANT', 'MEDICAL', 'ETC'],
  salesStatuses: [
    'INQUIRY',
    'MANUAL_SEND',
    'PACKAGE_SEND',
    'BEFORE_MEETING',
    'SCHEDULED_MEETING',
    'AFTER_MEETING',
    'SALES_SUCCESS',
    'SALES_FAILED',
  ],
  todoStatuses: ['CONTACT', 'VISIT', 'PACKAGE'],
};

export const FILTER_DATA: Record<string, string> = {
  serviceTypes: '구분',
  salesStatuses: '상태',
  todoStatuses: 'Do List',
  ALL: '전체',
  MERCHANT: '매장',
  MEDICAL: '의료',
  ETC: '기타',
  INQUIRY: '도입문의',
  MANUAL_SEND: '메뉴얼 전송',
  PACKAGE_SEND: '패키지 발송',
  BEFORE_MEETING: '논의 | 미팅전',
  SCHEDULED_MEETING: '방문일자 협의',
  AFTER_MEETING: '논의 | 미팅후',
  SALES_SUCCESS: '가맹성공',
  SALES_FAILED: '가맹실패',
  CONTACT: '재연락',
  VISIT: '미팅 협의',
  PACKAGE: '패키지 발송',
  ENROLL_YES: 'O',
  ENROLL_NO: 'X',
  kioskActiveTypes: '구분',
  ACTIVATED: '사용중',
  DEACTIVATED: '사용 불가',
};

export const SalesOrderByType: Record<string, Record<string, any>> = {
  taxEnrollment: {
    none: undefined,
    descending: 'TAX_ENROLLMENT_YET',
    ascending: 'TAX_ENROLLMENT_COMPLETE',
  },
  firstInquiryDate: {
    none: undefined,
    descending: 'FIRST_INQUIRY_DESC',
    ascending: 'FIRST_INQUIRY_ASC',
  },
  todoDueDate: {
    none: undefined,
    descending: 'DUE_DATE_DESC',
    ascending: 'DUE_DATE_ASC',
  },
  processDays: {
    none: undefined,
    descending: 'PROCESS_DAY_DESC',
    ascending: 'PROCESS_DAY_ASC',
  },
};
