import React from 'react';
import { useLocation } from 'react-router-dom';

function useGetRequestPath(path: string | RegExp) {
  const { pathname } = useLocation();

  return pathname.match(path);
}

export default useGetRequestPath;
