import { useQuery } from 'react-query';

import { getWithdrawalDetail } from '@/api/kiosk';
import Modal from '@/components/common/Modal';
import { GetWithdrawalDetailResponse } from '@/types/api/kiosk';
import WithdrawalModalContent from './WithdrawalModalContent';
import { Typography } from '@material-ui/core';

type WithdrawalDetailModalProps = {
  refundIndex: number;
  onClose: () => void;
};

function WithdrawalDetailModal({
  refundIndex,
  onClose,
}: WithdrawalDetailModalProps) {
  const { isLoading, data } = useQuery<GetWithdrawalDetailResponse, number>(
    ['withdrawalDetail', refundIndex],
    () => getWithdrawalDetail(refundIndex),
    {
      refetchOnWindowFocus: false,
      onError: () =>
        alert('데이터 조회에 실패하였습니다. 관리자에게 문의해주세요.'),
    },
  );

  return (
    <Modal onClose={onClose} isLoading={isLoading}>
      {data && <WithdrawalModalContent data={data} />}
    </Modal>
  );
}

export default WithdrawalDetailModal;
