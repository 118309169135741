import React from 'react';
import { MenuItem, Select } from '@material-ui/core';

interface IProps {
  selectedValue: string;
  defaultValue: string;
  defaultTitle: string;
  onChange: () => void;
  menuList: Array<{ title: string; value: 'string' }>;
  disabled?: boolean;
  name?: string;
}
function SelectBox({
  selectedValue,
  defaultValue,
  defaultTitle,
  onChange,
  menuList,
  disabled = false,
  name,
}: IProps) {
  return (
    <Select
      name={name}
      style={{ padding: '4px 8px', fontSize: '14px', width: '30%' }}
      value={selectedValue}
      onChange={onChange}
      displayEmpty
      disabled={disabled}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem style={{ fontSize: 14 }} value={defaultValue}>
        {defaultTitle}
      </MenuItem>
      {menuList.map((item, index) => (
        <MenuItem key={index} style={{ fontSize: 14 }} value={item.value}>
          {item.title}
        </MenuItem>
      ))}
    </Select>
  );
}

export default SelectBox;
