import { useReducer } from 'react';

const initialState = {
  dateFilter: 'CUSTOM',
  filter: {
    startDate: new Date(),
    endDate: new Date(),
  },
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'SET_FILTER_DATE':
      return {
        ...state,
        filter: {
          ...state.filter,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    case 'SET_DATEFILTER':
      return {
        ...state,
        dateFilter: action.payload,
      };
    default:
      throw Error(`Unhandled Type Error: ${action.type}`);
  }
}

const useHomeReducer = () => {
  return useReducer(reducer, initialState);
};

export default useHomeReducer;
