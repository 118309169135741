import store from '@/store';
import api from '..';
import {
  AddHistoryRequest,
  AddHistoryResponse,
  DetailHistoryResponse,
  ExceedHistoriesResponse,
  modifyDetailHistoryRequest,
  SaveInquiryHistoryRequest,
  SaveInquiryHistoryResponse,
  StatusResponse,
  TotalHistoriesRequest,
  TotalHistoriesResponse,
} from '@/types/sales.types';

const getTargetServerURL = () => {
  return store.getState().serverState === 'prod'
    ? 'https://erp.successmode.co.kr'
    : 'http://123.142.246.2:20921/test';
};

// 상태 목록 가져오기
export const getStatus = async () => {
  const res: { data: StatusResponse } = await api.getAxios(
    `${getTargetServerURL()}/sales/status`,
  );
  return res.data;
};

export const getKeywords = async () => {
  const res: { data: StatusResponse } = await api.getAxios(
    `${getTargetServerURL()}/sales/status`,
  );
  const { storeNames, emails, contacts } = res.data.salesKeywords;

  return [
    ...[...new Set(storeNames)].map((v) => {
      return { keyword: v };
    }),
    ...[...new Set(emails)].map((v) => {
      return { keyword: v };
    }),
    ...[...new Set(contacts)].map((v) => {
      return { keyword: v };
    }),
  ];
};

// 가맹 목록 가져오기
export const getTotalHistories = async ({
  serviceType,
  salesStatus,
  todoStatus,
  page = 0,
  size = 100,
  keyword,
  salesOrderBy,
}: TotalHistoriesRequest) => {
  const params = new URLSearchParams();
  if (serviceType) serviceType.forEach((v) => params.append('serviceType', v));
  if (salesStatus) salesStatus.forEach((v) => params.append('salesStatus', v));
  if (todoStatus) todoStatus.forEach((v) => params.append('todoStatus', v));
  if (keyword) params.append('keyword', keyword);
  if (salesOrderBy) params.append('salesOrderBy', salesOrderBy);
  params.append('size', size.toString());
  params.append('page', page.toString());

  const res: { data: TotalHistoriesResponse } = await api.getAxios(
    `${getTargetServerURL()}/sales?${params.toString()}`,
  );
  return res.data;
};

// 가맹 목록 가져오기 (Do List 지난 경우)
export const getExceedHistories = async ({
  serviceType,
  salesStatus,
  todoStatus,
  page = 0,
  size = 100,
  keyword,
  salesOrderBy,
}: TotalHistoriesRequest) => {
  const params = new URLSearchParams();
  if (serviceType) serviceType.forEach((v) => params.append('serviceType', v));
  if (salesStatus) salesStatus.forEach((v) => params.append('salesStatus', v));
  if (todoStatus) todoStatus.forEach((v) => params.append('todoStatus', v));
  if (keyword) params.append('keyword', keyword);
  if (salesOrderBy) params.append('salesOrderBy', salesOrderBy);
  params.append('size', size.toString());
  params.append('page', page.toString());

  const res: { data: ExceedHistoriesResponse } = await api.getAxios(
    `${getTargetServerURL()}/sales/exceed?${params.toString()}`,
  );
  return res.data;
};

// 가맹 문의 히스토리 상세 보기
export const getDetailHistory = async ({ id }: { id: number }) => {
  const res: { data: DetailHistoryResponse } = await api.getAxios(
    `${getTargetServerURL()}/sales/${id}`,
  );
  return res.data;
};

// 가맹 문의 히스토리 상세 보기 내 수정
export const modifyDetailHistory = async ({
  id,
  data,
}: modifyDetailHistoryRequest) => {
  const res = await api.patchAxios(`${getTargetServerURL()}/sales/${id}`, data);
  return res.status;
};

// 가맹 문의 히스토리 상세 보기 내 문의 내역 저장
export const saveInquiryHistory = async (data: SaveInquiryHistoryRequest) => {
  const res: { data: SaveInquiryHistoryResponse } = await api.postAxios(
    `${getTargetServerURL()}/sales/inquiry`,
    data,
  );
  return res.data;
};

// 문의 히스토리 삭제
export const deleteInquiryHistory = async ({ id }: { id: number }) => {
  const res = await api.deleteAxios(
    `${getTargetServerURL()}/sales/inquiry/${id}`,
  );
  return res.status;
};

// 가맹 문의 히스토리 추가
export const addHistory = async ({ data }: { data: AddHistoryRequest }) => {
  const res: { data: AddHistoryResponse } = await api.postAxios(
    `${getTargetServerURL()}/sales`,
    data,
  );
  return res.data;
};
