import React from 'react';
import styled from 'styled-components';
import prepareImage from '../../assets/common/prepare.png';
import { Container } from '@material-ui/core';

const Prepare = () => {
  return (
    <PrepareContainer>
      <img src={prepareImage} alt={'Prepare'} />
    </PrepareContainer>
  );
};

const PrepareContainer = styled(Container)`
  display: flex;
  width: 100%;
  height: 50vh;
  align-items: center;
  justify-content: center;
`;

export default Prepare;
