import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import SignUpContent from './SignUpContent';
import ApplicationContent from './ApplicationContent';
import Modal from '@/components/common/Modal';

function SignUpModal({ onClose }) {
  const [hospitalIndex, setHospitalIndex] = useState();
  const onModalClose = useCallback(() => {
    onClose('CREATE');
  }, [onClose]);

  return (
    <Modal onClose={onModalClose}>
      <Wrapper>
        <Title>계정 생성</Title>
        <SignUpContent
          setHospitalIndex={setHospitalIndex}
          hospitalIndex={hospitalIndex}
        />
        <ApplicationContent
          hospitalIndex={hospitalIndex}
          onClose={onModalClose}
        />
      </Wrapper>
    </Modal>
  );
}
const Wrapper = styled.div`
  width: 100%;
`;
const Title = styled.h2`
  font-size: 28px;
  margin-bottom: 40px;
`;

export default React.memo(SignUpModal);
