import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container } from '@material-ui/core';
import { format } from 'date-fns';

import LeastestNotice from '@/components/medical/home/LeastestNotice';
import NoticeModal from '@/components/medical/home/NoticeModal';
import RefundOverview from '@/components/medical/home/RefundOverview';
import useHomeData from '@/hooks/medical/useHomeData';
import useHomeReducer from '@/hooks/medical/useHomeReducer';
import LeastestRefundDetail from '../../components/medical/home/LeastestRefundDetail';
import KioskStatus from '@/components/medical/home/KioskStatus';
import Typography from '@/components/common/Typography';
import DateSelectorFilter from '@/components/common/DateSelectorFilter';
import FlexWrap from '@/components/common/FlexWrap';
import LoadingView from '@/components/common/LoadingView';

function Home() {
  const [state, dispatch] = useHomeReducer();
  const {
    filter: { startDate, endDate },
  } = state;
  const { data, isError, isLoading } = useHomeData({
    dateFilter: 'CUSTOM',
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
  });

  const [selectedNoticeIndex, setSelectedNoticeIndex] = useState<null | number>(
    null,
  );
  const onPressNoticeList = (index: number) => {
    setSelectedNoticeIndex(index);
  };
  const closeNoticeModal = () => {
    setSelectedNoticeIndex(null);
  };

  useEffect(() => {
    const todayDate = format(new Date(), 'yyyy-MM-dd');
    const currDate = format(startDate, 'yyyy-MM-dd');

    if (currDate !== todayDate) {
      dispatch({
        type: 'SET_FILTER_DATE',
        payload: {
          startDate: new Date(),
          endDate: new Date(),
        },
      });
    }
  }, []);

  if (isError) {
    return <>서버 요청에 실패하였습니다. 관리자에게 문의하세요.</>;
  }

  return (
    <StyledContainer maxWidth="xl">
      {isLoading ? (
        <LoadingView />
      ) : (
        data && (
          <>
            <Typography
              fontSize="30px"
              lineHeight="38px"
              color="#212121"
              margin="0 0 28px 0"
              fontWeight="700"
            >
              홈
            </Typography>
            <FlexWrap justifyContent="space-between">
              <Typography
                fontSize="20px"
                lineHeight="28px"
                color="#212121"
                margin="0 0 28px 0"
                fontWeight="500"
              >
                환급 개요
              </Typography>
              <DateSelectorFilter
                position="relative"
                dispatch={dispatch}
                filter={state.filter}
              />
            </FlexWrap>
            <RefundOverview data={data.refundSummary} />
            <LeastestRefundDetail data={data.latestRefundInfo} />
            {/* <KioskStatus data={data.kioskInfo} /> */}
            <LeastestNotice
              data={data.notice}
              onPressNoticeList={onPressNoticeList}
            />
          </>
        )
      )}

      {selectedNoticeIndex && (
        <NoticeModal
          onClose={closeNoticeModal}
          noticeIndex={selectedNoticeIndex}
        />
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  padding-top: 16px;
`;

export default Home;
