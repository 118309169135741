import { DataTableColumn, DataTableRow } from '@/types/sales.types';
import React, { KeyboardEvent } from 'react';
import styled from 'styled-components';

type Props = {
  columns: DataTableColumn[];
  data: DataTableRow;
  onClickRow?: (id: number) => void;
  onEnterRow?: (e: KeyboardEvent, id: number) => void;
  hasDivider?: boolean;
  highlight?: boolean;
};

function TableBodyRow({
  columns,
  data,
  onClickRow,
  onEnterRow,
  hasDivider = false,
  highlight = false,
}: Props) {
  return (
    <Row
      role='row'
      key={data.id}
      onClick={() => onClickRow && onClickRow(data.id)}
      onKeyDown={(e) => onEnterRow && onEnterRow(e, data.id)}
      tabIndex={0}
      hasDivider={hasDivider}
    >
      {columns.map(({ type, align = 'center', highlight: columnHighlight }) => (
        <TableData key={type}>
          <Data align={align} isHighlight={highlight && columnHighlight}>
            {data[type]}
          </Data>
        </TableData>
      ))}
    </Row>
  );
}

const Row = styled.tr<{ hasDivider?: boolean }>`
  height: 48px;
  border-bottom: 1px solid #f5f6f7;
  cursor: pointer;

  &:hover {
    background-color: #f7f9fc;
  }

  &:last-child {
    border-bottom: 0;
  }

  ${({ hasDivider }) => (hasDivider ? `border-top: 4px solid #F5F6F7;` : '')}
`;

const TableData = styled.td`
  padding: 0 20px;
`;

const Data = styled.span<{
  align: 'center' | 'left' | 'right';
  isHighlight?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ align = 'center' }) => align};
  font-size: 14px;
  font-weight: 600;
  ${({ isHighlight = false }) => isHighlight && `color: #ED0828`}
`;

export default TableBodyRow;
