import { Dispatch, memo, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styled from 'styled-components';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';

import { defaultStaticRanges } from '@/utils/ktp';
import Calendar from '@/assets/common/calendar.png';
import ActionBtn from './ActionBtn';
interface IProps {
  dispatch: Dispatch<any>;
  top?: string;
  right?: string;
  position?: 'relative' | 'absolute';
  filter: {
    startDate: Date;
    endDate: Date;
  };
}
function DateSelectorFilter({ dispatch, filter }: IProps) {
  const [date, setDate] = useState({
    startDate: filter.startDate,
    endDate: filter.endDate,
  });
  const { startDate, endDate } = date;
  const [isActiveCalendar, setIsActiveCalendar] = useState(false);
  const onChange = (e: any) => {
    const { startDate, endDate } = e.selection;
    setDate({ startDate, endDate });
  };
  const onApply = (e: any) => {
    dispatch({
      type: 'SET_FILTER_DATE',
      payload: {
        startDate,
        endDate,
      },
    });
    onCloseCalendar(e);
  };
  const onCancel = (e: any) => {
    setDate({
      startDate: filter.startDate,
      endDate: filter.endDate,
    });
    onCloseCalendar(e);
  };
  const onCloseCalendar = (e: any) => {
    if (e.target === e.currentTarget) {
      setIsActiveCalendar(false);
    }
  };

  return (
    <>
      <DateSelector onClick={() => setIsActiveCalendar(true)}>
        <Icon src={Calendar} />
        {`${format(filter.startDate, 'yyyy-MM-dd ~ ')}${format(
          filter.endDate,
          'yyyy-MM-dd',
        )}`}
      </DateSelector>
      {isActiveCalendar && (
        <Container onClick={onCloseCalendar}>
          <CalendarModal>
            <DateRangePicker
              ranges={[
                {
                  startDate,
                  endDate,
                  key: 'selection',
                },
              ]}
              onChange={onChange}
              locale={ko}
              months={2}
              direction="horizontal"
              staticRanges={defaultStaticRanges}
              inputRanges={[]}
            />
            <ActionBtn
              title="취소"
              bgColor="white"
              color="gray"
              outlineColor="gray"
              onClick={onCancel}
              right="130px"
              bottom="16px"
            />
            <ActionBtn
              title="적용"
              onClick={onApply}
              right="20px"
              bottom="16px"
            />
          </CalendarModal>
        </Container>
      )}
    </>
  );
}
const Container = styled.div`
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`;
const Icon = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  left: 8px;
  top: 6px;
`;
const DateSelector = styled.button`
  position: relative;
  padding: 8px 20px 8px 36px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  color: #212121;
  background-color: #ffffff;
`;
const CalendarModal = styled.div`
  position: absolute;
  z-index: 5px;
  right: 60px;
  top: 190px;
  background-color: #ffffff;
  padding-bottom: 40px;
`;
export default memo(DateSelectorFilter);
