import { format } from 'date-fns';
import { useReducer } from 'react';

const initialState = {
  toggle: null,
  dateSelector: 'ALL',
  selected: null,
  filter: {
    refundStatus: 'ALL',
    paymentStatus: 'ALL',
    departureStatus: 'ALL',
    receiptUploadStatus: 'ALL',
    refundType: 'ALL',
    startDate: '20/01/01',
    endDate: format(new Date(), 'yy/MM/dd'),
  },
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'SET_SELECTED':
      return {
        ...state,
        selected: action.payload,
      };
    case 'SET_TOGGLE':
      return {
        ...state,
        toggle: action.payload,
      };
    case 'SET_FILTER_DATE':
      return {
        ...state,
        filter: {
          ...state.filter,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };
    case 'SET_FILTER':
      return {
        ...state,
        filter: { ...state.filter, [action.payload.key]: action.payload.value },
      };
    case 'SET_FILTER_REFUND_TYPE':
      return {
        ...state,
        filter: {
          ...initialState.filter,
          refundType: action.payload,
        },
      };
    case 'SET_DATESELECTOR':
      return {
        ...state,
        dateSelector: action.payload,
      };
    default:
      throw Error(`Unhandled Type Error: ${action.type}`);
  }
}

const useRefundReducer = () => {
  return useReducer(reducer, initialState);
};

export default useRefundReducer;
