import styled, { css } from 'styled-components';
import FlexWrap from '@/components/common/FlexWrap';

export type ListType = {
  title: string;
  isSelected: boolean;
  value: string;
  isDisable?: boolean;
};

export type FilterListType = {
  orderBy: ListType[];
  paymentStatus: ListType[];
  mainCount: ListType[];
};

type RemittanceFilterProps = {
  currentTransferView?: string;
  onClickChangeTransfer: (value: string) => void;
};

function QnaFilter({
  currentTransferView,
  onClickChangeTransfer,
}: RemittanceFilterProps) {
  return (
    <FlexWrap>
      <TransferViewContainer>
        <TransferView
          isActive={currentTransferView === 'ALL'}
          onClick={() => onClickChangeTransfer('ALL')}
        >
          전체보기
        </TransferView>
        <TransferView
          isActive={currentTransferView === 'NOT_ANSWERED'}
          onClick={() => onClickChangeTransfer('NOT_ANSWERED')}
        >
          답변 미완료
        </TransferView>
      </TransferViewContainer>
    </FlexWrap>
  );
}

export default QnaFilter;

const TransferViewContainer = styled.div`
  display: flex;
  background-color: #fff;
  align-items: center;
`;

const TransferView = styled.div<{ isActive?: boolean }>`
  padding: 16px 32px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #a7a7a7;
  white-space: nowrap;
  cursor: pointer;
  ${(props) =>
    props.isActive &&
    css`
      color: #1856cd;
      border-bottom: 2px solid #246cf6;
    `}
`;

const ViewContainer = styled(FlexWrap)<{ isMedical?: boolean }>`
  margin: ${(props) => (props.isMedical ? '0' : '25px 0')};
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

const Select = styled.select`
  padding: 4px 8px;
`;
