import React, { useCallback } from 'react';
import styled from 'styled-components';

import Modal from '@/components/common/Modal';

function PreviewModal({ onClose, selected }) {
  const onModalClose = useCallback(() => {
    onClose('DETAIL');
  }, [onClose]);

  return (
    <Modal onClose={onModalClose} width="auto">
      {selected.imgUrl && <Image src={selected.imgUrl} />}
    </Modal>
  );
}
const Image = styled.img`
  margin-top: 20px;
  width: 100%;
`;

export default React.memo(PreviewModal);
