import { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { getKioskDetailDeposit } from '@/api/kiosk';
import LoadingView from '@/components/common/LoadingView';
import Paging from '@/components/common/Paging';
import { GetKioskDetailChargingResponse } from '@/types/api/kiosk';
import CashBalance from './kioskDetail/CashBalance';
import { StateType } from './KioskStatusDetail';
import KioskTitle from './KioskTitle';

function KioskChargingDetail() {
  const { state } = useLocation<StateType>();
  const { title, preTitle, kioskId, detailTitle, billType } = state;

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPage, setTotalPage] = useState<number>(0);

  const payload = {
    queryString: { page: pageNumber, size: 10 },
    kioskIndex: kioskId,
  };

  const { data: kioskDetailDeposit } = useQuery<
    GetKioskDetailChargingResponse,
    number
  >(['kioskDetailDepsit', payload], () => getKioskDetailDeposit(payload), {
    onSuccess: (data) => {
      setTotalPage(data.depositInfo.totalPages);
    },
    retry: false,
  });
  const isSetData =
    kioskDetailDeposit?.remainingInfo &&
    kioskDetailDeposit?.depositInfo.content &&
    kioskDetailDeposit?.kioskLackCriteria;

  return (
    <>
      <KioskTitle
        kioskId={kioskId}
        title={title}
        preTitle={preTitle}
        detailTitle={detailTitle}
        billType={billType}
      />
      {isSetData ? (
        <>
          <CashBalance
            kioskIndex={kioskId}
            billType={billType}
            remainingInfo={kioskDetailDeposit.remainingInfo}
            depositInfo={kioskDetailDeposit.depositInfo.content}
            kioskLackCriteria={kioskDetailDeposit.kioskLackCriteria}
            isCharging
          />
          <Paging
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalPage={totalPage}
          />
        </>
      ) : (
        <LoadingView />
      )}
    </>
  );
}

export default KioskChargingDetail;
