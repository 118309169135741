import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Container, TableBody } from '@material-ui/core';
import { useQuery } from 'react-query';

import { getSearchKeywords, getWithdrawalLists } from '@api/ktp';
import CheckboxForm from '@components/common/CheckboxForm';
import SearchBox from '@components/common/SearchBox';
import PageIndicator from '@components/common/PageIndicator';

import DetailModal from '@components/ktp/point-withdrawal/DetailModal';
import ListItem from '@components/ktp/point-withdrawal/ListItem';
import Title from '@components/common/Title';
import { pointWithdrawalData } from '@constants/ktp';
import usePointReducer from '@hooks/ktp/usePointReducer';
import DataTable from '@components/common/DataTable';
import LoadingView from '@/components/common/LoadingView';

function PointWithdrawal() {
  const [state, dispatch] = usePointReducer();
  const { isOpenDetail, toggle, filter, selected } = state;
  const [totalPage, setTotalPage] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageList, setPageList] = useState<any>();
  const [searchKeyword, setSearchKeyword] = useState('');
  const { columns, isAllRadio, pointStatusRadio } = pointWithdrawalData;

  const { isLoading, data: pointData } = useQuery(
    ['withdrawalLists', filter, currentPage, searchKeyword],
    () => getWithdrawalLists(filter, currentPage, searchKeyword),
    {
      onSuccess: (data) => {
        if (currentPage === 0) {
          setTotalPage(data.totalPages);
          setPageList(
            data.totalPages < 4
              ? Array.from({ length: data.totalPages + 1 }, (v, i) => i)
              : [0, 1, 2, 3, 4],
          );
        }
      },
      retry: false,
    },
  );
  const { data: keywords } = useQuery('keywords', getSearchKeywords, {
    retry: false,
  });
  const onOpen = (selected: any) => {
    dispatch({
      type: 'OPEN_MODAL',
      payload: { selected },
    });
  };

  const onClose = useCallback((selected) => {
    dispatch({
      type: 'CLOSE_MODAL',
      payload: { selected },
    });
  }, []);

  const onFilter = useCallback(
    (e) => {
      const { name, value } = e.target;
      dispatch({ type: 'SET_TOGGLE', payload: null });
      dispatch({
        type: 'SET_FILTER',
        payload: { ...filter, [name]: value },
      });
      setCurrentPage(0);
      setPageList([0, 1, 2, 3, 4]);
    },
    [filter],
  );

  if (isLoading) {
    return <LoadingView />;
  }
  return (
    <StyledContainer maxWidth="xl">
      <Title>포인트 정산 관리</Title>
      {keywords && (
        <SearchBox
          data={keywords}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          setCurrentPage={setCurrentPage}
        />
      )}
      <DataTable
        columns={columns}
        dispatch={dispatch}
        toggle={toggle}
        contentComponent={
          <TableBody>
            {pointData?.content?.map((item: any, idx: number) => (
              <ListItem
                key={item.pointsIndex}
                idx={idx}
                data={item}
                onOpen={onOpen}
              />
            ))}
          </TableBody>
        }
      />
      {pointData?.content?.length === 0 && (
        <ContentContainer>
          포인트 출금 요청이 존재하지 않습니다.
        </ContentContainer>
      )}
      {isOpenDetail && <DetailModal onClose={onClose} selected={selected} />}
      {toggle && (
        <CheckboxForm
          valueArray={toggle === 'isAll' ? isAllRadio : pointStatusRadio}
          defaultValue={filter[toggle]}
          type={toggle}
          onFilter={onFilter}
        />
      )}
      {pageList && (
        <PageIndicator
          totalPage={totalPage}
          pageList={pageList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setPageList={setPageList}
        />
      )}
    </StyledContainer>
  );
}
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  color: gray;
  font-size: 16px;
`;

export default PointWithdrawal;
