import React, {
  Dispatch,
  FormEventHandler,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { StoreListType } from '@/hooks/ktp/usePushNotification';
import SearchFranchaseinessList from '../ktp/push-notification/SearchFranchaseinessList';
import FlexWrap from './FlexWrap';

interface IProps {
  data: any;
  setSearchKeyword: Dispatch<SetStateAction<string>>;
  searchKeyword: string;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  isPushNotice?: boolean;
  pushSearchFilter?: (inputValue: string) => void;
  selectedStoreList?: StoreListType[];
  setSelectedStoreList?: Dispatch<SetStateAction<StoreListType[]>>;
  filteredStoreList?: StoreListType[];
  disabled?: boolean;
  placeholder?: string;
}
function SearchBox({
  data,
  setSearchKeyword,
  searchKeyword,
  setCurrentPage,
  isPushNotice = false,
  selectedStoreList,
  setSelectedStoreList,
  pushSearchFilter,
  filteredStoreList,
  disabled = false,
  placeholder = '검색',
}: IProps) {
  const [inputValue, setInputValue] = useState('');
  const autoCompleteRef = useRef();
  const pushData = data.slice(0, (data.length / 3) * 2);

  const onChange = (e: any, v: any) => {
    //검색어 미리보기 클릭시 실행
    setInputValue(v?.keyword);
  };
  const onInputChange = (e: any, v: any) => {
    //검색값 입력시 실행
    setInputValue(v);
  };
  const onSubmit: FormEventHandler<HTMLFormElement | HTMLButtonElement> = e => {
    e.preventDefault();
    const current = autoCompleteRef.current as any;
    current.blur();
    setSearchKeyword(inputValue || '');
    isPushNotice && pushSearchFilter!(inputValue);
  };
  const onResetKeyword = () => {
    setSearchKeyword('');
    setCurrentPage(0);
  };
  const onRemoveKeyword = (e: any) => {
    if (!selectedStoreList) {
      return;
    }
    const selectedText = e.target.parentNode.innerText.slice(0, -1);
    const filteredList = selectedStoreList.filter(
      (store: StoreListType) => store.keyword !== selectedText,
    );
    setSelectedStoreList!(filteredList);
  };

  return (
    <Container isPushNotice={isPushNotice}>
      <Form onSubmit={onSubmit}>
        <Autocomplete
          ref={autoCompleteRef}
          id="combo-box-demo"
          options={isPushNotice ? pushData : data}
          onChange={onChange}
          onInputChange={onInputChange}
          disabled={disabled}
          getOptionLabel={option => option.keyword}
          renderInput={params => {
            return (
              <TextField
                {...params}
                variant="outlined"
                style={{ backgroundColor: disabled ? '#FAFAFA' : '#FFFFFF' }}
                placeholder={placeholder}
                InputProps={{
                  className: 'MuiAutocomplete-inputRoot',
                  ref: params.InputProps.ref,
                  startAdornment: (
                    <SearchButton onClick={onSubmit}>
                      <InputAdornment position="start">
                        <Search style={{ marginLeft: 10 }} color="disabled" />
                      </InputAdornment>
                    </SearchButton>
                  ),
                  fullWidth: true,
                  id: 'combo-box-demo',
                }}
                size="small"
              />
            );
          }}
          renderOption={(option, { inputValue }) => {
            const matches = match(option.keyword, inputValue);
            const parts = parse(option.keyword, matches);
            return (
              <div>
                {parts.map((part: any, index: number) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? '#27AE60' : 'black',
                    }}>
                    {part.text}
                  </span>
                ))}
              </div>
            );
          }}
        />
      </Form>
      {isPushNotice && filteredStoreList ? (
        <>
          <SearchList>
            {selectedStoreList &&
              selectedStoreList.map((store, idx) => (
                <KeywordContainer key={idx}>
                  <Tag isSelectedStoreTag>
                    {store.keyword}
                    <ResetBtn onClick={onRemoveKeyword}>X</ResetBtn>
                  </Tag>
                </KeywordContainer>
              ))}
          </SearchList>
          {filteredStoreList.length > 0 && (
            <SearchFranchaseinessList
              filteredStoreList={filteredStoreList!}
              selectedStoreList={selectedStoreList!}
              setSelectedStoreList={setSelectedStoreList!}
            />
          )}
        </>
      ) : (
        searchKeyword !== '' && (
          <KeywordContainer>
            <Tag>" {searchKeyword} "</Tag>
            <ResetBtn onClick={onResetKeyword}>X</ResetBtn>
          </KeywordContainer>
        )
      )}
    </Container>
  );
}
const Container = styled.div<{ isPushNotice: boolean }>`
  display: flex;
  margin-bottom: 24px;
  ${props =>
    props.isPushNotice &&
    css`
      gap: 16px;
      flex-direction: column;
      width: 100%;
    `}
`;

const Form = styled.form`
  flex: 1;
  font-size: 10px;
`;
const KeywordContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
const Tag = styled.p<{ isSelectedStoreTag?: boolean }>`
  padding: 4px 10px;
  border-radius: 12px;
  color: white;
  background-color: #757575;
  margin-left: ${props => (props.isSelectedStoreTag ? '0' : '18px')};
`;
const ResetBtn = styled.button`
  cursor: pointer;
  border-radius: 50%;
  border: none;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  color: white;
  background-color: #757575;
`;
const SearchList = styled(FlexWrap)`
  gap: 12px;
  flex-wrap: wrap;
`;
const SearchButton = styled.button``;
export default SearchBox;
