import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Text from '@/components/common/Text';

interface IProps {
  label: string;
  required: boolean;
  children: ReactNode;
  style?: any;
  labelWidth?: number;
}
const FormItemLayout = ({
  label,
  required,
  children,
  style,
  labelWidth = 220,
}: IProps) => {
  return (
    <ItemContainer style={style}>
      <Text text={label} required={required} style={{ width: labelWidth }} />
      {children}
    </ItemContainer>
  );
};
const ItemContainer = styled.div`
  display: flex;
  margin: 4px 0;
  width: 100%;
`;

export default FormItemLayout;
