import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const GenderDoughnut = ({ statistics }) => {
  const { numberOfUsers, numberOfMale, numberOfFemale } = statistics;

  const options = {
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      title: {
        display: true,
        font: {
          size: 20,
        },
        text: '성별',
      },
    },
  };

  return (
    <Doughnut
      width={400}
      height={400}
      options={options}
      data={{
        labels: ['남성', '여성'],
        datasets: [
          {
            label: 'Gender of Users',
            data: [numberOfMale, numberOfFemale],
            backgroundColor: [
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 99, 132, 0.2)',
            ],
            borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
            borderWidth: 1,
          },
        ],
      }}
    />
  );
};

export default GenderDoughnut;
