import { TableBody } from '@material-ui/core';
import ControlCodeItem from './ControlCodeItem';

function ControlCodeList(props) {
  const { controlCodes, onClick, selectedCode } = props;

  return (
    <TableBody>
      {controlCodes.map((controlCode) => (
        <ControlCodeItem
          key={controlCode.valueCode}
          controlCode={controlCode}
          selectedCode={selectedCode}
          onClick={onClick}
        />
      ))}
    </TableBody>
  );
}

export default ControlCodeList;
