import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import Icon from '@/components/common/Icon';
import Typography from '@/components/common/Typography';
import radioIcon from '@/assets/common/radio.png';
import radioActiveIcon from '@/assets/common/radioActive.png';
import { ListType } from './RemittanceFilter';

type ModalFilterType = {
  title: string;
  filterList: ListType[];
  setFilterList: Dispatch<SetStateAction<ListType[]>>;
  onClickSelect: (
    list: ListType[],
    value: string,
    setFilter: Dispatch<SetStateAction<ListType[]>>,
  ) => void;
};

function ModalFilter({
  title,
  filterList,
  setFilterList,
  onClickSelect,
}: ModalFilterType) {
  return (
    <>
      <Typography
        fontSize="16px"
        fontWeight="600"
        lineHeight="24px"
        color="#3A3B3E"
        margin="16px 0 19px 0"
      >
        {title}
      </Typography>
      <Container>
        {filterList.map((item, idx) => (
          <Item
            key={idx}
            isDisable={item.isDisable || false}
            onClick={() => onClickSelect(filterList, item.title, setFilterList)}
          >
            <Icon
              imgUrl={item.isSelected ? radioActiveIcon : radioIcon}
              width="24px"
              height="24px"
            />
            <Typography
              fontSize="14px"
              lineHeight="21px"
              fontWeight="500"
              color={item.isDisable ? '#A7A7A7' : '#5F6165'}
            >
              {item.title}
            </Typography>
          </Item>
        ))}
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  gap: 16px;
`;
const Item = styled.div<{ isDisable: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  pointer-events: ${(props) => props.isDisable && 'none'};
`;

export default ModalFilter;
