import React, { KeyboardEvent } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import Field from './Field';
import styled from 'styled-components';

type TextInputProps<T extends FieldValues> = {
  register: ReturnType<UseFormRegister<T>>;
  label: string;
  placeholder?: string;
  required?: boolean;
  error?: boolean;
};

function TextInput<T extends FieldValues>({
  register,
  label,
  placeholder,
  required = false,
  error = false,
}: TextInputProps<T>) {
  const onEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Field label={label} required={required} error={error}>
      <Input {...register} placeholder={placeholder} onKeyDown={onEnter} />
    </Field>
  );
}

const Input = styled.input`
  width: 100%;
  padding: 0;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #030303;

  ::placeholder {
    color: #cbccce;
  }

  :focus {
    outline: 0;
  }
`;

export default TextInput;
