import React from 'react';
import Modal from '../common/Modal';
import styled from 'styled-components';
import QnaCustomerInfo from './QnaCustomerInfo';
import QnaType from './QnaType';
import { getQnaMedicalDetail } from '@/api/medical';
import { useQuery } from 'react-query';
import LoadingView from '../common/LoadingView';
import { QnaDetailResponse } from '@/types/qna.type';
import useGetRequestPath from '@/hooks/common/useGetRequestPath';
import { getQnaDetail } from '@/api/ktp';

type Props = {
  qnaId: number;
  onClose: () => void;
  onOpenRefundModal: (id: number) => void;
};

function QnaModal({ qnaId, onClose, onOpenRefundModal }: Props) {
  const isMedical = useGetRequestPath(/medical/);
  const { data, isLoading } = useQuery<QnaDetailResponse>(
    ['qnaDetail'],
    () => (isMedical ? getQnaMedicalDetail(qnaId) : getQnaDetail(qnaId)),
    {
      retry: false,
    },
  );

  if (isLoading) return <LoadingView />;

  return (
    <Modal width="970px" onClose={onClose}>
      <Title>고객 문의</Title>
      <ContentsWrapper>
        <QnaCustomerInfo
          name={data?.name}
          nation={data?.nation}
          refundIdList={data?.refundIdList}
          contentList={data?.contentList}
          onOpenModal={onOpenRefundModal}
        />
        <QnaType id={data?.touristId || data?.customerId} />
      </ContentsWrapper>
    </Modal>
  );
}

export default QnaModal;

const Title = styled.h5`
  width: 100%;
  margin: 0 0 16px;
  color: #000;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
`;

const ContentsWrapper = styled.div`
  display: flex;
  gap: 28px;
  width: 100%;
`;
